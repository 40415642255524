.hm-solution-content {
    width: 100vw;
    position: relative;
    section {
        padding: 4.74vw 16.667vw 2.083vw 16.667vw;
    }
    h3 {
        font-weight: 400;
        font-size: 1.563vw;
        color: #000000;
    }
    p {
        font-weight: 400;
        font-size: 1.146vw;
        color: #454545;
        white-space: nowrap;
    }
    ul {
        font-weight: 400;
        font-size: 1.042vw;
        color: #454545;
    }
    .hm-solution-ul {
        margin: 2.865vw 0vw 5.208vw 0px;
        margin-right: 3.698vw;
    }
    .hm-solution-img {
       height: 19.155vw; 
       width: 32.396vw;
       border: 1px solid #f6f6f6;
    }
    
    .hm-solution-show {
        display: inline-block;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg4.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 100vw; /* 元素宽度 */
        height: 15.573vw ;/* 元素高度 */
        margin-top:var(--header-top);
        padding: 5.573vw 0vw 0vw 16.927vw;
        box-sizing: border-box;
        h1 {
            font-weight: 600;
            font-size: 2.604vw;
            color: #FFFFFF;
        }
        h3 {
                font-weight: 400;
                font-size: 1.563vw;
                color: #FFFFFF;
        }
    }
    .hm-solution-feature {
        margin-bottom: 4.115vw;
        div {
            padding: 0px;
            box-sizing: border-box;
            padding-top: 1.458vw;
            width: 15.26vw;
            // height: 10.365vw;
            text-align: left;
            background: #F0FAFF;
            box-shadow: 0px 0px 3px #afdff7;
        }
        h4 {
            font-weight: 600;
            font-size: 1.25vw;
            color: #000000;
            text-align: center;
            margin-bottom: 1.042vw;
        }
        p{
            font-weight: 400;
            font-size: 0.833vw;
            line-height: 1.302vw;
            color: #454545;
            padding-left: 1.406vw !important;
        }
        img{
            padding: 0.5vw;
        }
    }
    .hm-solution-frame {
        width: 100%;
        height: 34.063vw;
        padding:1.6vw 2.667vw 0vw 2.667vw ;
        border-top: 2px solid #0000B5;
        margin-bottom: 6vw;
        img {
            width: 100%;
        }
    }

    .hm-sidebar-bar {
        position: absolute;
        top: 18.229vw;
        left: 3.125vw;
        // width: 16.667vw;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 36.646vw;
        cursor: pointer;
        h5{
            height: 1.563vw;
            font-size: 0.833vw;
            color: #2376FC;
            text-align: center;
        }
        a{
            text-decoration: none;
            transform: scale(1);
            transition: all 1s;
            &:hover{
             transform: scale(1.1);
            }
         }
        
        .col {
            padding-top: 1.042vw;
            margin-bottom: 0.8vw;
            width: 8.51vw;
            height: 9vw;
            background-color: #e3edf7;
            border-radius: 0.625vw;
        }
        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 0.938vw;
            color: #000000;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .hm-solution-new {
        width: 58.594vw;
        margin-bottom: 5.833vw;
       
        h1{
            font-size: 1.667vw;
            font-weight: 900;
            margin-top: 4.323vw;
            margin-bottom: 1.042vw;
        }
        p {
            font-size: 1.042vw;
            margin-bottom: 1.198vw;
            white-space:inherit;
        }
    }
    
}
.hm-solution-content-app {
    font-family: MiSans, MiSans;
    section {
        padding: 10.267vw 8.533vw;
    }
    .hm-solution-show {
        display: inline-block;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg4.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 100vw; /* 元素宽度 */
        height: 28.933vw;/* 元素高度 */
        margin-top:var(--header-top);
        padding: 8.267vw 0vw 0vw 9.2vw;
        box-sizing: border-box;
        h1 {
            font-weight: 600;
            font-size: 6.667vw;
            color: #FFFFFF;
            line-height: 8.533vw;
        }
        h3 {
            font-size: 4vw;
            color: #FFFFFF;
            line-height: 4vw;
        }
    }
    h3 {
        font-weight: 400;
        font-size: 5.067vw;
        color: #000000;
        margin-bottom: 4.533vw;
    }
    p {
        font-size: 3.467vw;
        color: #454545;
        line-height: 4.8vw;
    }
    .hm-solution-ul {
        margin: 4.8vw 0vw 0 0vw;
        padding-left: 3.333vw;
        font-size: 3.467vw;
        color: #454545;
        line-height: 4.8vw;
    }
    .hm-solution-img {
        margin-top: 4.067vw;
        margin-bottom: 11.067vw;
    }
    .hm-solution-feature {
        margin-bottom: 4.115vw;
        
        div {
            box-sizing: content-box;
            padding: 3.067vw 3.467vw;
            text-align: left;
            // width: 33.333vw;
            // height: 22.533vw;
            background: #F0FAFF;
            margin-bottom: 4vw;
            box-shadow: 0px 0px 6px #ccc;
        }
        h4 {
            font-weight: 600;
            font-size: 4vw;
            color: #000000;
            text-align: center;
            margin-bottom: 1.042vw;
        }
        p{
            font-weight: 400;
            font-size: 3.2vw;
            line-height: 4vw;
            color: #454545;
            // padding-bottom: 3.2vw;
        }
    }
    .hm-solution-frame {
        width: 100%;
        height: 34.063vw;
        // padding:1.6vw 2.667vw 0vw 2.667vw ;
        border-top: 2px solid #0000B5;
        margin-bottom: 8vw;
        img {
            width: 100%;
        }
    }
    .hm-sidebar-bar {
        height: 100.4vw;
        width: 100vw;
        padding: 6.933vw;
        cursor: pointer;
        h5{
            font-size: 4.8vw;
            color: #2376FC;
            text-align: left;
        }
        a{
            background-color: #e3edf7;
            border-radius: 1.625vw;
            width: 40vw;
            height: 36.5vw;
            padding: 2.5vw;
            margin: 1.5vw;
            text-decoration: none;
            transform: scale(1);
            transition: all 1s;
            &:hover{
             transform: scale(1.1);
            }
         }
         .hm-sidebar-bar-main {
            display: flex;
            flex-wrap: wrap;
         }
        
        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 4.467vw;
            color: #000000;
            text-align: center;
            width: 100%;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .hm-solution-new {
        margin-bottom: 5.833vw;
       
        h1{
            font-size: 4.533vw;
            font-weight: 900;
            margin-top: 8.323vw;
            margin-bottom: 3.042vw;
        }
        p {
            font-weight: 400;
            font-size: 3.2vw;
            color: #454545;
            line-height: 7vw;
            margin-bottom: 1.198vw;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}

