#hm-catrgory {
    h1 {
        font-weight: 600;
        font-size: 2.083vw;
        color: #000000;
        margin-top: 4.479vw;
    }

    h5 {
        font-weight: 400;
        font-size: 1.042vw;
        color: #000000;
        margin-bottom: 2.604vw;
    }

    .hm-catrgory {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg2.png');
        /* 设置图片路径 */
        background-size: cover;
        /* 背景图覆盖整个元素 */
        background-repeat: no-repeat;
        /* 背景图不重复 */
        background-position: center;
        /* 背景图居中 */
        width: 100vw;
        /* 元素宽度 */
        height: 33.49vw;
        /* 元素高度 */
        padding: 2.0vw 0vw 2.2vw 0vw;
        box-sizing: border-box;

        .hm-catrgory-container {
            a {
                text-decoration: none;
                transform: scale(1);
                transition: all 1s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .row {
            width: 55.792vw;
            height: 13.45vw;
            margin: 0 auto;
        }

        .col:not(:last-child) {
            margin-right: 0.781vw;
        }

        .col {
            padding: 0px;
            background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png');
            /* 设置图片路径 */
            background-size: cover;
            /* 背景图覆盖整个元素 */
            background-repeat: no-repeat;
            /* 背景图不重复 */
            background-position: center;
            /* 背景图居中 */
            width: 14.74vw;
            height: 100%;
        }

        .hm-category-secondDesc {
            font-weight: 300;
            font-size: 0.6vw;
            color: #989898;
            cursor: pointer;
        }

        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 0.85vw;
            color: #000000;
        }

        p {
            margin: 0px;
        }

        img {
            margin-top: 2.1vw;
            width: 6.042vw;
            height: 6.042vw;
        }


    }
}

#hm-catrgory-app {
    h1 {
        font-weight: 600;
        font-size: 4.533vw;
        line-height: 6vw;
        color: #000000;
        margin: 8.479vw 6.667vw 0;
        text-align: left;
    }

    h1:nth-child(2) {
        margin-top: 0;
    }

    h5 {
        font-weight: 400;
        font-size: 3.467vw;
        color: #000000;
        margin: 3.479vw 6.667vw;
    }

    .hm-catrgory {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg2.png');
        /* 设置图片路径 */
        background-size: cover;
        /* 背景图覆盖整个元素 */
        background-repeat: no-repeat;
        /* 背景图不重复 */
        background-position: center;
        /* 背景图居中 */
        width: 100vw;
        /* 元素宽度 */
        height: 178.667vw;
        /* 元素高度 */
        padding: 2.0vw 0vw 2.2vw 0vw;
        box-sizing: border-box;

        .hm-catrgory-container {
            a {
                text-decoration: none;
                transform: scale(1);
                transition: all 1s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .row {
            width: 88vw;
            height: 42.45vw;
            margin: 0 auto;
        }

        .col:not(:last-child) {
            margin-right: 0.781vw;
        }

        .col {
            padding: 0px;
            background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png');
            /* 设置图片路径 */
            background-size: cover;
            /* 背景图覆盖整个元素 */
            background-repeat: no-repeat;
            /* 背景图不重复 */
            background-position: center;
            /* 背景图居中 */
            width: 30vw;
            height: 100%;
        }

        .hm-category-secondDesc {
            font-weight: 300;
            font-size: 2.4vw;
            color: #989898;
            cursor: pointer;
        }

        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 3.467vw;
            color: #000000;
        }

        p {
            margin: 0px;
        }

        img {
            margin-top: 2.1vw;
            width: 24.042vw;
            height: 24.042vw;
        }


    }
}

.hm-contact {
    img {
        width: 109px;
        height: 109px;
    }

    font-weight: 400;
    font-size: 0.625vw;
    color: #9C9C9C;
}