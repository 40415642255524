#number-animation {
    text-align: center;
    h1 {
        font-weight: 600;
        font-size: 2.083vw;
        color: #000000;
    }
    h3 {
        font-weight: 400;
        font-size: 1.042vw;
        color: #000000;
    }
    h2 {
         display: inline;
        font-weight: 600;
        font-size: 1.875vw;
        color: #005DFF;
        margin-right: 0.156vw;
        margin-bottom: 0px;
    }
    span {
        display: inline;
        vertical-align: super;
        font-weight: 600;
        font-size: 1.406vw;
        color: #005DFF;
    }
    .row {
       height: 6.302vw;
       width: 46.875vw;
       overflow: hidden;
       margin: 0 auto;
       margin-top: 2.3vw;
       margin-bottom: 3.3vw;
    }
    .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px;
        div {
            height: 7.67vw;
            display: inline;
        }
    }
    .col:not(:last-child) {
        border-right: 0.156vw solid #D2E6F5;
    }
    h4 {
        font-weight: 400;
        font-size: 1.146vw;
        color: #000000;
        margin-bottom: 0px;
    }
}

.hm-data {
    position: relative;
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg1.png'); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 31.615vw; /* 元素高度 */
    padding: 3.229vw 0vw 3.542vw 0vw;
    box-sizing: border-box;

    .marquee-brand {
      position: absolute;
      width: 100vw;
      overflow-x: hidden;
          #box {
            min-width: 100%;
            display: flex;
            position: relative;
            box-sizing: border-box;
            transform: translate3d(0,0,0);
          }
          
          #box::after {
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          
          .box-area {
            display: flex;
          }
          
          // #box:hover .logo-top {
          //   animation-play-state: paused;
          // }
          
          .logo-top {
            display: flex;
            position: relative;
          /*   z-index: 1; */
          }
          
          .logo-top {
            will-change: transform;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-animation: marquee 60s linear infinite;
            animation: marquee 60s linear infinite;
            -webkit-transform-origin: center center;
            -ms-transform-origin: center center;
            transform-origin: center center;
          }
          .logo-top-one {
            display: flex;
            position: relative;
          /*   z-index: 1; */
          }
          
          .logo-top-one {
            will-change: transform;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-animation: marquee 60s linear infinite;
            animation: marquee 60s linear infinite;
            -webkit-transform-origin: center center;
            -ms-transform-origin: center center;
            transform-origin: center center;
            margin-left: 10vw;
          }
          
          @keyframes marquee {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
          
          a {
            display: block;
            width: 12.969vw;
            overflow: hidden;
            margin: 0 20px;
          }
          
          img {
            width: 12.969vw;
            height: 5.417vw;
            overflow: hidden;
          }
    
     }
     .marquee-brand img {
      width: 12.969vw;
      height: 5.417vw;
      overflow: hidden;
  }
}
.hm-data-app {
    position: relative;
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/组27@2.png'); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 101.467vw; /* 元素高度 */
    padding: 3.229vw 0vw 3.542vw 0vw;
    box-sizing: border-box;
    #number-animation-app {
      h1 {
        font-weight: 700;
        font-size: 4.533vw;
        line-height: 6vw;
        color: #000000;
        margin: 8.479vw 6.667vw 0;
        strong {
          display: inline;
          font-size: 5.5vw;
        }
      }
  
      h1:nth-child(2) {
          margin-top: 0;
      }
  
      h5 {
          font-weight: 400;
          font-size: 3.067vw;
          color: #000000;
          margin: 1.479vw 6.667vw 2.479vw;
      }
  
      h2 {
          font-weight: 700;
          font-size: 5.5vw;
          color: #005DFF;
          margin-right: 0.156vw;
          margin-bottom: 0px;
      }
      span {
          vertical-align: super;
          font-weight: 700;
          font-size: 5.5vw;
          color: #005DFF;
      }
      h4 {
          font-weight: 400;
          font-size: 3vw;
          color: #000000;
          margin-bottom: 0px;
      }
      .row {
         height: 38vw;
         width: 100vw;
         overflow: hidden;
         margin: 0 auto;
         margin-top: 5.3vw;
      }
      .col {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          padding: 0px;
          div {
              height: 15.67vw;
              display: inline;
          }
      }
     
  }
  

    .marquee-brand {
      position: absolute;
      width: 100vw;
      overflow-x: hidden;
          #box {
            min-width: 100%;
            display: flex;
            position: relative;
            box-sizing: border-box;
            transform: translate3d(0,0,0);
          }
          
          #box::after {
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          
          .box-area {
            display: flex;
          }
          
          // #box:hover .logo-top {
          //   animation-play-state: paused;
          // }
          
          .logo-top {
            display: flex;
            position: relative;
          /*   z-index: 1; */
          }
          
          .logo-top {
            will-change: transform;
            -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
            animation: marquee 70s linear infinite;
            transform-origin: center center;
            
          }
          .logo-top-one {
            display: flex;
            position: relative;
          /*   z-index: 1; */
          }
          
          .logo-top-one {
            will-change: transform;
            -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
            animation: marquee  70s linear infinite;
            transform-origin: center center;  
            margin-left: 10vw;
          }
          
          @keyframes marquee {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
          
          a {
            display: block;
            width: 32vw;
            height: 13vw;
            overflow: hidden;
            margin: 1.333vw 1.667vw;
          }
          
          img {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
    
    }
    
}
