.hmnav {
    height: var(--header-top);
    background-color: #fff !important;
    padding: 0;
    box-shadow: none !important;

    .row {
        margin: 0px !important;
    }

    a {
        text-decoration: none;
    }
}

.hm-navbar-nav li {
    position: relative !important;
    margin-bottom: 0px !important;

    .hm-method {
        position: absolute;
        top: 2.85vw !important;
        width: 18.5vw !important;
        height: 20.833vw;
        left: -7vw !important;
        box-sizing: border-box;
        padding: 1.198vw 1vw 0vw 3.521vw;
        padding-top: 1.198vw;
        background: #FFFFFF;
        border-radius: 0vw 0vw 1.042vw 1.042vw;
        border: 0.052vw solid #EFEFEF;

        &:before {
            @include media-breakpoint-up(md) {
                content: '';
                top: -0.417vw;
                position: absolute;
                left: 9vw;
                border-top: 0.833vw solid #fff;
                border-left: 0.833vw solid #fff;
                transform: rotate(45deg);
                box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
            }
        }
    }
    .dropdown-menu-son {
        display: flex;
        flex-direction: column;

    }

    .dropdown-menu-son:nth-child(1) {

        width: 25.469vw;
        margin-right: 2.083vw;

        .title {
            border-bottom: 0.156vw solid #4878e3;
        }

    }

    .dropdown-menu-son:nth-child(2) {
        width: 13.802vw;
        margin-right: 2.083vw;

        .title {
            &::before {
                width: 13.802vw;
            }
        }
    }

    .dropdown-menu-son:nth-child(3) {
        width: 15.313vw;

        .title {
            &::before {
                width: 15.313vw;
            }
        }
    }

    .title {
        position: relative;
        font-size: 1.146vw;
        color: #0089FF;
        font-weight: bold;
        display: inline-block;
        padding-bottom: 0.26vw;

        &::before {
            position: absolute;
            bottom: -0.078vw;
            left: 0vw;
            content: "";
            height: 0.052vw;
            z-index: -1;
            background: #E7E7E7;
        }

        margin-bottom: 1.563vw;
    }

    .dropdown-menu-item {
        font-size: 0.885vw;
        color: #000000;
        line-height: 1.667vw;
        cursor: pointer;

        &::before {
            display: inline-block;
            content: "";
            width: 0.156vw;
            height: 0.156vw;
            background: #0089FF;
            vertical-align: middle;
            margin-right: 0.365vw;
        }

        a:hover {
            color: #0089FF;
        }

        a {
            color: #000000;
        }

        .active {
            color: #0089FF;
        }
    }

    .list-group-item a {

        color: #4878e3;
    }

    .hm-resource {
        position: absolute;
        left: -14vw !important;
        top: 2.85vw !important;
        width: 35.052vw;
        background: #FFFFFF;
        border-radius: 0vw 0vw 1.042vw 1.042vw;
        border: 0.052vw solid #EFEFEF;
        padding: 1.198vw 5.069vw 0vw 5.469vw;
        box-sizing: border-box;

        &:before {
            @include media-breakpoint-up(md) {
                content: '';
                top: -0.417rem;
                position: absolute;
                left: 16vw;
                border-top: 0.833vw solid #fff;
                border-left: 0.833vw solid #fff;
                transform: rotate(45deg);
                box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
            }
        }

        h1 {
            font-weight: bold;
            font-size: 1.146vw;
            color: #A9A9A9;
            border-bottom: 0.104vw solid #E7E7E7;
            padding-bottom: 0.521vw;
            color: #0089FF;
            // margin-bottom: 1.823vw;
        }

        h4 {
            font-weight: 600;
            font-size: 1.042vw;
            color: #000000;
            margin:  1vw 0;
        }

        .row {
            cursor: pointer;
        }

        .col-6 {
            height: 100%;
            padding: 0.677vw;
        }

        a {
            display: flex;
            flex-direction: column;

        }

        .col-6:hover {
            h4 {
                color: #0089FF;
            }

            background: #F4FAFF;
        }

        .extra-col {
            height: 6.927vw;
        }

        span {
            display: inline-block;
            white-space: nowrap;
            font-weight: 400;
            font-size: 0.729vw;
            color: #7F7F7F;
        }
    }

    .hm-solution {
        position: absolute;
        left: -21vw !important;
        top: 2.85vw !important;
        width: 45.052vw;
        height: 18.229vw;
        background: #FFFFFF;
        border-radius: 0vw 0vw 1.042vw 1.042vw;
        border: 0.052vw solid #EFEFEF;
        padding: 1.198vw 5.069vw 0vw 5.469vw;
        box-sizing: border-box;

        &:before {
            @include media-breakpoint-up(md) {
                content: '';
                top: -0.417vw;
                position: absolute;
                left: 22.90vw;
                border-top: 0.833vw solid #fff;
                border-left: 0.833vw solid #fff;
                transform: rotate(45deg);
                box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
            }
        }

        h1 {
            font-weight: bold;
            font-size: 1.146vw;
            color: #A9A9A9;
            border-bottom: 0.104vw solid #E7E7E7;
            padding-bottom: 0.521vw;
            color: #0089FF;
            // margin-bottom: 1.823vw;
        }

        h4 {
            font-weight: 600;
            font-size: 1.042vw;
            color: #000000;
            margin-bottom: 0.521vw;
        }

        .row {
            cursor: pointer;
            height: 4.052vw;
        }

        .col-6 {
            height: 100%;
            padding: 0.677vw;
        }

        a {
            display: flex;
            flex-direction: column;

        }

        .col-6:hover {
            h4 {
                color: #0089FF;
            }

            background: #F4FAFF;
        }

        .extra-col {
            height: 6.927vw;
        }

        span {
            display: inline-block;
            white-space: nowrap;
            font-weight: 400;
            font-size: 0.729vw;
            color: #7F7F7F;
        }
    }

    .hm-support {
        position: absolute;
        left: -21vw !important;
        top: 2.85vw !important;
        width: 15.833vw;
        background: #FFFFFF;
        border-radius: 0vw 0vw 1.042vw 1.042vw;
        border: 0.052vw solid #EFEFEF;
        padding: 1.198vw 2.069vw 0vw 2.469vw;
        margin-left: 15vw;
        box-sizing: border-box;

        &:before {
            @include media-breakpoint-up(md) {
                content: '';
                top: -0.417vw;
                position: absolute;
                left: 8vw;
                border-top: 0.833vw solid #fff;
                border-left: 0.833vw solid #fff;
                transform: rotate(45deg);
                box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
            }
        }

        h1 {
            font-weight: bold;
            font-size: 1.146vw;
            color: #A9A9A9;
            border-bottom: 0.104vw solid #E7E7E7;
            padding-bottom: 0.521vw;
            color: #0089FF;
            // margin-bottom: 1.823vw;
        }

        h4 {
            font-weight: 600;
            font-size: 1.042vw;
            color: #000000;
            margin-bottom: 0.26vw;
        }

        .row {
            cursor: pointer;

            a:hover {
                background: #F4FAFF !important;
                color: #0089FF !important;
            }
        }

        div[class^="col"] {
            height: 100%;
            padding: 0.677vw;
        }

        div[class^="col"]:hover {
            a {
                color: #0089FF;
            }

            background: #F4FAFF;
        }

        span {
            white-space: nowrap;
            font-weight: 400;
            font-size: 0.729vw;
            color: #7F7F7F;
        }

        a {

            display: flex;
            flex-direction: column;
            padding: 0.651vw;
        }
    }

    .hm-know {
        position: absolute;
        left: -38.5vw !important;
        top: 2.85vw !important;
        width: 55.052vw;
        height: 9.219vw;
        background: #FFFFFF;
        border-radius: 0vw 0vw 1.042vw 1.042vw;
        border: 0.052vw solid #EFEFEF;
        padding: 1.198vw 5.069vw 0vw 5.469vw;
        box-sizing: border-box;

        &:before {
            @include media-breakpoint-up(md) {
                content: '';
                top: -0.417vw;
                position: absolute;
                left: 40.6vw;
                border-top: 0.833vw solid #fff;
                border-left: 0.833vw solid #fff;
                transform: rotate(45deg);
                box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
            }
        }

        .split {
            display: flex;
            border-bottom: 0.104vw solid #E7E7E7;
            margin-bottom: 0.833vw;
        }

        h1 {
            display: inline-block;
            font-weight: bold;
            font-size: 1.146vw;
            color: #A9A9A9;
            border-bottom: 0rem;
            margin-bottom: 0.521vw;
            color: #0089FF;

        }

        h1:not(:last-child) {
            margin-right: 9.896vw;
        }

        .row {

            height: 1.771vw;
        }

        a {
            font-weight: 300;
            font-size: 0.885vw;
            color: #000000;

            padding: 0rem;

            &:hover {
                color: #0089FF;
            }
        }
    }

}

.hm-navbar-nav ul {
    list-style-type: none;

    .nav-item {
        height: var(--header-top);
    }
}

.hm-22year {
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/22year.png');
    /* 设置图片路径 */
    background-size: cover;
    /* 背景图覆盖整个元素 */
    background-repeat: no-repeat;
    /* 背景图不重复 */
    background-position: center;
    /* 背景图居中 */
    width: 16.021vw;
    /* 元素宽度 */
    height: 1.758vw;
    /* 元素高度 */
    margin-right: 1.354vw !important;
    margin-left: 1.354vw !important;
}

.hm-22year-a {
    height: var(--header-top);
    display: flex;
    align-items: center;
}

.hm-22year-md {
    display: block;
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/22year.png');
    /* 设置图片路径 */
    background-size: cover;
    /* 背景图覆盖整个元素 */
    background-repeat: no-repeat;
    /* 背景图不重复 */
    background-position: center;
    /* 背景图居中 */
    width: 18rem;
    /* 元素宽度 */
    height: 2rem;
    margin-right: 1.62rem !important;
    margin-left: 1.62rem !important;
}

.end-nac-item {
    display: flex;
    align-items: center;
    font-weight: bold !important;
    font-size: 1.042vw !important;
    color: #005DFF !important;
    margin-left: 1.581vw;
    white-space: nowrap;

    .nav-item-apply {
        text-align: center;
        width: 6.25vw;
        height: 1.823vw;
        line-height: 1.823vw;
        background: #005DFF;
        border-radius: 0.885vw 0.885vw 0.885vw 0.885vw;
        font-weight: 400;
        font-size: 0.938vw;
        color: #FFFFFF;
        cursor: pointer;
        margin: 0vw 1.563vw 0vw 1.198vw;
    }

    .nav-item-login {
        text-align: center;
        font-weight: 400;
        font-size: 0.938vw;
        color: #005DFF;
        width: 6.302vw;
        height: 1.875vw;
        line-height: 1.875vw;
        background: #FFFFFF;
        border-radius: 0.885vw 0.885vw 0.885vw 0.885vw;
        border: 0.052vw solid #005DFF;
        cursor: pointer;
    }

    i {
        width: 1.875vw;
        height: 1.875vw;
    }

}

.hm-navbar-nav {
    margin: 0;
    height: 100%;
    justify-content: center;

    .dropdown-toggle {
        font-weight: 400;
        font-size: 0.833vw;
        white-space: nowrap;
        width: 3.854vw;
        margin-right: 2.083vw;
    }
    .dropdown-toggles {
        font-weight: 400;
        font-size: 0.833vw;
        white-space: nowrap;
        margin-right: 0.1vw;
    }
}

.navbar-collapse {
    padding-left: 2vw;
}

.hm-navbarApp {
    box-shadow: 1px 1px 4px #ccc;
    .hm-navbar-nav-md {
        box-sizing: border-box;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding-inline-start: 0 !important;
        margin: 0;

        .list-group a {
            color: #000;
            padding: .625rem;
        }
        .nav-link {
            padding: 3vw  0!important;
            font-size: 4vw !important;
        }

        .list-group a:hover {
            color: #4878e3;
        }

        .hm-method {
            .dropdown-menu-son {
                padding: 1.333vw;

                ul li {
                    list-style-type: none;
                }
            }
        }

        .hm-resource {
            h4, h1 {
                font-size: 4vw !important;
                color: #000000;
                margin-bottom: .6252rem;
            }

            .row {
                cursor: pointer;
            }

            .col-12,
            .col-10 {
                padding: 3vw;
            }

            a {
                display: flex;
                flex-direction: column;
            }

            .col-12:hover,
            .col-10:hover {
                h4 {
                    color: #0089FF;
                }

                background: #F4FAFF;
            }

            span {
                display: inline-block;
                white-space: nowrap;
                font-weight: 400;
                font-size: .8748rem;
                color: #7F7F7F;
            }
            .menu-img{
                width: 70vw;
            }
        }

        .dropdown-toggle {
            font-weight: 400;
            font-size: 1.11rem;
            white-space: nowrap;
        }

        .hm-method {
            .dropdown-toggle {
                padding-left: 4vw;
            }

            .dropdown-menu-son {
                padding: 0;
            }
        }

        .dropdown-menu {
            box-shadow: none;
            max-height: 100vw;
            overflow-y: auto;
        }

        .dropdown-menu-item {
            font-size: 1.2004rem;
            color: #000000;
            line-height: 6vw;
            margin-left: 5vw;
            margin-top: 5vw;
            cursor: pointer;
            &:nth-child(1){
                margin-top: 0;
            }
            a:hover {
                color: #0089FF;
            }

            a {
                color: #000000;
            }

            .active {
                color: #0089FF;
            }
        }
    }
}

.navbar-toggler-icon {
    width: 2rem;
    height: 2rem;
}


.nav-item.dropdown {
    position: relative;
    /* 使伪元素相对于li定位 */
}

.nav-item.dropdown::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #007bff;
    transition: width 1s ease;
}

.nav-item.dropdown:hover::before,
.nav-item.dropdown:focus-within::before {
    width: 100%;
}
.nav-item.dropdown.active::before {
    width: 100%;
}