#carouselExampleCaptions {
  height: 34.375vw;
  overflow-y: hidden;
margin-top:var(--header-top);
 video {
    height: 34.375vw;
    width: 100vw;
  }
  
  
  .carousel-caption {
    left: 16.615vw;
    bottom: 5.775vw;
    text-align: left;
    h5 {
      font-weight: 600;
      font-size: 2.092vw;
      color: #ffffff;
      line-height: 2.8vw;
      margin-bottom: 3.358vw;
      span{
        display: inline;
        font-weight: 1000;
        font-size: 2.865vw;
        background-image: linear-gradient(to right, #ffe579, #ffc83f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        line-height: 2.8vw;
        margin-bottom: 3.358vw;
      }
    }
    p {
      font-weight: 300;
      font-size: 0.8vw;
      color: #ffffff;
      line-height: 1.52vw;
      margin-bottom: 1.042vw;
    }
  }
  .carousel-inner-button {
    width: 10.313vw;
    height: 2.8021vw;
    background: #ffffff;
    border-radius: 1.51vw 1.51vw 1.51vw 1.51vw;
    text-align: center;
    line-height: 2.8021vw;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.146vw;
    color: #2171ff;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .btn-outline-primary {
    height: 2.709vw;
    line-height: 2.709vw;
    font-size: 0.938vw;
    padding: 0 2.083vw;
  }
  .img-fluid-app {
    height: 56.25rem;
    width: 100%;
  }
}
#carouselExampleCaptions-app {
  height: 120vw;
  overflow-y: hidden;
margin-top:var(--header-top);
 video {
    height: 120vw;
    width: 100vw;
  }
  
  
  .carousel-caption {
    left: 50%;
    bottom: 5.775vw;
    transform: translateX(-50%);
  }
  img {
    width: 100%;
    height: 100%;
  }
  .btn-outline-primary {
    height: 7.8vw;
    line-height: 7.8vw;
    font-size: 3.333vw;
    padding: 0 4.083vw;
  }
}
