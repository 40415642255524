@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Nunito, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #005dff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0041b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #7832e2;
  --indigo: #005dff;
  --purple: #ad6edd;
  --pink: #ff0266;
  --red: #ff5916;
  --orange: #fbb500;
  --yellow: #ffde03;
  --green: #44c553;
  --teal: #09ebaf;
  --cyan: #35bdff;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #005dff;
  --secondary: #7832e2;
  --success: #44c553;
  --info: #09ebaf;
  --warning: #ffde03;
  --danger: #ff5916;
  --light: #f8f9fa;
  --dark: #343a40;
  --white: #fff;
  --purple: #ad6edd;
  --salmon: #ff977a;
  --cyan: #35bdff;
  --gray: #ced4da;
  --indigo: #005dff;
  --orange: #fbb500;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1920px;
  --font-family-sans-serif: Nunito, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Nunito, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #005dff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0041b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 85%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #ff0266;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1360px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d2ff;
}

.table-hover .table-primary:hover {
  background-color: #9fc2ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fc2ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9c6f7;
}

.table-hover .table-secondary:hover {
  background-color: #cab0f4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cab0f4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbefcf;
}

.table-hover .table-success:hover {
  background-color: #b8e9bd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b8e9bd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #baf9e9;
}

.table-hover .table-info:hover {
  background-color: #a3f7e2;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a3f7e2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff6b8;
}

.table-hover .table-warning:hover {
  background-color: #fff39f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff39f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd1be;
}

.table-hover .table-danger:hover {
  background-color: #ffbfa5;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffbfa5;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #e8d6f5;
}

.table-hover .table-purple:hover {
  background-color: #ddc2f0;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #ddc2f0;
}

.table-salmon,
.table-salmon > th,
.table-salmon > td {
  background-color: #ffe2da;
}

.table-hover .table-salmon:hover {
  background-color: #ffcec1;
}
.table-hover .table-salmon:hover > td,
.table-hover .table-salmon:hover > th {
  background-color: #ffcec1;
}

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #c6edff;
}

.table-hover .table-cyan:hover {
  background-color: #ade5ff;
}
.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: #ade5ff;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #f1f3f5;
}

.table-hover .table-gray:hover {
  background-color: #e2e6ea;
}
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #e2e6ea;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #b8d2ff;
}

.table-hover .table-indigo:hover {
  background-color: #9fc2ff;
}
.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #9fc2ff;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #feeab8;
}

.table-hover .table-orange:hover {
  background-color: #fee39f;
}
.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #fee39f;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.5rem + 2px);
  padding: 0.45rem 1.2rem;
  font-size: 1rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80aeff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  font-size: 1.15rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  margin-bottom: 0;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(3.025rem + 2px);
  padding: 0.65rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #44c553;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(68, 197, 83, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #44c553;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #44c553;
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #44c553;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #44c553;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #a5e3ad;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6bd177;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(68, 197, 83, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #44c553;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #ff5916;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(255, 89, 22, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff5916;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff5916;
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff5916;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff5916;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ffb496;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff7d49;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 89, 22, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff5916;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.45rem 1.2rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #004fd9;
  border-color: #004acc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004acc;
  border-color: #0046bf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #7832e2;
  border-color: #7832e2;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #651dd0;
  border-color: #5f1cc5;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 50, 226, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #7832e2;
  border-color: #7832e2;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f1cc5;
  border-color: #5a1aba;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 50, 226, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #44c553;
  border-color: #44c553;
}
.btn-success:hover {
  color: #fff;
  background-color: #36ad44;
  border-color: #33a340;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #44c553;
  border-color: #44c553;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #33a340;
  border-color: #309a3c;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.btn-info:hover {
  color: #fff;
  background-color: #08c694;
  border-color: #07ba8a;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 235, 175, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #07ba8a;
  border-color: #07ae81;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 235, 175, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffde03;
  border-color: #ffde03;
}
.btn-warning:hover {
  color: #212529;
  background-color: #dcbf00;
  border-color: #cfb400;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 222, 3, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffde03;
  border-color: #ffde03;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #cfb400;
  border-color: #c2a900;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 222, 3, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ff5916;
  border-color: #ff5916;
}
.btn-danger:hover {
  color: #fff;
  background-color: #ef4500;
  border-color: #e24100;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ff5916;
  border-color: #ff5916;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e24100;
  border-color: #d53d00;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.btn-purple:hover {
  color: #fff;
  background-color: #9b4fd6;
  border-color: #9645d3;
}
.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 110, 221, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #9645d3;
  border-color: #903ad1;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 110, 221, 0.5);
}

.btn-salmon {
  color: #212529;
  background-color: #ff977a;
  border-color: #ff977a;
}
.btn-salmon:hover {
  color: #212529;
  background-color: #ff7954;
  border-color: #ff6f47;
}
.btn-salmon:focus, .btn-salmon.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 122, 0.5);
}
.btn-salmon.disabled, .btn-salmon:disabled {
  color: #212529;
  background-color: #ff977a;
  border-color: #ff977a;
}
.btn-salmon:not(:disabled):not(.disabled):active, .btn-salmon:not(:disabled):not(.disabled).active, .show > .btn-salmon.dropdown-toggle {
  color: #fff;
  background-color: #ff6f47;
  border-color: #ff653a;
}
.btn-salmon:not(:disabled):not(.disabled):active:focus, .btn-salmon:not(:disabled):not(.disabled).active:focus, .show > .btn-salmon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 122, 0.5);
}

.btn-cyan {
  color: #212529;
  background-color: #35bdff;
  border-color: #35bdff;
}
.btn-cyan:hover {
  color: #fff;
  background-color: #0fb1ff;
  border-color: #02acff;
}
.btn-cyan:focus, .btn-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 189, 255, 0.5);
}
.btn-cyan.disabled, .btn-cyan:disabled {
  color: #212529;
  background-color: #35bdff;
  border-color: #35bdff;
}
.btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active, .show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #02acff;
  border-color: #00a4f4;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 189, 255, 0.5);
}

.btn-gray {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-gray:hover {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}
.btn-gray:focus, .btn-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-gray.disabled, .btn-gray:disabled {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show > .btn-gray.dropdown-toggle {
  color: #212529;
  background-color: #b1bbc4;
  border-color: #aab4bf;
}
.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-indigo:hover {
  color: #fff;
  background-color: #004fd9;
  border-color: #004acc;
}
.btn-indigo:focus, .btn-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}
.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active, .show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #004acc;
  border-color: #0046bf;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}

.btn-orange {
  color: #212529;
  background-color: #fbb500;
  border-color: #fbb500;
}
.btn-orange:hover {
  color: #212529;
  background-color: #d59900;
  border-color: #c89000;
}
.btn-orange:focus, .btn-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 181, 0, 0.5);
}
.btn-orange.disabled, .btn-orange:disabled {
  color: #212529;
  background-color: #fbb500;
  border-color: #fbb500;
}
.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active, .show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c89000;
  border-color: #bb8700;
}
.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 181, 0, 0.5);
}

.btn-outline-primary {
  color: #005dff;
  background-color: #fff;
  background-image: none;
  border-color: #005dff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #005dff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}

.btn-outline-secondary {
  color: #7832e2;
  background-color: #fff;
  background-image: none;
  border-color: #7832e2;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #7832e2;
  border-color: #7832e2;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 50, 226, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #7832e2;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #7832e2;
  border-color: #7832e2;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 50, 226, 0.5);
}

.btn-outline-success {
  color: #44c553;
  background-color: #fff;
  background-image: none;
  border-color: #44c553;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #44c553;
  border-color: #44c553;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #44c553;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #44c553;
  border-color: #44c553;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 197, 83, 0.5);
}

.btn-outline-info {
  color: #09ebaf;
  background-color: #fff;
  background-image: none;
  border-color: #09ebaf;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 235, 175, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #09ebaf;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 235, 175, 0.5);
}

.btn-outline-warning {
  color: #ffde03;
  background-color: #fff;
  background-image: none;
  border-color: #ffde03;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffde03;
  border-color: #ffde03;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 222, 3, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffde03;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffde03;
  border-color: #ffde03;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 222, 3, 0.5);
}

.btn-outline-danger {
  color: #ff5916;
  background-color: #fff;
  background-image: none;
  border-color: #ff5916;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff5916;
  border-color: #ff5916;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ff5916;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff5916;
  border-color: #ff5916;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 22, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: #fff;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: #fff;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-white {
  color: #fff;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-purple {
  color: #ad6edd;
  background-color: #fff;
  background-image: none;
  border-color: #ad6edd;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 110, 221, 0.5);
}
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #ad6edd;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 110, 221, 0.5);
}

.btn-outline-salmon {
  color: #ff977a;
  background-color: #fff;
  background-image: none;
  border-color: #ff977a;
}
.btn-outline-salmon:hover {
  color: #212529;
  background-color: #ff977a;
  border-color: #ff977a;
}
.btn-outline-salmon:focus, .btn-outline-salmon.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 122, 0.5);
}
.btn-outline-salmon.disabled, .btn-outline-salmon:disabled {
  color: #ff977a;
  background-color: transparent;
}
.btn-outline-salmon:not(:disabled):not(.disabled):active, .btn-outline-salmon:not(:disabled):not(.disabled).active, .show > .btn-outline-salmon.dropdown-toggle {
  color: #212529;
  background-color: #ff977a;
  border-color: #ff977a;
}
.btn-outline-salmon:not(:disabled):not(.disabled):active:focus, .btn-outline-salmon:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-salmon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 122, 0.5);
}

.btn-outline-cyan {
  color: #35bdff;
  background-color: #fff;
  background-image: none;
  border-color: #35bdff;
}
.btn-outline-cyan:hover {
  color: #212529;
  background-color: #35bdff;
  border-color: #35bdff;
}
.btn-outline-cyan:focus, .btn-outline-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 189, 255, 0.5);
}
.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  color: #35bdff;
  background-color: transparent;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active, .show > .btn-outline-cyan.dropdown-toggle {
  color: #212529;
  background-color: #35bdff;
  border-color: #35bdff;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 189, 255, 0.5);
}

.btn-outline-gray {
  color: #ced4da;
  background-color: #fff;
  background-image: none;
  border-color: #ced4da;
}
.btn-outline-gray:hover {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #ced4da;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-gray.dropdown-toggle {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-indigo {
  color: #005dff;
  background-color: #fff;
  background-image: none;
  border-color: #005dff;
}
.btn-outline-indigo:hover {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}
.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #005dff;
  background-color: transparent;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active, .show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.5);
}

.btn-outline-orange {
  color: #fbb500;
  background-color: #fff;
  background-image: none;
  border-color: #fbb500;
}
.btn-outline-orange:hover {
  color: #212529;
  background-color: #fbb500;
  border-color: #fbb500;
}
.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 181, 0, 0.5);
}
.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fbb500;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active, .show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fbb500;
  border-color: #fbb500;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 181, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #005dff;
  background-color: transparent;
}
.btn-link:hover {
  color: #0041b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.65rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.45rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #005dff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.45rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 1.2rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(3.025rem + 2px);
  padding: 0.65rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #005dff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3ceff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #005dff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #005dff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 93, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 93, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #005dff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 93, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.5rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80aeff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 174, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(3.025rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.5rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80aeff;
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80aeff;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.5rem + 2px);
  padding: 0.45rem 1.2rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.5rem;
  padding: 0.45rem 1.2rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #005dff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3ceff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #005dff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3ceff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #005dff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3ceff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.833vw 0.833vw;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #005dff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.68rem;
  padding-bottom: 0.68rem;
  margin-right: 1rem;
  font-size: 1.15rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  background-color: #fff;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.15rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex;
    flex-basis: auto;
    justify-content: center;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex;
    flex-basis: auto;
    justify-content: center;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex;
    flex-basis: auto;
    justify-content: center;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex;
    flex-basis: auto;
    justify-content: center;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1919.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex;
    flex-basis: auto;
    justify-content: center;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex;
  flex-basis: auto;
  justify-content: center;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: black;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #005dff;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #005dff;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #005dff;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: black;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #005dff;
}
.navbar-dark .navbar-nav .nav-link {
  color: black;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #005dff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #005dff;
}
.navbar-dark .navbar-toggler {
  color: black;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: black;
}
.navbar-dark .navbar-text a {
  color: black;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: black;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.75rem 1rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #005dff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0041b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 93, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.15rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 84%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #005dff;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #004acc;
}

.badge-secondary {
  color: #fff;
  background-color: #7832e2;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #5f1cc5;
}

.badge-success {
  color: #fff;
  background-color: #44c553;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #33a340;
}

.badge-info {
  color: #212529;
  background-color: #09ebaf;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #07ba8a;
}

.badge-warning {
  color: #212529;
  background-color: #ffde03;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cfb400;
}

.badge-danger {
  color: #fff;
  background-color: #ff5916;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #e24100;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.badge-white {
  color: #212529;
  background-color: #fff;
}
.badge-white[href]:hover, .badge-white[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #e6e6e6;
}

.badge-purple {
  color: #fff;
  background-color: #ad6edd;
}
.badge-purple[href]:hover, .badge-purple[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #9645d3;
}

.badge-salmon {
  color: #212529;
  background-color: #ff977a;
}
.badge-salmon[href]:hover, .badge-salmon[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #ff6f47;
}

.badge-cyan {
  color: #212529;
  background-color: #35bdff;
}
.badge-cyan[href]:hover, .badge-cyan[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #02acff;
}

.badge-gray {
  color: #212529;
  background-color: #ced4da;
}
.badge-gray[href]:hover, .badge-gray[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #b1bbc4;
}

.badge-indigo {
  color: #fff;
  background-color: #005dff;
}
.badge-indigo[href]:hover, .badge-indigo[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #004acc;
}

.badge-orange {
  color: #212529;
  background-color: #fbb500;
}
.badge-orange[href]:hover, .badge-orange[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #c89000;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #005dff;
  background-color: #005dff;
  border-color: #005dff;
}
.alert-primary hr {
  border-top-color: #0054e6;
}
.alert-primary .alert-link {
  color: #004acc;
}

.alert-secondary {
  color: #7832e2;
  background-color: #7832e2;
  border-color: #7832e2;
}
.alert-secondary hr {
  border-top-color: #6a1fdb;
}
.alert-secondary .alert-link {
  color: #5f1cc5;
}

.alert-success {
  color: #44c553;
  background-color: #44c553;
  border-color: #44c553;
}
.alert-success hr {
  border-top-color: #39b747;
}
.alert-success .alert-link {
  color: #33a340;
}

.alert-info {
  color: #09ebaf;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.alert-info hr {
  border-top-color: #08d29d;
}
.alert-info .alert-link {
  color: #07ba8a;
}

.alert-warning {
  color: #ffde03;
  background-color: #ffde03;
  border-color: #ffde03;
}
.alert-warning hr {
  border-top-color: #e9ca00;
}
.alert-warning .alert-link {
  color: #cfb400;
}

.alert-danger {
  color: #ff5916;
  background-color: #ff5916;
  border-color: #ff5916;
}
.alert-danger hr {
  border-top-color: #fc4800;
}
.alert-danger .alert-link {
  color: #e24100;
}

.alert-light {
  color: #f8f9fa;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #dae0e5;
}

.alert-dark {
  color: #343a40;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #1d2124;
}

.alert-white {
  color: white;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #e6e6e6;
}

.alert-purple {
  color: #ad6edd;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.alert-purple hr {
  border-top-color: #a159d8;
}
.alert-purple .alert-link {
  color: #9645d3;
}

.alert-salmon {
  color: #ff977a;
  background-color: #ff977a;
  border-color: #ff977a;
}
.alert-salmon hr {
  border-top-color: #ff8361;
}
.alert-salmon .alert-link {
  color: #ff6f47;
}

.alert-cyan {
  color: #35bdff;
  background-color: #35bdff;
  border-color: #35bdff;
}
.alert-cyan hr {
  border-top-color: #1cb5ff;
}
.alert-cyan .alert-link {
  color: #02acff;
}

.alert-gray {
  color: #ced4da;
  background-color: #ced4da;
  border-color: #ced4da;
}
.alert-gray hr {
  border-top-color: #bfc7cf;
}
.alert-gray .alert-link {
  color: #b1bbc4;
}

.alert-indigo {
  color: #005dff;
  background-color: #005dff;
  border-color: #005dff;
}
.alert-indigo hr {
  border-top-color: #0054e6;
}
.alert-indigo .alert-link {
  color: #004acc;
}

.alert-orange {
  color: #fbb500;
  background-color: #fbb500;
  border-color: #fbb500;
}
.alert-orange hr {
  border-top-color: #e2a300;
}
.alert-orange .alert-link {
  color: #c89000;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #005dff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #003085;
  background-color: #b8d2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #003085;
  background-color: #9fc2ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #003085;
  border-color: #003085;
}

.list-group-item-secondary {
  color: #3e1a76;
  background-color: #d9c6f7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3e1a76;
  background-color: #cab0f4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3e1a76;
  border-color: #3e1a76;
}

.list-group-item-success {
  color: #23662b;
  background-color: #cbefcf;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #23662b;
  background-color: #b8e9bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #23662b;
  border-color: #23662b;
}

.list-group-item-info {
  color: #057a5b;
  background-color: #baf9e9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #057a5b;
  background-color: #a3f7e2;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #057a5b;
  border-color: #057a5b;
}

.list-group-item-warning {
  color: #857302;
  background-color: #fff6b8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #857302;
  background-color: #fff39f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857302;
  border-color: #857302;
}

.list-group-item-danger {
  color: #852e0b;
  background-color: #ffd1be;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #852e0b;
  background-color: #ffbfa5;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #852e0b;
  border-color: #852e0b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-purple {
  color: #5a3973;
  background-color: #e8d6f5;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #5a3973;
  background-color: #ddc2f0;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #5a3973;
  border-color: #5a3973;
}

.list-group-item-salmon {
  color: #854f3f;
  background-color: #ffe2da;
}
.list-group-item-salmon.list-group-item-action:hover, .list-group-item-salmon.list-group-item-action:focus {
  color: #854f3f;
  background-color: #ffcec1;
}
.list-group-item-salmon.list-group-item-action.active {
  color: #fff;
  background-color: #854f3f;
  border-color: #854f3f;
}

.list-group-item-cyan {
  color: #1c6285;
  background-color: #c6edff;
}
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: #1c6285;
  background-color: #ade5ff;
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #1c6285;
  border-color: #1c6285;
}

.list-group-item-gray {
  color: #6b6e71;
  background-color: #f1f3f5;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #6b6e71;
  background-color: #e2e6ea;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #6b6e71;
  border-color: #6b6e71;
}

.list-group-item-indigo {
  color: #003085;
  background-color: #b8d2ff;
}
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #003085;
  background-color: #9fc2ff;
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #003085;
  border-color: #003085;
}

.list-group-item-orange {
  color: #835e00;
  background-color: #feeab8;
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #835e00;
  background-color: #fee39f;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #835e00;
  border-color: #835e00;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 0.5rem * 2);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 0.5rem * 2);
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - 1.75rem * 2);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 1.75rem * 2);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Nunito, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Nunito, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-caption-banner2 {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #212529;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #005dff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004acc !important;
}

.bg-secondary {
  background-color: #7832e2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5f1cc5 !important;
}

.bg-success {
  background-color: #44c553 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #33a340 !important;
}

.bg-info {
  background-color: #09ebaf !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #07ba8a !important;
}

.bg-warning {
  background-color: #ffde03 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cfb400 !important;
}

.bg-danger {
  background-color: #ff5916 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e24100 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-purple {
  background-color: #ad6edd !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #9645d3 !important;
}

.bg-salmon {
  background-color: #ff977a !important;
}

a.bg-salmon:hover, a.bg-salmon:focus,
button.bg-salmon:hover,
button.bg-salmon:focus {
  background-color: #ff6f47 !important;
}

.bg-cyan {
  background-color: #35bdff !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #02acff !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important;
}

.bg-indigo {
  background-color: #005dff !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #004acc !important;
}

.bg-orange {
  background-color: #fbb500 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c89000 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #005dff !important;
}

.border-secondary {
  border-color: #7832e2 !important;
}

.border-success {
  border-color: #44c553 !important;
}

.border-info {
  border-color: #09ebaf !important;
}

.border-warning {
  border-color: #ffde03 !important;
}

.border-danger {
  border-color: #ff5916 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-purple {
  border-color: #ad6edd !important;
}

.border-salmon {
  border-color: #ff977a !important;
}

.border-cyan {
  border-color: #35bdff !important;
}

.border-gray {
  border-color: #ced4da !important;
}

.border-indigo {
  border-color: #005dff !important;
}

.border-orange {
  border-color: #fbb500 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #005dff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #004acc !important;
}

.text-secondary {
  color: #7832e2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #5f1cc5 !important;
}

.text-success {
  color: #44c553 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #33a340 !important;
}

.text-info {
  color: #09ebaf !important;
}

a.text-info:hover, a.text-info:focus {
  color: #07ba8a !important;
}

.text-warning {
  color: #ffde03 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #cfb400 !important;
}

.text-danger {
  color: #ff5916 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e24100 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-purple {
  color: #ad6edd !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #9645d3 !important;
}

.text-salmon {
  color: #ff977a !important;
}

a.text-salmon:hover, a.text-salmon:focus {
  color: #ff6f47 !important;
}

.text-cyan {
  color: #35bdff !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #02acff !important;
}

.text-gray {
  color: #ced4da !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #b1bbc4 !important;
}

.text-indigo {
  color: #005dff !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #004acc !important;
}

.text-orange {
  color: #fbb500 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #c89000 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1360px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
:root {
  --header-top: 3.125vw;
  font-size: 16px; /* 默认字体大小 */
}

@media (max-width: 960px) {
  :root {
    --header-top:4.525rem;
    font-size: 14px; /* 在较小屏幕上减小字体大小 */
  }
}
.hmnav {
  height: var(--header-top);
  background-color: #fff !important;
  padding: 0;
  box-shadow: none !important;
}
.hmnav .row {
  margin: 0px !important;
}
.hmnav a {
  text-decoration: none;
}

.hm-navbar-nav li {
  position: relative !important;
  margin-bottom: 0px !important;
}
.hm-navbar-nav li .hm-method {
  position: absolute;
  top: 2.85vw !important;
  width: 18.5vw !important;
  height: 20.833vw;
  left: -7vw !important;
  box-sizing: border-box;
  padding: 1.198vw 1vw 0vw 3.521vw;
  padding-top: 1.198vw;
  background: #FFFFFF;
  border-radius: 0vw 0vw 1.042vw 1.042vw;
  border: 0.052vw solid #EFEFEF;
}
@media (min-width: 768px) {
  .hm-navbar-nav li .hm-method:before {
    content: "";
    top: -0.417vw;
    position: absolute;
    left: 9vw;
    border-top: 0.833vw solid #fff;
    border-left: 0.833vw solid #fff;
    transform: rotate(45deg);
    box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
  }
}
.hm-navbar-nav li .dropdown-menu-son {
  display: flex;
  flex-direction: column;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(1) {
  width: 25.469vw;
  margin-right: 2.083vw;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(1) .title {
  border-bottom: 0.156vw solid #4878e3;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(2) {
  width: 13.802vw;
  margin-right: 2.083vw;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(2) .title::before {
  width: 13.802vw;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(3) {
  width: 15.313vw;
}
.hm-navbar-nav li .dropdown-menu-son:nth-child(3) .title::before {
  width: 15.313vw;
}
.hm-navbar-nav li .title {
  position: relative;
  font-size: 1.146vw;
  color: #0089FF;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 0.26vw;
  margin-bottom: 1.563vw;
}
.hm-navbar-nav li .title::before {
  position: absolute;
  bottom: -0.078vw;
  left: 0vw;
  content: "";
  height: 0.052vw;
  z-index: -1;
  background: #E7E7E7;
}
.hm-navbar-nav li .dropdown-menu-item {
  font-size: 0.885vw;
  color: #000000;
  line-height: 1.667vw;
  cursor: pointer;
}
.hm-navbar-nav li .dropdown-menu-item::before {
  display: inline-block;
  content: "";
  width: 0.156vw;
  height: 0.156vw;
  background: #0089FF;
  vertical-align: middle;
  margin-right: 0.365vw;
}
.hm-navbar-nav li .dropdown-menu-item a:hover {
  color: #0089FF;
}
.hm-navbar-nav li .dropdown-menu-item a {
  color: #000000;
}
.hm-navbar-nav li .dropdown-menu-item .active {
  color: #0089FF;
}
.hm-navbar-nav li .list-group-item a {
  color: #4878e3;
}
.hm-navbar-nav li .hm-resource {
  position: absolute;
  left: -14vw !important;
  top: 2.85vw !important;
  width: 35.052vw;
  background: #FFFFFF;
  border-radius: 0vw 0vw 1.042vw 1.042vw;
  border: 0.052vw solid #EFEFEF;
  padding: 1.198vw 5.069vw 0vw 5.469vw;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .hm-navbar-nav li .hm-resource:before {
    content: "";
    top: -0.417rem;
    position: absolute;
    left: 16vw;
    border-top: 0.833vw solid #fff;
    border-left: 0.833vw solid #fff;
    transform: rotate(45deg);
    box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
  }
}
.hm-navbar-nav li .hm-resource h1 {
  font-weight: bold;
  font-size: 1.146vw;
  color: #A9A9A9;
  border-bottom: 0.104vw solid #E7E7E7;
  padding-bottom: 0.521vw;
  color: #0089FF;
}
.hm-navbar-nav li .hm-resource h4 {
  font-weight: 600;
  font-size: 1.042vw;
  color: #000000;
  margin: 1vw 0;
}
.hm-navbar-nav li .hm-resource .row {
  cursor: pointer;
}
.hm-navbar-nav li .hm-resource .col-6 {
  height: 100%;
  padding: 0.677vw;
}
.hm-navbar-nav li .hm-resource a {
  display: flex;
  flex-direction: column;
}
.hm-navbar-nav li .hm-resource .col-6:hover {
  background: #F4FAFF;
}
.hm-navbar-nav li .hm-resource .col-6:hover h4 {
  color: #0089FF;
}
.hm-navbar-nav li .hm-resource .extra-col {
  height: 6.927vw;
}
.hm-navbar-nav li .hm-resource span {
  display: inline-block;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.729vw;
  color: #7F7F7F;
}
.hm-navbar-nav li .hm-solution {
  position: absolute;
  left: -21vw !important;
  top: 2.85vw !important;
  width: 45.052vw;
  height: 18.229vw;
  background: #FFFFFF;
  border-radius: 0vw 0vw 1.042vw 1.042vw;
  border: 0.052vw solid #EFEFEF;
  padding: 1.198vw 5.069vw 0vw 5.469vw;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .hm-navbar-nav li .hm-solution:before {
    content: "";
    top: -0.417vw;
    position: absolute;
    left: 22.9vw;
    border-top: 0.833vw solid #fff;
    border-left: 0.833vw solid #fff;
    transform: rotate(45deg);
    box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
  }
}
.hm-navbar-nav li .hm-solution h1 {
  font-weight: bold;
  font-size: 1.146vw;
  color: #A9A9A9;
  border-bottom: 0.104vw solid #E7E7E7;
  padding-bottom: 0.521vw;
  color: #0089FF;
}
.hm-navbar-nav li .hm-solution h4 {
  font-weight: 600;
  font-size: 1.042vw;
  color: #000000;
  margin-bottom: 0.521vw;
}
.hm-navbar-nav li .hm-solution .row {
  cursor: pointer;
  height: 4.052vw;
}
.hm-navbar-nav li .hm-solution .col-6 {
  height: 100%;
  padding: 0.677vw;
}
.hm-navbar-nav li .hm-solution a {
  display: flex;
  flex-direction: column;
}
.hm-navbar-nav li .hm-solution .col-6:hover {
  background: #F4FAFF;
}
.hm-navbar-nav li .hm-solution .col-6:hover h4 {
  color: #0089FF;
}
.hm-navbar-nav li .hm-solution .extra-col {
  height: 6.927vw;
}
.hm-navbar-nav li .hm-solution span {
  display: inline-block;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.729vw;
  color: #7F7F7F;
}
.hm-navbar-nav li .hm-support {
  position: absolute;
  left: -21vw !important;
  top: 2.85vw !important;
  width: 15.833vw;
  background: #FFFFFF;
  border-radius: 0vw 0vw 1.042vw 1.042vw;
  border: 0.052vw solid #EFEFEF;
  padding: 1.198vw 2.069vw 0vw 2.469vw;
  margin-left: 15vw;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .hm-navbar-nav li .hm-support:before {
    content: "";
    top: -0.417vw;
    position: absolute;
    left: 8vw;
    border-top: 0.833vw solid #fff;
    border-left: 0.833vw solid #fff;
    transform: rotate(45deg);
    box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
  }
}
.hm-navbar-nav li .hm-support h1 {
  font-weight: bold;
  font-size: 1.146vw;
  color: #A9A9A9;
  border-bottom: 0.104vw solid #E7E7E7;
  padding-bottom: 0.521vw;
  color: #0089FF;
}
.hm-navbar-nav li .hm-support h4 {
  font-weight: 600;
  font-size: 1.042vw;
  color: #000000;
  margin-bottom: 0.26vw;
}
.hm-navbar-nav li .hm-support .row {
  cursor: pointer;
}
.hm-navbar-nav li .hm-support .row a:hover {
  background: #F4FAFF !important;
  color: #0089FF !important;
}
.hm-navbar-nav li .hm-support div[class^=col] {
  height: 100%;
  padding: 0.677vw;
}
.hm-navbar-nav li .hm-support div[class^=col]:hover {
  background: #F4FAFF;
}
.hm-navbar-nav li .hm-support div[class^=col]:hover a {
  color: #0089FF;
}
.hm-navbar-nav li .hm-support span {
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.729vw;
  color: #7F7F7F;
}
.hm-navbar-nav li .hm-support a {
  display: flex;
  flex-direction: column;
  padding: 0.651vw;
}
.hm-navbar-nav li .hm-know {
  position: absolute;
  left: -38.5vw !important;
  top: 2.85vw !important;
  width: 55.052vw;
  height: 9.219vw;
  background: #FFFFFF;
  border-radius: 0vw 0vw 1.042vw 1.042vw;
  border: 0.052vw solid #EFEFEF;
  padding: 1.198vw 5.069vw 0vw 5.469vw;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .hm-navbar-nav li .hm-know:before {
    content: "";
    top: -0.417vw;
    position: absolute;
    left: 40.6vw;
    border-top: 0.833vw solid #fff;
    border-left: 0.833vw solid #fff;
    transform: rotate(45deg);
    box-shadow: -0.052vw -0.052vw 0.156vw #f2f2f2;
  }
}
.hm-navbar-nav li .hm-know .split {
  display: flex;
  border-bottom: 0.104vw solid #E7E7E7;
  margin-bottom: 0.833vw;
}
.hm-navbar-nav li .hm-know h1 {
  display: inline-block;
  font-weight: bold;
  font-size: 1.146vw;
  color: #A9A9A9;
  border-bottom: 0rem;
  margin-bottom: 0.521vw;
  color: #0089FF;
}
.hm-navbar-nav li .hm-know h1:not(:last-child) {
  margin-right: 9.896vw;
}
.hm-navbar-nav li .hm-know .row {
  height: 1.771vw;
}
.hm-navbar-nav li .hm-know a {
  font-weight: 300;
  font-size: 0.885vw;
  color: #000000;
  padding: 0rem;
}
.hm-navbar-nav li .hm-know a:hover {
  color: #0089FF;
}

.hm-navbar-nav ul {
  list-style-type: none;
}
.hm-navbar-nav ul .nav-item {
  height: var(--header-top);
}

.hm-22year {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/22year.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 16.021vw;
  /* 元素宽度 */
  height: 1.758vw;
  /* 元素高度 */
  margin-right: 1.354vw !important;
  margin-left: 1.354vw !important;
}

.hm-22year-a {
  height: var(--header-top);
  display: flex;
  align-items: center;
}

.hm-22year-md {
  display: block;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/22year.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 18rem;
  /* 元素宽度 */
  height: 2rem;
  margin-right: 1.62rem !important;
  margin-left: 1.62rem !important;
}

.end-nac-item {
  display: flex;
  align-items: center;
  font-weight: bold !important;
  font-size: 1.042vw !important;
  color: #005DFF !important;
  margin-left: 1.581vw;
  white-space: nowrap;
}
.end-nac-item .nav-item-apply {
  text-align: center;
  width: 6.25vw;
  height: 1.823vw;
  line-height: 1.823vw;
  background: #005DFF;
  border-radius: 0.885vw 0.885vw 0.885vw 0.885vw;
  font-weight: 400;
  font-size: 0.938vw;
  color: #FFFFFF;
  cursor: pointer;
  margin: 0vw 1.563vw 0vw 1.198vw;
}
.end-nac-item .nav-item-login {
  text-align: center;
  font-weight: 400;
  font-size: 0.938vw;
  color: #005DFF;
  width: 6.302vw;
  height: 1.875vw;
  line-height: 1.875vw;
  background: #FFFFFF;
  border-radius: 0.885vw 0.885vw 0.885vw 0.885vw;
  border: 0.052vw solid #005DFF;
  cursor: pointer;
}
.end-nac-item i {
  width: 1.875vw;
  height: 1.875vw;
}

.hm-navbar-nav {
  margin: 0;
  height: 100%;
  justify-content: center;
}
.hm-navbar-nav .dropdown-toggle {
  font-weight: 400;
  font-size: 0.833vw;
  white-space: nowrap;
  width: 3.854vw;
  margin-right: 2.083vw;
}
.hm-navbar-nav .dropdown-toggles {
  font-weight: 400;
  font-size: 0.833vw;
  white-space: nowrap;
  margin-right: 0.1vw;
}

.navbar-collapse {
  padding-left: 2vw;
}

.hm-navbarApp {
  box-shadow: 1px 1px 4px #ccc;
}
.hm-navbarApp .hm-navbar-nav-md {
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0 !important;
  margin: 0;
}
.hm-navbarApp .hm-navbar-nav-md .list-group a {
  color: #000;
  padding: 0.625rem;
}
.hm-navbarApp .hm-navbar-nav-md .nav-link {
  padding: 3vw 0 !important;
  font-size: 4vw !important;
}
.hm-navbarApp .hm-navbar-nav-md .list-group a:hover {
  color: #4878e3;
}
.hm-navbarApp .hm-navbar-nav-md .hm-method .dropdown-menu-son {
  padding: 1.333vw;
}
.hm-navbarApp .hm-navbar-nav-md .hm-method .dropdown-menu-son ul li {
  list-style-type: none;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource h4, .hm-navbarApp .hm-navbar-nav-md .hm-resource h1 {
  font-size: 4vw !important;
  color: #000000;
  margin-bottom: 0.6252rem;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource .row {
  cursor: pointer;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-12,
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-10 {
  padding: 3vw;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource a {
  display: flex;
  flex-direction: column;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-12:hover,
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-10:hover {
  background: #F4FAFF;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-12:hover h4,
.hm-navbarApp .hm-navbar-nav-md .hm-resource .col-10:hover h4 {
  color: #0089FF;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource span {
  display: inline-block;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.8748rem;
  color: #7F7F7F;
}
.hm-navbarApp .hm-navbar-nav-md .hm-resource .menu-img {
  width: 70vw;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-toggle {
  font-weight: 400;
  font-size: 1.11rem;
  white-space: nowrap;
}
.hm-navbarApp .hm-navbar-nav-md .hm-method .dropdown-toggle {
  padding-left: 4vw;
}
.hm-navbarApp .hm-navbar-nav-md .hm-method .dropdown-menu-son {
  padding: 0;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu {
  box-shadow: none;
  max-height: 100vw;
  overflow-y: auto;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu-item {
  font-size: 1.2004rem;
  color: #000000;
  line-height: 6vw;
  margin-left: 5vw;
  margin-top: 5vw;
  cursor: pointer;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu-item:nth-child(1) {
  margin-top: 0;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu-item a:hover {
  color: #0089FF;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu-item a {
  color: #000000;
}
.hm-navbarApp .hm-navbar-nav-md .dropdown-menu-item .active {
  color: #0089FF;
}

.navbar-toggler-icon {
  width: 2rem;
  height: 2rem;
}

.nav-item.dropdown {
  position: relative;
  /* 使伪元素相对于li定位 */
}

.nav-item.dropdown::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #007bff;
  transition: width 1s ease;
}

.nav-item.dropdown:hover::before,
.nav-item.dropdown:focus-within::before {
  width: 100%;
}

.nav-item.dropdown.active::before {
  width: 100%;
}

#hm-call {
  position: relative;
  display: flex;
  align-items: center;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg3.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 13.75vw; /* 元素高度 */
}
#hm-call .hm-call-employee {
  position: absolute;
  right: 16.198vw;
  bottom: 0px;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/employee.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 13.177vw; /* 元素宽度 */
  height: 17.5vw; /* 元素高度 */
}
#hm-call .hm-call-text {
  font-weight: 600;
  font-size: 1.692vw;
  color: #FFFFFF;
  margin: 0vw 14.546vw 0vw 16.719vw;
}
#hm-call .hm-call-btn {
  width: 9.635vw;
  height: 2.813vw;
  line-height: 2.813vw;
  border-radius: 1.354vw 1.354vw 1.354vw 1.354vw;
  border: 0.052vw solid #FFFFFF;
  font-weight: 400;
  font-size: 1.146vw;
  color: #FFFFFF;
  text-align: center;
  margin-right: 1.667vw;
  cursor: pointer;
}
#hm-call .chat-icon {
  display: inline-block;
  vertical-align: sub;
  margin-right: 0.26vw;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/tv.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 1.354vw;
  height: 1.354vw;
}
#hm-call .tv-icon {
  display: inline-block;
  vertical-align: sub;
  margin-right: 0.26vw;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/chat.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 1.354vw;
  height: 1.354vw;
}

#hm-call-app {
  position: relative;
  display: flex;
  align-items: center;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/矢量智能对象@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 35.2vw; /* 元素高度 */
}
#hm-call-app .hm-call-employee {
  position: absolute;
  right: 1vw;
  bottom: 0;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/employee@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 33.733vw; /* 元素宽度 */
  height: 44.8vw; /* 元素高度 */
}
#hm-call-app .hm-call-btn {
  width: 30vw;
  height: 8vw;
  line-height: 8vw;
  border-radius: 4vw;
  border: 0.052vw solid #FFFFFF;
  font-weight: 400;
  font-size: 4.146vw;
  color: #FFFFFF;
  text-align: center;
  margin-right: 3.667vw;
  cursor: pointer;
}
#hm-call-app .hm-call-btn:nth-child(1) {
  margin-left: 4vw;
}
#hm-call-app .chat-icon {
  display: inline-block;
  vertical-align: sub;
  margin: 0 1.333vw;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/tv.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 5vw;
  height: 5.354vw;
}
#hm-call-app .tv-icon {
  display: inline-block;
  margin: 0 1.333vw;
  vertical-align: sub;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/chat.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 5vw;
  height: 5.354vw;
}

.hm-footer-container {
  background-color: #F7F7F7;
  padding-top: 2.656vw;
}
.hm-footer-container h5 {
  font-weight: 400;
  font-size: 1.25vw;
  color: #000000 !important;
}
.hm-footer-container li {
  font-weight: 400;
  font-size: 0.833vw;
  color: #7E7E7E;
  font-weight: 400;
}
.hm-footer-container .row {
  width: 68.302vw;
  margin: 0 auto;
}
.hm-footer-container .col-6:nth-child(1) {
  margin-right: -4vw;
}
.hm-footer-container .col-6:nth-child(3) {
  margin-right: -3vw;
}
.hm-footer-container .hm-tip::before {
  content: "";
  display: inline-block;
  width: 0vw;
  height: 0vw;
  margin: auto;
  vertical-align: middle;
  border: 0.417vw solid transparent;
  border-left: 0.417vw solid #000000;
}
.hm-footer-container .qrcode {
  width: 5.73vw;
  margin-right: 0.521vw;
}
.hm-footer-container .qrcode img {
  width: 5.677vw;
  height: 5.677vw;
}
.hm-footer-container .qrcode-6 img {
  width: 5.677vw;
  height: 5.677vw;
}
.hm-footer-container .hm-map {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.563vw;
  color: #FFFFFF;
  width: 66.302vw;
  height: 3.385vw;
  line-height: 3.385vw;
  background: radial-gradient(0% 0% at 0% 0%, #518AFF 0%, #0057FF 100%);
  box-sizing: border-box;
  padding: 0vw 1.302vw 0vw 3.333vw;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.hm-footer-container .hm-footer-button {
  width: 7.604vw;
  height: 2.188vw;
  line-height: 2.188vw;
  background: #FFFFFF;
  border-radius: 1.094vw 1.094vw 1.094vw 1.094vw;
  font-weight: 400;
  font-size: 1.042vw;
  color: #1A64FF;
  text-align: center;
}
.hm-footer-container .hm-footer-split {
  height: 0.052vw;
  background: #D2D2D2;
  border: 0.052vw solid #D2D2D2;
  margin-top: 30px;
}
.hm-footer-container .hm-footer-endDesc {
  align-items: center;
  font-weight: 400;
  font-size: 0.833vw;
  color: #A1A1A1;
}
.hm-footer-container .hm-footer-endDesc a {
  color: #1A64FF;
}
.hm-footer-container .hm-footer-endDesc .hm-footer-endDesc-item {
  flex: 1;
  font-weight: 600;
  font-size: 1.042vw;
  color: #404040;
}
.hm-footer-container .w-75 {
  width: 73% !important;
}
.hm-footer-container .orgicon {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/hm_icon.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 8.21vw;
  height: 2.01vw;
}

.hm-footer-container-app {
  background-color: #F7F7F7;
  padding-top: 2.656vw;
  margin: 0;
}
.hm-footer-container-app h5 {
  font-weight: 700;
  font-size: 4.042vw;
  color: #000000 !important;
}
.hm-footer-container-app li {
  font-weight: 400;
  font-size: 2.833vw;
  color: #7E7E7E;
  font-weight: 400;
}
.hm-footer-container-app li .text-muted {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hm-footer-container-app .row {
  width: 100vw;
}
.hm-footer-container-app .hm-tip::before {
  content: "";
  display: inline-block;
  width: 0vw;
  height: 0vw;
  margin: auto;
  vertical-align: middle;
  border: 0.417vw solid transparent;
  border-left: 0.417vw solid #000000;
}
.hm-footer-container-app .qrcode, .hm-footer-container-app .qrcode-6 {
  font-size: 2.863vw;
}
.hm-footer-container-app .qrcode img, .hm-footer-container-app .qrcode-6 img {
  width: 14.677vw;
  height: 14.677vw;
}
.hm-footer-container-app .hm-mapapp {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 2.863vw;
  color: #FFFFFF;
  height: 10.385vw;
  line-height: 10.385vw;
  background: radial-gradient(0% 0% at 0% 0%, #518AFF 0%, #0057FF 100%);
  box-sizing: border-box;
  padding: 0vw 1.302vw 0vw 3.333vw;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.hm-footer-container-app .hm-footer-button {
  width: 15.604vw;
  height: 6vw;
  line-height: 6vw;
  background: #FFFFFF;
  border-radius: 3vw;
  font-weight: 400;
  font-size: 2.542vw;
  color: #1A64FF;
  text-align: center;
}
.hm-footer-container-app .hm-footer-split {
  border-bottom: 0.133vw solid #D2D2D2;
  margin-top: 30px;
}
.hm-footer-container-app .hm-footer-endDesc {
  align-items: center;
  font-weight: 400;
  font-size: 2.233vw;
  color: #A1A1A1;
}
.hm-footer-container-app .hm-footer-endDesc .hm-footer-endDesc-content {
  flex: 1;
  padding: 3.667vw;
}
.hm-footer-container-app .hm-footer-endDesc a {
  color: #1A64FF;
}
.hm-footer-container-app .hm-footer-endDesc .hm-footer-endDesc-item {
  width: 30vw;
  font-weight: 600;
  font-size: 3.042vw;
  color: #404040;
}
.hm-footer-container-app .orgicon {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/hm_icon.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 26.813vw;
  height: 6.5vw;
}

footer {
  background-color: #F7F7F7;
  padding-bottom: 7vw;
}

#number-animation {
  text-align: center;
}
#number-animation h1 {
  font-weight: 600;
  font-size: 2.083vw;
  color: #000000;
}
#number-animation h3 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #000000;
}
#number-animation h2 {
  display: inline;
  font-weight: 600;
  font-size: 1.875vw;
  color: #005DFF;
  margin-right: 0.156vw;
  margin-bottom: 0px;
}
#number-animation span {
  display: inline;
  vertical-align: super;
  font-weight: 600;
  font-size: 1.406vw;
  color: #005DFF;
}
#number-animation .row {
  height: 6.302vw;
  width: 46.875vw;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 2.3vw;
  margin-bottom: 3.3vw;
}
#number-animation .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}
#number-animation .col div {
  height: 7.67vw;
  display: inline;
}
#number-animation .col:not(:last-child) {
  border-right: 0.156vw solid #D2E6F5;
}
#number-animation h4 {
  font-weight: 400;
  font-size: 1.146vw;
  color: #000000;
  margin-bottom: 0px;
}

.hm-data {
  position: relative;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg1.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 31.615vw; /* 元素高度 */
  padding: 3.229vw 0vw 3.542vw 0vw;
  box-sizing: border-box;
}
.hm-data .marquee-brand {
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
.hm-data .marquee-brand #box {
  min-width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}
.hm-data .marquee-brand #box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.hm-data .marquee-brand .box-area {
  display: flex;
}
.hm-data .marquee-brand .logo-top {
  display: flex;
  position: relative;
  /*   z-index: 1; */
}
.hm-data .marquee-brand .logo-top {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: marquee 60s linear infinite;
  animation: marquee 60s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
.hm-data .marquee-brand .logo-top-one {
  display: flex;
  position: relative;
  /*   z-index: 1; */
}
.hm-data .marquee-brand .logo-top-one {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: marquee 60s linear infinite;
  animation: marquee 60s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  margin-left: 10vw;
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.hm-data .marquee-brand a {
  display: block;
  width: 12.969vw;
  overflow: hidden;
  margin: 0 20px;
}
.hm-data .marquee-brand img {
  width: 12.969vw;
  height: 5.417vw;
  overflow: hidden;
}
.hm-data .marquee-brand img {
  width: 12.969vw;
  height: 5.417vw;
  overflow: hidden;
}

.hm-data-app {
  position: relative;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/组27@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 101.467vw; /* 元素高度 */
  padding: 3.229vw 0vw 3.542vw 0vw;
  box-sizing: border-box;
}
.hm-data-app #number-animation-app h1 {
  font-weight: 700;
  font-size: 4.533vw;
  line-height: 6vw;
  color: #000000;
  margin: 8.479vw 6.667vw 0;
}
.hm-data-app #number-animation-app h1 strong {
  display: inline;
  font-size: 5.5vw;
}
.hm-data-app #number-animation-app h1:nth-child(2) {
  margin-top: 0;
}
.hm-data-app #number-animation-app h5 {
  font-weight: 400;
  font-size: 3.067vw;
  color: #000000;
  margin: 1.479vw 6.667vw 2.479vw;
}
.hm-data-app #number-animation-app h2 {
  font-weight: 700;
  font-size: 5.5vw;
  color: #005DFF;
  margin-right: 0.156vw;
  margin-bottom: 0px;
}
.hm-data-app #number-animation-app span {
  vertical-align: super;
  font-weight: 700;
  font-size: 5.5vw;
  color: #005DFF;
}
.hm-data-app #number-animation-app h4 {
  font-weight: 400;
  font-size: 3vw;
  color: #000000;
  margin-bottom: 0px;
}
.hm-data-app #number-animation-app .row {
  height: 38vw;
  width: 100vw;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 5.3vw;
}
.hm-data-app #number-animation-app .col {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.hm-data-app #number-animation-app .col div {
  height: 15.67vw;
  display: inline;
}
.hm-data-app .marquee-brand {
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
.hm-data-app .marquee-brand #box {
  min-width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}
.hm-data-app .marquee-brand #box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.hm-data-app .marquee-brand .box-area {
  display: flex;
}
.hm-data-app .marquee-brand .logo-top {
  display: flex;
  position: relative;
  /*   z-index: 1; */
}
.hm-data-app .marquee-brand .logo-top {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  animation: marquee 70s linear infinite;
  transform-origin: center center;
}
.hm-data-app .marquee-brand .logo-top-one {
  display: flex;
  position: relative;
  /*   z-index: 1; */
}
.hm-data-app .marquee-brand .logo-top-one {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  animation: marquee 70s linear infinite;
  transform-origin: center center;
  margin-left: 10vw;
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.hm-data-app .marquee-brand a {
  display: block;
  width: 32vw;
  height: 13vw;
  overflow: hidden;
  margin: 1.333vw 1.667vw;
}
.hm-data-app .marquee-brand img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#carouselExampleCaptions {
  height: 34.375vw;
  overflow-y: hidden;
  margin-top: var(--header-top);
}
#carouselExampleCaptions video {
  height: 34.375vw;
  width: 100vw;
}
#carouselExampleCaptions .carousel-caption {
  left: 16.615vw;
  bottom: 5.775vw;
  text-align: left;
}
#carouselExampleCaptions .carousel-caption h5 {
  font-weight: 600;
  font-size: 2.092vw;
  color: #ffffff;
  line-height: 2.8vw;
  margin-bottom: 3.358vw;
}
#carouselExampleCaptions .carousel-caption h5 span {
  display: inline;
  font-weight: 1000;
  font-size: 2.865vw;
  background-image: linear-gradient(to right, #ffe579, #ffc83f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  line-height: 2.8vw;
  margin-bottom: 3.358vw;
}
#carouselExampleCaptions .carousel-caption p {
  font-weight: 300;
  font-size: 0.8vw;
  color: #ffffff;
  line-height: 1.52vw;
  margin-bottom: 1.042vw;
}
#carouselExampleCaptions .carousel-inner-button {
  width: 10.313vw;
  height: 2.8021vw;
  background: #ffffff;
  border-radius: 1.51vw 1.51vw 1.51vw 1.51vw;
  text-align: center;
  line-height: 2.8021vw;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.146vw;
  color: #2171ff;
}
#carouselExampleCaptions img {
  width: 100%;
  height: 100%;
}
#carouselExampleCaptions .btn-outline-primary {
  height: 2.709vw;
  line-height: 2.709vw;
  font-size: 0.938vw;
  padding: 0 2.083vw;
}
#carouselExampleCaptions .img-fluid-app {
  height: 56.25rem;
  width: 100%;
}

#carouselExampleCaptions-app {
  height: 120vw;
  overflow-y: hidden;
  margin-top: var(--header-top);
}
#carouselExampleCaptions-app video {
  height: 120vw;
  width: 100vw;
}
#carouselExampleCaptions-app .carousel-caption {
  left: 50%;
  bottom: 5.775vw;
  transform: translateX(-50%);
}
#carouselExampleCaptions-app img {
  width: 100%;
  height: 100%;
}
#carouselExampleCaptions-app .btn-outline-primary {
  height: 7.8vw;
  line-height: 7.8vw;
  font-size: 3.333vw;
  padding: 0 4.083vw;
}

.hm-desc {
  margin: 4.479vw auto 1.667vw auto;
}
.hm-desc h1 {
  font-weight: 600;
  font-size: 2.083vw;
  color: #000000;
}
.hm-desc h5 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #000000;
}
.hm-desc .hm-desc-container {
  position: relative;
  width: 65.625vw;
  margin: 0 auto;
  margin-bottom: 4.167vw;
}
.hm-desc .hm-desc-container img:nth-child(1) {
  width: 31.25vw;
  height: 25vw;
  transition: all 1s;
}
.hm-desc .hm-desc-container img:nth-child(1):hover {
  transform: scale(1.1);
}
.hm-desc .hm-desc-container img:nth-child(2) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12.917vw;
  height: 12.031vw;
  z-index: 99;
}
.hm-desc .hm-desc-container img:nth-child(3) {
  width: 31.25vw;
  height: 25vw;
  transition: all 1s;
}
.hm-desc .hm-desc-container img:nth-child(3):hover {
  transform: scale(1.1);
}

#hm-catrgory h1 {
  font-weight: 600;
  font-size: 2.083vw;
  color: #000000;
  margin-top: 4.479vw;
}
#hm-catrgory h5 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #000000;
  margin-bottom: 2.604vw;
}
#hm-catrgory .hm-catrgory {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg2.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 100vw;
  /* 元素宽度 */
  height: 33.49vw;
  /* 元素高度 */
  padding: 2vw 0vw 2.2vw 0vw;
  box-sizing: border-box;
}
#hm-catrgory .hm-catrgory .hm-catrgory-container a {
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
#hm-catrgory .hm-catrgory .hm-catrgory-container a:hover {
  transform: scale(1.1);
}
#hm-catrgory .hm-catrgory .row {
  width: 55.792vw;
  height: 13.45vw;
  margin: 0 auto;
}
#hm-catrgory .hm-catrgory .col:not(:last-child) {
  margin-right: 0.781vw;
}
#hm-catrgory .hm-catrgory .col {
  padding: 0px;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 14.74vw;
  height: 100%;
}
#hm-catrgory .hm-catrgory .hm-category-secondDesc {
  font-weight: 300;
  font-size: 0.6vw;
  color: #989898;
  cursor: pointer;
}
#hm-catrgory .hm-catrgory .hm-category-firstDesc {
  font-weight: 400;
  font-size: 0.85vw;
  color: #000000;
}
#hm-catrgory .hm-catrgory p {
  margin: 0px;
}
#hm-catrgory .hm-catrgory img {
  margin-top: 2.1vw;
  width: 6.042vw;
  height: 6.042vw;
}

#hm-catrgory-app h1 {
  font-weight: 600;
  font-size: 4.533vw;
  line-height: 6vw;
  color: #000000;
  margin: 8.479vw 6.667vw 0;
  text-align: left;
}
#hm-catrgory-app h1:nth-child(2) {
  margin-top: 0;
}
#hm-catrgory-app h5 {
  font-weight: 400;
  font-size: 3.467vw;
  color: #000000;
  margin: 3.479vw 6.667vw;
}
#hm-catrgory-app .hm-catrgory {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg2.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 100vw;
  /* 元素宽度 */
  height: 178.667vw;
  /* 元素高度 */
  padding: 2vw 0vw 2.2vw 0vw;
  box-sizing: border-box;
}
#hm-catrgory-app .hm-catrgory .hm-catrgory-container a {
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
#hm-catrgory-app .hm-catrgory .hm-catrgory-container a:hover {
  transform: scale(1.1);
}
#hm-catrgory-app .hm-catrgory .row {
  width: 88vw;
  height: 42.45vw;
  margin: 0 auto;
}
#hm-catrgory-app .hm-catrgory .col:not(:last-child) {
  margin-right: 0.781vw;
}
#hm-catrgory-app .hm-catrgory .col {
  padding: 0px;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 30vw;
  height: 100%;
}
#hm-catrgory-app .hm-catrgory .hm-category-secondDesc {
  font-weight: 300;
  font-size: 2.4vw;
  color: #989898;
  cursor: pointer;
}
#hm-catrgory-app .hm-catrgory .hm-category-firstDesc {
  font-weight: 400;
  font-size: 3.467vw;
  color: #000000;
}
#hm-catrgory-app .hm-catrgory p {
  margin: 0px;
}
#hm-catrgory-app .hm-catrgory img {
  margin-top: 2.1vw;
  width: 24.042vw;
  height: 24.042vw;
}

.hm-contact {
  font-weight: 400;
  font-size: 0.625vw;
  color: #9C9C9C;
}
.hm-contact img {
  width: 109px;
  height: 109px;
}

#hm-step {
  padding: 3.631vw 0px 10.417vw 0px;
}
#hm-step h1 {
  font-weight: 600;
  font-size: 1.875vw;
  color: #000000;
}
#hm-step h4 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #000000;
  margin-bottom: 2.604vw;
}
#hm-step .row {
  width: 72.083vw;
  margin: 0 auto;
}
#hm-step .row div {
  position: relative;
  transform: scale(1);
  transition: all 1s;
}
#hm-step .row div:hover {
  transform: scale(1.1);
}
#hm-step .row div:nth-child(1)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "01";
  font-weight: 400;
  font-size: 4.115vw;
  color: #FAEBF2;
}
#hm-step .row div:nth-child(2)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "02";
  font-weight: 400;
  font-size: 4.115vw;
  color: #F2EBFA;
}
#hm-step .row div:nth-child(3)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "03";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBEFFA;
}
#hm-step .row div:nth-child(4)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "04";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBF4FA;
}
#hm-step .row div:nth-child(5)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "05";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBF7FA;
}
#hm-step .row div:nth-child(6)::after {
  position: absolute;
  top: 6.5vw;
  z-index: -1;
  content: "06";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBF7FA;
}
#hm-step .col {
  padding: 0px;
}
#hm-step img {
  position: relative;
  width: 7.2vw;
  height: 8.75vw;
}
#hm-step .hm-category-firstDesc {
  font-weight: 400;
  font-size: 1.302vw;
  color: #000000;
  margin-bottom: 0.26vw;
  white-space: nowrap;
}
#hm-step .hm-category-secondDesc {
  font-weight: 400;
  font-size: 0.729vw;
  color: #A2A2A2;
}
#hm-step .hm-step-footer {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/ft.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 73.542vw; /* 元素宽度 */
  height: 2.708vw; /* 元素高度 */
  border-radius: 0.5vw;
  box-sizing: border-box;
  margin: 0 auto;
}

#hm-step-app {
  padding: 3.631vw 0px 17.417vw 0px;
}
#hm-step-app h1 {
  font-weight: 700;
  font-size: 4.533vw;
  line-height: 6vw;
  color: #000000;
  margin: 8.479vw 6.667vw 0;
}
#hm-step-app h1 strong {
  display: inline;
  font-size: 5.5vw;
}
#hm-step-app h4 {
  font-weight: 400;
  font-size: 3.067vw;
  color: #000000;
  margin: 1.479vw 6.667vw 0vw;
}
#hm-step-app .row {
  width: 100vw;
  margin: 6vw auto 0;
}
#hm-step-app .row div {
  position: relative;
  transform: scale(1);
  transition: all 1s;
}
#hm-step-app .row div:hover {
  transform: scale(1.1);
}
#hm-step-app .row .col:nth-child(1)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "01";
  font-weight: 400;
  font-size: 4.115vw;
  color: #FAEBF2;
}
#hm-step-app .row .col:nth-child(2)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "02";
  font-weight: 400;
  font-size: 4.115vw;
  color: #F2EBFA;
}
#hm-step-app .row div:nth-child(3)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "03";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBEFFA;
}
#hm-step-app .row-2 {
  width: 100vw;
  margin: 6vw auto 0;
}
#hm-step-app .row-2 div {
  position: relative;
  transform: scale(1);
  transition: all 1s;
}
#hm-step-app .row-2 div:hover {
  transform: scale(1.1);
}
#hm-step-app .row-2 .col:nth-child(1)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "04";
  font-weight: 400;
  font-size: 4.115vw;
  color: #FAEBF2;
}
#hm-step-app .row-2 .col:nth-child(2)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "05";
  font-weight: 400;
  font-size: 4.115vw;
  color: #F2EBFA;
}
#hm-step-app .row-2 div:nth-child(3)::after {
  position: absolute;
  bottom: -5.9vw;
  z-index: -1;
  content: "06";
  font-weight: 400;
  font-size: 4.115vw;
  color: #EBEFFA;
}
#hm-step-app .col {
  padding: 0px;
}
#hm-step-app img {
  position: relative;
  width: 18vw;
  height: 22vw;
}
#hm-step-app .hm-category-firstDesc {
  font-weight: 400;
  font-size: 4vw;
  color: #000000;
  margin-bottom: 0.26vw;
  white-space: nowrap;
}
#hm-step-app .hm-category-secondDesc {
  font-weight: 400;
  font-size: 0.729vw;
  color: #A2A2A2;
}
#hm-step-app .hm-step-footer {
  background-image: linear-gradient(to right, #eaa3f9, #336cfc); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 96vw; /* 元素宽度 */
  height: 5vw; /* 元素高度 */
  border-radius: 2.5vw;
  box-sizing: border-box;
  margin: 0 auto;
}
#hm-step-app .hm-step-footer-2 {
  background-image: linear-gradient(to right, #3280f9, #2ad5eb); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 96vw; /* 元素宽度 */
  height: 5vw; /* 元素高度 */
  border-radius: 2.5vw;
  box-sizing: border-box;
  margin: 0 auto;
}

.hm-solution-content {
  width: 100vw;
  position: relative;
}
.hm-solution-content section {
  padding: 4.74vw 16.667vw 2.083vw 16.667vw;
}
.hm-solution-content h3 {
  font-weight: 400;
  font-size: 1.563vw;
  color: #000000;
}
.hm-solution-content p {
  font-weight: 400;
  font-size: 1.146vw;
  color: #454545;
  white-space: nowrap;
}
.hm-solution-content ul {
  font-weight: 400;
  font-size: 1.042vw;
  color: #454545;
}
.hm-solution-content .hm-solution-ul {
  margin: 2.865vw 0vw 5.208vw 0px;
  margin-right: 3.698vw;
}
.hm-solution-content .hm-solution-img {
  height: 19.155vw;
  width: 32.396vw;
  border: 1px solid #f6f6f6;
}
.hm-solution-content .hm-solution-show {
  display: inline-block;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg4.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 15.573vw; /* 元素高度 */
  margin-top: var(--header-top);
  padding: 5.573vw 0vw 0vw 16.927vw;
  box-sizing: border-box;
}
.hm-solution-content .hm-solution-show h1 {
  font-weight: 600;
  font-size: 2.604vw;
  color: #FFFFFF;
}
.hm-solution-content .hm-solution-show h3 {
  font-weight: 400;
  font-size: 1.563vw;
  color: #FFFFFF;
}
.hm-solution-content .hm-solution-feature {
  margin-bottom: 4.115vw;
}
.hm-solution-content .hm-solution-feature div {
  padding: 0px;
  box-sizing: border-box;
  padding-top: 1.458vw;
  width: 15.26vw;
  text-align: left;
  background: #F0FAFF;
  box-shadow: 0px 0px 3px #afdff7;
}
.hm-solution-content .hm-solution-feature h4 {
  font-weight: 600;
  font-size: 1.25vw;
  color: #000000;
  text-align: center;
  margin-bottom: 1.042vw;
}
.hm-solution-content .hm-solution-feature p {
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 1.302vw;
  color: #454545;
  padding-left: 1.406vw !important;
}
.hm-solution-content .hm-solution-feature img {
  padding: 0.5vw;
}
.hm-solution-content .hm-solution-frame {
  width: 100%;
  height: 34.063vw;
  padding: 1.6vw 2.667vw 0vw 2.667vw;
  border-top: 2px solid #0000B5;
  margin-bottom: 6vw;
}
.hm-solution-content .hm-solution-frame img {
  width: 100%;
}
.hm-solution-content .hm-sidebar-bar {
  position: absolute;
  top: 18.229vw;
  left: 3.125vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 36.646vw;
  cursor: pointer;
}
.hm-solution-content .hm-sidebar-bar h5 {
  height: 1.563vw;
  font-size: 0.833vw;
  color: #2376FC;
  text-align: center;
}
.hm-solution-content .hm-sidebar-bar a {
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
.hm-solution-content .hm-sidebar-bar a:hover {
  transform: scale(1.1);
}
.hm-solution-content .hm-sidebar-bar .col {
  padding-top: 1.042vw;
  margin-bottom: 0.8vw;
  width: 8.51vw;
  height: 9vw;
  background-color: #e3edf7;
  border-radius: 0.625vw;
}
.hm-solution-content .hm-sidebar-bar .hm-category-firstDesc {
  font-weight: 400;
  font-size: 0.938vw;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hm-solution-content .hm-solution-new {
  width: 58.594vw;
  margin-bottom: 5.833vw;
}
.hm-solution-content .hm-solution-new h1 {
  font-size: 1.667vw;
  font-weight: 900;
  margin-top: 4.323vw;
  margin-bottom: 1.042vw;
}
.hm-solution-content .hm-solution-new p {
  font-size: 1.042vw;
  margin-bottom: 1.198vw;
  white-space: inherit;
}

.hm-solution-content-app {
  font-family: MiSans, MiSans;
}
.hm-solution-content-app section {
  padding: 10.267vw 8.533vw;
}
.hm-solution-content-app .hm-solution-show {
  display: inline-block;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg4.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 28.933vw; /* 元素高度 */
  margin-top: var(--header-top);
  padding: 8.267vw 0vw 0vw 9.2vw;
  box-sizing: border-box;
}
.hm-solution-content-app .hm-solution-show h1 {
  font-weight: 600;
  font-size: 6.667vw;
  color: #FFFFFF;
  line-height: 8.533vw;
}
.hm-solution-content-app .hm-solution-show h3 {
  font-size: 4vw;
  color: #FFFFFF;
  line-height: 4vw;
}
.hm-solution-content-app h3 {
  font-weight: 400;
  font-size: 5.067vw;
  color: #000000;
  margin-bottom: 4.533vw;
}
.hm-solution-content-app p {
  font-size: 3.467vw;
  color: #454545;
  line-height: 4.8vw;
}
.hm-solution-content-app .hm-solution-ul {
  margin: 4.8vw 0vw 0 0vw;
  padding-left: 3.333vw;
  font-size: 3.467vw;
  color: #454545;
  line-height: 4.8vw;
}
.hm-solution-content-app .hm-solution-img {
  margin-top: 4.067vw;
  margin-bottom: 11.067vw;
}
.hm-solution-content-app .hm-solution-feature {
  margin-bottom: 4.115vw;
}
.hm-solution-content-app .hm-solution-feature div {
  box-sizing: content-box;
  padding: 3.067vw 3.467vw;
  text-align: left;
  background: #F0FAFF;
  margin-bottom: 4vw;
  box-shadow: 0px 0px 6px #ccc;
}
.hm-solution-content-app .hm-solution-feature h4 {
  font-weight: 600;
  font-size: 4vw;
  color: #000000;
  text-align: center;
  margin-bottom: 1.042vw;
}
.hm-solution-content-app .hm-solution-feature p {
  font-weight: 400;
  font-size: 3.2vw;
  line-height: 4vw;
  color: #454545;
}
.hm-solution-content-app .hm-solution-frame {
  width: 100%;
  height: 34.063vw;
  border-top: 2px solid #0000B5;
  margin-bottom: 8vw;
}
.hm-solution-content-app .hm-solution-frame img {
  width: 100%;
}
.hm-solution-content-app .hm-sidebar-bar {
  height: 100.4vw;
  width: 100vw;
  padding: 6.933vw;
  cursor: pointer;
}
.hm-solution-content-app .hm-sidebar-bar h5 {
  font-size: 4.8vw;
  color: #2376FC;
  text-align: left;
}
.hm-solution-content-app .hm-sidebar-bar a {
  background-color: #e3edf7;
  border-radius: 1.625vw;
  width: 40vw;
  height: 36.5vw;
  padding: 2.5vw;
  margin: 1.5vw;
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
.hm-solution-content-app .hm-sidebar-bar a:hover {
  transform: scale(1.1);
}
.hm-solution-content-app .hm-sidebar-bar .hm-sidebar-bar-main {
  display: flex;
  flex-wrap: wrap;
}
.hm-solution-content-app .hm-sidebar-bar .hm-category-firstDesc {
  font-weight: 400;
  font-size: 4.467vw;
  color: #000000;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hm-solution-content-app .hm-solution-new {
  margin-bottom: 5.833vw;
}
.hm-solution-content-app .hm-solution-new h1 {
  font-size: 4.533vw;
  font-weight: 900;
  margin-top: 8.323vw;
  margin-bottom: 3.042vw;
}
.hm-solution-content-app .hm-solution-new p {
  font-weight: 400;
  font-size: 3.2vw;
  color: #454545;
  line-height: 7vw;
  margin-bottom: 1.198vw;
}
.hm-solution-content-app .hm-solution-new img {
  width: 100%;
  height: 100%;
}

.hm-news-content {
  font-family: MiSans, MiSans;
  background-color: #f2f2f2;
  position: relative;
  margin-bottom: -3vw;
  margin-top: var(--header-top);
}
.hm-news-content .hm-new-bg {
  width: 100vw;
  /* 元素宽度 */
  height: 15.573vw;
  /* 元素高度 */
  background-color: #fcfcfc;
  position: relative;
}
.hm-news-content .hm-new-bg img {
  width: 100%;
}
.hm-news-content .hm-new-bg h1 {
  position: absolute;
  top: 50%;
  left: 15.625vw;
  font-size: 2.083vw;
  font-weight: 900;
  color: #fff;
}
.hm-news-content .hm-download {
  height: 10vw;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vw;
  background-color: #fff;
}
.hm-news-content .hm-download a {
  margin: 2vw;
  font-size: 0.833vw;
  color: #000000;
}
.hm-news-content .hm-download a:nth-child(2) {
  display: block;
  height: 2.083vw;
  line-height: 2.083vw;
  text-align: center;
  width: 6.25vw;
  border: 1px solid #1868c9;
  color: #1868c9;
}
.hm-news-content .hm-iframe-box {
  position: relative;
  top: -8.28vw;
  background-color: #fff;
  border-radius: 1.042vw;
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .details-title {
  margin: 0 1.042vw;
  padding: 1.042vw 0;
  display: block;
  border-bottom: 1px solid #d4d6d8;
}
.hm-news-content .hm-iframe-box .details-title .news_list_detail {
  font-size: 1.25vw;
  line-height: 1.5;
  color: #000000;
}
.hm-news-content .hm-iframe-box .details-title .news_list_info {
  font-size: 0.833vw;
  color: #b2b2b2;
  display: flex;
  align-items: center;
}
.hm-news-content .hm-iframe-box .details-title .news_list_info span {
  padding-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .details-title .news_list_info .icon-eye {
  display: inline-block;
  vertical-align: sub;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/eye.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 1vw;
  height: 0.55vw;
  margin-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-notice {
  padding: 1vw;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content .hm-iframe-box .met-editor-notice p {
  font-size: 0.885vw;
  text-indent: 2em;
}
.hm-news-content .hm-iframe-box .met-editor-notice .flon {
  font-size: 0.99vw;
}
.hm-news-content .hm-iframe-box .met-editor-notice .text-red {
  color: #ff0000;
}
.hm-news-content .hm-iframe-box .met-editor-notice .text-other {
  color: #17365d;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-notice a {
  color: #62a8ea;
  font-weight: 500;
}
.hm-news-content .hm-iframe-box .met-editor-notice .iframe-img {
  display: flex;
  justify-content: center;
}
.hm-news-content .hm-iframe-box .iframe-title {
  margin: 1.563vw;
}
.hm-news-content .hm-iframe-box .met-editor {
  padding: 1vw;
  font-size: 0.781vw;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content .hm-iframe-box .met-editor .text-bold {
  font-weight: bold;
  color: #0d54fe;
}
.hm-news-content .hm-iframe-box .met-editor .img-content img {
  width: 41.667vw;
}
.hm-news-content .hm-iframe-box .met-editor .mecdi-item {
  position: relative;
  background-color: #f6f6f6;
  padding: 0.521vw 0.781vw 0.781vw 5.208vw;
}
.hm-news-content .hm-iframe-box .met-editor .mecdi-item strong {
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor .mecdi-item .tip {
  position: absolute;
  top: 0;
  left: 1.042vw;
  width: 2em;
  height: 3vw;
  background-color: rgb(0, 82, 217);
}
.hm-news-content .hm-iframe-box .met-editor .mecdi-item .tip::after {
  position: absolute;
  bottom: -0.9em;
  content: "";
  outline: 0px;
  height: 0.5em;
  width: 2em;
  border-left: 1em solid rgb(0, 82, 217);
  border-right: 1em solid rgb(0, 82, 217);
  visibility: visible;
  border-bottom: 1em solid transparent !important;
}
.hm-news-content .hm-iframe-box .met-editor .mecdi-item .bc-line {
  background-color: rgb(0, 82, 217);
  color: #fff;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor .four-title {
  font-size: 2.6vw;
  padding: 0.5vw;
  margin-bottom: 3.084vw;
  font-weight: bold;
  position: relative;
}
.hm-news-content .hm-iframe-box .met-editor .four-title span {
  font-size: 2.3vw;
  color: #deebfb;
}
.hm-news-content .hm-iframe-box .met-editor .four-title .line {
  position: absolute;
  top: 1.25vw;
  left: 0;
  color: #256ed9;
}
.hm-news-content .hm-iframe-box .met-editor .four-title .line span:nth-child(1) {
  color: #256ed9;
  font-size: 4.125vw;
  letter-spacing: -0.261vw;
  padding-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor .line-two {
  margin-left: 4.3vw;
  margin-top: -1.6vw;
  margin-bottom: 1vw;
  color: #256ed9;
  font-size: 1.8vw;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor .four-title-second {
  color: #256ed9;
  font-size: 2vw;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor ul li {
  font-weight: bold;
  margin: 0.5vw;
}
.hm-news-content .hm-iframe-box .met-editor h3 {
  text-align: center;
  font-weight: 900;
  padding: 2vw 0 2vw;
}
.hm-news-content .hm-iframe-box .met-editor .item-line {
  font-weight: 600;
}
.hm-news-content .hm-iframe-box .met-editor .text-d {
  text-indent: 2em;
}
.hm-news-content .hm-iframe-box .met-editor .mb-1 {
  margin-bottom: 1vw !important;
}
.hm-news-content .hm-iframe-box .met-editor .mb-4 {
  margin-bottom: 5vw !important;
}
.hm-news-content .hm-iframe-box .met-editor .text-underline {
  text-decoration: underline;
  font-weight: 600;
}
.hm-news-content .hm-iframe-box .met-editor a {
  color: #62a8ea;
  font-weight: 500;
}
.hm-news-content .hm-iframe-box .met-editor table thead tr th {
  height: 5vw;
  line-height: 2.5vw;
}
.hm-news-content .hm-iframe-box .met-editor table tbody tr td {
  padding: 1vw;
}
.hm-news-content .hm-iframe-box .met-editor p {
  margin-bottom: 0.8em;
}
.hm-news-content .hm-iframe-box .met-editor p img {
  width: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-four {
  padding: 1vw 5vw;
  font-size: 0.833vw;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content .hm-iframe-box .met-editor-four .text-bold {
  font-weight: bold;
  color: #0d54fe;
}
.hm-news-content .hm-iframe-box .met-editor-four .img-content img {
  width: 41.667vw;
}
.hm-news-content .hm-iframe-box .met-editor-four .mecdi-item {
  position: relative;
  background-color: #f6f6f6;
  padding: 0.521vw 0.781vw 0.781vw 100px;
}
.hm-news-content .hm-iframe-box .met-editor-four .mecdi-item strong {
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-four .mecdi-item .tip {
  position: absolute;
  top: 0;
  left: 1.042vw;
  width: 2em;
  height: 3vw;
  background-color: rgb(0, 82, 217);
}
.hm-news-content .hm-iframe-box .met-editor-four .mecdi-item .tip::after {
  position: absolute;
  bottom: -0.9em;
  content: "";
  outline: 0px;
  height: 0.5em;
  width: 2em;
  border-left: 1em solid rgb(0, 82, 217);
  border-right: 1em solid rgb(0, 82, 217);
  visibility: visible;
  border-bottom: 1em solid transparent !important;
}
.hm-news-content .hm-iframe-box .met-editor-four .mecdi-item .bc-line {
  background-color: rgb(0, 82, 217);
  color: #fff;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-four .four-title {
  font-size: 2.6vw;
  padding: 0.5vw;
  margin-bottom: 3.084vw;
  font-weight: bold;
  position: relative;
}
.hm-news-content .hm-iframe-box .met-editor-four .four-title span {
  font-size: 2.3vw;
  color: #deebfb;
}
.hm-news-content .hm-iframe-box .met-editor-four .four-title .line {
  position: absolute;
  top: 1.25vw;
  left: 0;
  color: #256ed9;
}
.hm-news-content .hm-iframe-box .met-editor-four .four-title .line span:nth-child(1) {
  color: #256ed9;
  font-size: 4.125vw;
  letter-spacing: -0.261vw;
  padding-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-four .line-two {
  margin-left: 4.3vw;
  margin-top: -1.6vw;
  margin-bottom: 1vw;
  color: #256ed9;
  font-size: 1.8vw;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-four .four-title-second {
  color: #256ed9;
  font-size: 2vw;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-four ul {
  margin: 2vw 0;
}
.hm-news-content .hm-iframe-box .met-editor-four ul li {
  font-weight: bold;
  margin: 0.5vw;
}
.hm-news-content .hm-iframe-box .met-editor-four .title-six {
  margin: 0.521vw auto;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-four .title-six .top-tip {
  font-size: 1.042vw;
  letter-spacing: 0.078vw;
  color: rgb(175, 199, 117);
  text-align: center;
}
.hm-news-content .hm-iframe-box .met-editor-four .title-six .serial {
  font-size: 0.833vw;
  color: rgb(255, 255, 255);
  width: 1.302vw;
  height: 1.302vw;
  background-color: rgb(11, 121, 255);
  border-radius: 100%;
  text-align: center;
  line-height: 1.302vw;
}
.hm-news-content .hm-iframe-box .met-editor-four .title-six .title-content {
  font-size: 1.25vw;
  color: rgb(11, 121, 255);
  text-align: left;
  padding-right: 0.26vw;
}
.hm-news-content .hm-iframe-box .met-editor-four .title-six .title-line {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgb(203, 227, 162);
}
.hm-news-content .hm-iframe-box .met-editor-introd {
  padding: 1vw 5vw;
  font-size: 0.833vw;
  font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
}
.hm-news-content .hm-iframe-box .met-editor-introd .text-blur {
  color: #0d54fe;
}
.hm-news-content .hm-iframe-box .met-editor-introd .text-red {
  color: #d7281d;
}
.hm-news-content .hm-iframe-box .met-editor-introd .img-content img {
  width: 60%;
}
.hm-news-content .hm-iframe-box .met-editor-introd .introd-desc {
  text-indent: 3em;
  font-weight: 500;
}
.hm-news-content .hm-iframe-box .met-editor-introd .introd-two {
  margin-left: 3em;
  font-weight: 500;
}
.hm-news-content .hm-iframe-box .met-editor-introd .introd-h {
  margin: 4.5vw 0 1vw 0;
  font-size: 2.6vw;
  font-weight: 900;
}
.hm-news-content .hm-iframe-box .met-editor-introd .introd-htwo {
  margin: 2.083vw 2vw;
}
.hm-news-content .hm-iframe-box .met-editor-introd .introd-htwo h2 {
  font-size: 2vw;
  font-weight: 900;
}
.hm-news-content .hm-iframe-box .met-editor-year {
  padding: 1vw 5vw;
  font-size: 0.833vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main {
  display: flex;
  flex-direction: column;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .text-blur {
  color: #0d54fe;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .top-item {
  background-image: linear-gradient(to right bottom, #c42714, #da731f);
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffee9e;
  margin-top: -2.604vw;
  margin-bottom: 2.604vw;
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc h2 {
  font-weight: 900;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc p {
  font-size: 0.729vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content {
  margin-top: -0.521vw;
  border: 1px solid #0086db;
  padding: 0 0.781vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .img-content {
  margin: 0 1.563vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .img-md {
  width: 80%;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .mt-5 {
  margin-top: 2vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .p-tip {
  text-align: center;
  font-size: 0.625vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .year-p-desc {
  line-height: 2vw;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .year-p-desc p {
  text-indent: 2em;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .year-title {
  margin: 1.563vw;
  text-align: center;
}
.hm-news-content .hm-iframe-box .met-editor-year .year-main .year-content .year-title span {
  font-size: 1.198vw;
  font-weight: bold;
  padding: 0.2vw 0.521vw;
  color: #fff;
  background-color: #fcb42b;
}
.hm-news-content .hm-iframe-box .met-editor-look {
  padding: 1vw 5vw;
  font-size: 0.833vw;
  color: #000;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content .hm-iframe-box .met-editor-look .text-bold {
  font-weight: bold;
  color: #0d54fe;
}
.hm-news-content .hm-iframe-box .met-editor-look .img-content img {
  width: 41.667vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .mt {
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .mt p {
  text-indent: 2em;
}
.hm-news-content .hm-iframe-box .met-editor-look .mecdi-item-look {
  margin: 2vw;
  padding: 1.5em;
  outline: 0px;
  height: auto;
  overflow: hidden;
  box-shadow: rgb(102, 102, 102) 0px 0px 10px;
  visibility: visible;
  position: relative;
  background-color: #fff;
}
.hm-news-content .hm-iframe-box .met-editor-look .mecdi-item-look .img-content img {
  width: 41.667vw;
  margin: 2.083vw 0;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title {
  margin: 3vw 0 2vw;
  display: flex;
  justify-content: center;
  text-align: center;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div {
  height: 2.083vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div:nth-child(1) {
  width: 0.521vw;
  background-color: rgb(56, 89, 254);
  transform: skewX(-20deg);
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div:nth-child(2) {
  width: 0vw;
  border-top: 2.083vw solid transparent;
  border-right: 0.729vw solid rgb(56, 89, 254);
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div:nth-child(3) {
  font-size: 1.042vw;
  font-weight: bold;
  color: #fff;
  padding: 0.313vw 1.042vw;
  letter-spacing: 0.078vw;
  background-image: -webkit-linear-gradient(left, rgb(56, 89, 254), rgb(118, 140, 249));
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div:nth-child(4) {
  width: 0vw;
  border-bottom: 2.083vw solid transparent;
  border-left: 0.729vw solid rgb(118, 140, 249);
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .met-editor-look .look-title div:nth-child(5) {
  width: 0.521vw;
  background-color: rgb(56, 89, 254);
  transform: skewX(-20deg);
  overflow: hidden;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line {
  border: 0.052vw solid #67b8e8;
  border-radius: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content {
  border-radius: 0.521vw;
  background-color: #f5faff;
  padding: 2.083vw;
  margin: 0.217vw;
  font-size: 0.729vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .one {
  display: flex;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(1) {
  height: 1.823vw;
  width: 1.823vw;
  line-height: 1.823vw;
  margin-right: 0.521vw;
  text-align: center;
  border-radius: 1.042vw;
  font-size: 1.042vw;
  font-weight: 900;
  background-color: #1a9cf0;
  color: #fff;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(2) {
  position: relative;
  height: 1.823vw;
  line-height: 1.823vw;
  text-align: center;
  padding: 0 1.042vw;
  border-radius: 1.042vw;
  background-color: #ff9b0b;
  color: #fff;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(2)::after {
  position: absolute;
  bottom: -0.521vw;
  left: 1.042vw;
  content: "";
  width: 0px;
  height: 0.052vw;
  border-top: 0.729vw solid rgb(255, 155, 11);
  border-right: 0.417vw solid transparent;
  margin-top: -0.208vw;
  margin-left: 1.042vw;
  transform: rotate(30deg);
  visibility: visible;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-left {
  margin: 0.521vw 0;
  display: flex;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-left div {
  position: relative;
  text-align: right;
  background-color: #92d050;
  color: #fff;
  font-size: 0.729vw;
  padding: 1vw 2vw;
  border-radius: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-left div::after {
  content: "";
  position: absolute;
  left: -0.521vw;
  top: 1.1vw;
  width: 0px;
  height: 1px;
  border-top: 0.521vw solid transparent;
  border-right: 0.521vw solid rgb(146, 208, 80);
  border-bottom: 0.521vw solid transparent;
  overflow: hidden;
  visibility: visible;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-right {
  margin: 0.521vw 0;
  display: flex;
  justify-content: end;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-right div {
  position: relative;
  text-align: right;
  background-color: #92d050;
  color: #fff;
  font-size: 0.729vw;
  padding: 1vw 2vw;
  border-radius: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .chat-line .content .tow-right div::after {
  content: "";
  position: absolute;
  right: -0.521vw;
  top: 1.1vw;
  width: 0vw;
  height: 0.052vw;
  border-top: 0.521vw solid transparent;
  border-left: 0.521vw solid rgb(146, 208, 80);
  border-bottom: 0.521vw solid transparent;
  overflow: hidden;
  visibility: visible;
}
.hm-news-content .hm-iframe-box .met-editor-look li p {
  color: #a5a5a5;
  text-indent: 1em;
  margin-bottom: 0.5vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .client-desc .head {
  height: 3vw;
  border-radius: 1.5vw;
  border: 0.8vw solid #1aa6ff;
  background-color: #0086db;
}
.hm-news-content .hm-iframe-box .met-editor-look .client-desc .desc-content {
  margin-top: -0.521vw;
  margin-left: 1.042vw;
  margin-right: 1.042vw;
  background-color: #fafafa;
  list-style: none;
  padding: 1vw 2vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .client-desc .desc-content li {
  border-bottom: 1px dashed #b6b6b6;
  padding-top: 0.781vw;
  padding-bottom: 0.781vw;
  font-size: 0.729vw;
  font-weight: bold;
}
.hm-news-content .hm-iframe-box .met-editor-look .client-desc .desc-content li span {
  border: 1px solid #4ab8fe;
  height: 1.563vw;
  line-height: 1.563vw;
  border-radius: 0.781vw;
  color: #4ab8fe;
  font-weight: bold;
  padding: 0.156vw;
  margin-right: 1.042vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main img {
  width: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content {
  background-color: #f97c45;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-ms {
  text-align: center;
  width: 40%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk {
  background-color: #fff;
  margin: 1vw 1.5vw;
  border-radius: 0.433vw;
  padding: 0.8vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk p {
  text-indent: 2em;
  margin-top: 0.5vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk .img-md {
  text-align: center;
  width: 80%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title {
  margin-top: 3vw;
  margin-bottom: 1vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip::before {
  content: "";
  display: inline-block;
  width: 4.688vw;
  height: 0.052vw;
  background: linear-gradient(to right, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip::after {
  content: "";
  display: inline-block;
  width: 4.688vw;
  height: 0.052vw;
  background: linear-gradient(to left, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip img {
  width: 1.042vw;
  height: 1.042vw;
  margin: 0 0.26vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .second-title {
  font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  padding: 0vw 2.042vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 1vw;
  background-color: #fee8bb;
  color: #ea423a;
  font-size: 0.833vw;
  font-weight: 600;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other {
  display: flex;
  justify-content: center;
  margin: 0.521vw 0;
  height: 18.229vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div {
  padding: 0.157vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) {
  width: 35%;
  margin-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) img {
  border: 1px solid #a00;
  padding: 0.156vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) p {
  text-align: center;
  color: #fb8e5a;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(2) {
  border: 1px solid #a00;
  height: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(2) img {
  height: 100%;
  width: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two {
  display: flex;
  justify-content: center;
  margin: 0.521vw 0;
  height: 18.229vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div {
  padding: 0.157vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) {
  width: 35%;
  margin-right: 0.521vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) img {
  border: 1px solid #a00;
  padding: 0.156vw;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) p {
  text-align: center;
  color: #fb8e5a;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(1) {
  border: 1px solid #a00;
  height: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(1) img {
  height: 100%;
  width: 100%;
}
.hm-news-content .hm-iframe-box .met-editor-look .forward-main .footer-text {
  padding: 3vw 0;
  background-color: #b7001a;
  font-size: 1.042vw;
  color: #f5d7a6;
}
.hm-news-content .hm-new-form {
  position: absolute;
  right: 6.567vw;
  top: 7.292vw;
  width: 15.021vw;
  display: none;
}
.hm-news-content .hm-new-form .input-group {
  margin-bottom: 0.521vw;
  position: relative;
}
.hm-news-content .hm-new-form .input-group .form-control {
  height: 1.823vw;
  border-radius: 0.417vw;
  padding-left: 1.302vw;
}
.hm-news-content .hm-new-form .input-group .form-control::placeholder {
  color: #ccc;
  letter-spacing: 0.104vw;
  font-size: 0.885vw;
}
.hm-news-content .hm-new-form .input-group .input-group-prepend {
  position: absolute;
  top: 50%;
  left: 0.26vw;
  width: 0.881vw;
  height: 0.881vw;
  z-index: 9;
  line-height: 1.823vw;
  transform: translate(0, -52%);
}
.hm-news-content .hm-new-form .hm-new-tag {
  height: 6.771vw;
  width: 100%;
  border-radius: 0.417vw;
  background-color: #fff;
  box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
  padding: 0.417vw;
  margin-bottom: 1.042vw;
}
.hm-news-content .hm-new-form .hm-new-tag .hm-new-tag-title {
  font-size: 0.838vw;
  color: #000;
  font-weight: 400;
}
.hm-news-content .hm-new-form .hm-new-tag .hm-new-tag-content {
  display: flex;
}
.hm-news-content .hm-new-form .hm-new-tag .hm-new-tag-content .tag {
  height: 1.042vw;
  line-height: 1.042vw;
  padding: 0 0.521vw;
  font-size: 0.625vw;
  min-width: 3.083vw;
  background-color: #c6dbff;
  margin: 0.26vw;
  color: #2b79ff;
  text-align: center;
}
.hm-news-content .hm-new-form .hm-new-section {
  height: 15.771vw;
  width: 100%;
  border-radius: 0.417vw;
  background-color: #fff;
  box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
  padding: 0.417vw;
}
.hm-news-content .hm-new-form .hm-new-section .hm-new-section-title {
  font-size: 0.838vw;
  color: #000;
  font-weight: 400;
}
.hm-news-content .hm-new-form .hm-new-section .hm-new-section-content {
  display: flex;
}
.hm-news-content .hm-new-form .hm-new-section .hm-new-section-content .tag {
  height: 1.042vw;
  line-height: 1.042vw;
  padding: 0 0.521vw;
  font-size: 0.625vw;
  min-width: 3.083vw;
  background-color: #c6dbff;
  margin: 0.26vw;
  color: #2b79ff;
  text-align: center;
}

.hm-news-content-app {
  font-family: MiSans, MiSans;
  margin-top: var(--header-top);
  background-color: #c6dbff;
}
.hm-news-content-app .hm-new-bg {
  width: 100vw;
  /* 元素宽度 */
  height: 38.733vw;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/图层 7.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
}
.hm-news-content-app .hm-download {
  height: 20vw;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vw;
  background-color: #fff;
}
.hm-news-content-app .hm-download a {
  margin: 2vw;
  font-size: 3.125vw;
  color: #000000;
}
.hm-news-content-app .hm-download a:nth-child(2) {
  display: block;
  height: 6.083vw;
  line-height: 6.083vw;
  text-align: center;
  width: 16.25vw;
  border: 1px solid #1868c9;
  color: #1868c9;
}
.hm-news-content-app .hm-iframe-box {
  position: relative;
  top: -15vw;
  background-color: #fff;
  border-radius: 1.042vw;
  overflow: hidden;
  margin: 0 6.667vw;
}
.hm-news-content-app .hm-iframe-box .details-title {
  display: block;
  padding: 1.042vw;
}
.hm-news-content-app .hm-iframe-box .details-title .news_list_detail {
  font-size: 4.133vw;
  line-height: 5.333vw;
  color: #000000;
  padding: 4.267vw 4.533vw 0;
}
.hm-news-content-app .hm-iframe-box .details-title .news_list_info {
  padding: 0 4.533vw;
  font-size: 2.267vw;
  color: #b1b1b1;
  display: flex;
  align-items: center;
}
.hm-news-content-app .hm-iframe-box .details-title .news_list_info span {
  padding-right: 1.333vw;
}
.hm-news-content-app .hm-iframe-box .details-title .news_list_info .icon-eye {
  display: inline-block;
  vertical-align: sub;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/eye.png");
  /* 设置图片路径 */
  background-size: cover;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中 */
  width: 2.5vw;
  height: 1.5vw;
  margin-right: 1.333vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-notice {
  padding: 1vw;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content-app .hm-iframe-box .met-editor-notice p {
  font-size: 4vw;
  text-indent: 2em;
}
.hm-news-content-app .hm-iframe-box .met-editor-notice .text-red {
  color: #ff0000;
}
.hm-news-content-app .hm-iframe-box .met-editor-notice a {
  color: #62a8ea;
  font-weight: 500;
}
.hm-news-content-app .hm-iframe-box .met-editor-notice .iframe-img {
  display: flex;
  justify-content: center;
}
.hm-news-content-app .hm-iframe-box .iframe-title {
  margin: 1.563vw;
}
.hm-news-content-app .hm-iframe-box .met-editor {
  padding: 1vw;
  font-size: 2.667vw;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content-app .hm-iframe-box .met-editor h3 {
  text-align: center;
  font-weight: 900;
  padding: 5vw 0 5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor .item-line {
  font-weight: 600;
}
.hm-news-content-app .hm-iframe-box .met-editor .text-d {
  text-indent: 2em;
}
.hm-news-content-app .hm-iframe-box .met-editor .mb-1 {
  margin-bottom: 4vw !important;
}
.hm-news-content-app .hm-iframe-box .met-editor .mb-4 {
  margin-bottom: 10vw !important;
}
.hm-news-content-app .hm-iframe-box .met-editor .text-underline {
  text-decoration: underline;
  font-weight: 600;
}
.hm-news-content-app .hm-iframe-box .met-editor a {
  color: #62a8ea;
  font-weight: 500;
}
.hm-news-content-app .hm-iframe-box .met-editor table thead tr th {
  height: 5vw;
  line-height: 2.5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor table tbody tr td {
  padding: 1vw;
}
.hm-news-content-app .hm-iframe-box .met-editor p {
  margin-bottom: 0.8em;
}
.hm-news-content-app .hm-iframe-box .met-editor p img {
  width: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-four {
  padding: 20px;
  font-size: 15.994px;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .text-bold {
  font-weight: bold;
  color: #0d54fe;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .img-content img {
  width: 800.006px;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .mecdi-item {
  position: relative;
  background-color: #f6f6f6;
  padding: 15.333vw 5.334vw 14.995px 14.995px;
  line-height: 7vw;
  font-size: 3.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .mecdi-item strong {
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .mecdi-item .tip {
  position: absolute;
  top: 0;
  left: 2.667vw;
  width: 1.8em;
  height: 10.8vw;
  background-color: rgb(0, 82, 217);
}
.hm-news-content-app .hm-iframe-box .met-editor-four .mecdi-item .tip::after {
  position: absolute;
  bottom: -0.98em;
  content: "";
  outline: 0px;
  height: 0.5em;
  width: 1.8em;
  border-left: 0.9em solid rgb(0, 82, 217);
  border-right: 0.9em solid rgb(0, 82, 217);
  visibility: visible;
  border-bottom: 1em solid transparent !important;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .mecdi-item .bc-line {
  background-color: rgb(0, 82, 217);
  color: #fff;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .four-title {
  font-size: 6.656vw;
  padding: 0.5vw;
  margin-bottom: 7.895vw;
  font-weight: bold;
  position: relative;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .four-title span {
  font-size: 5.888vw;
  color: #deebfb;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .four-title .line {
  position: absolute;
  top: 3.2vw;
  left: 0;
  color: #256ed9;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .four-title .line span:nth-child(1) {
  color: #256ed9;
  font-size: 10.56vw;
  letter-spacing: -0.668vw;
  padding-right: 1.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .line-two {
  margin-left: 11.008vw;
  margin-top: -4.096vw;
  margin-bottom: 2.56vw;
  color: #256ed9;
  font-size: 4.608vw;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .four-title-second {
  color: #256ed9;
  font-size: 5.12vw;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-four ul {
  margin: 5.12vw 0;
}
.hm-news-content-app .hm-iframe-box .met-editor-four ul li {
  font-weight: bold;
  margin: 1.28vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .title-six {
  margin: 1.334vw auto;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .title-six .top-tip {
  font-size: 3.667vw;
  letter-spacing: 1.498px;
  color: rgb(175, 199, 117);
  text-align: center;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .title-six .serial {
  font-size: 3.133vw;
  color: rgb(255, 255, 255);
  width: 5vw;
  height: 5vw;
  background-color: rgb(11, 121, 255);
  border-radius: 100%;
  text-align: center;
  line-height: 5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .title-six .title-content {
  font-size: 5vw;
  color: rgb(11, 121, 255);
  text-align: left;
  padding-right: 0.26vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-four .title-six .title-line {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgb(203, 227, 162);
}
.hm-news-content-app .hm-iframe-box .met-editor-four .introd-desc {
  text-indent: 3em;
  font-size: 3vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd {
  padding: 2.56vw 10vw;
  font-size: 3.56vw;
  font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .text-blur {
  color: #0d54fe;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .text-red {
  color: #d7281d;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .introd-desc {
  text-indent: 3em;
  font-weight: 500;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .introd-two {
  font-weight: 500;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .introd-h {
  margin: 5.333vw 0;
  font-size: 7.68vw;
  font-weight: 900;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .introd-htwo {
  margin: 5.333vw 0;
}
.hm-news-content-app .hm-iframe-box .met-editor-introd .introd-htwo h2 {
  font-size: 5.12vw;
  font-weight: 900;
}
.hm-news-content-app .hm-iframe-box .met-editor-year {
  padding: 2.56vw 1.333vw;
  font-size: 3.5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main {
  display: flex;
  flex-direction: column;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .text-blur {
  color: #0d54fe;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .top-item {
  background-image: linear-gradient(to right bottom, #c42714, #da731f);
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffee9e;
  margin-top: -6.666vw;
  margin-bottom: 6.666vw;
  overflow: hidden;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc h2 {
  font-weight: 900;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .top-item .top-item-desc p {
  font-size: 2.3vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content {
  margin-top: -10.003px;
  border: 1px solid #0086db;
  padding: 0 1.999vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .img-content {
  margin: 0 4.001vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .img-md {
  width: 90%;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .mt-5 {
  margin-top: 3.12vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .p-tip {
  text-align: center;
  font-size: 2.5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .year-p-desc {
  line-height: 8.12vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .year-p-desc p {
  text-indent: 2em;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .year-title {
  margin: 4.001vw;
  text-align: center;
}
.hm-news-content-app .hm-iframe-box .met-editor-year .year-main .year-content .year-title span {
  font-size: 5.067vw;
  font-weight: bold;
  padding: 0.512vw 1.334vw;
  color: #fff;
  background-color: #fcb42b;
}
.hm-news-content-app .hm-iframe-box .met-editor-look {
  padding: 2.56vw 1.333vw;
  font-size: 3.5vw;
  color: #000;
  font-family: 微软雅黑, MicrosoftYaHei;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .text-bold {
  font-weight: bold;
  color: #0d54fe;
}
.hm-news-content-app .hm-iframe-box .met-editor-look p {
  text-indent: 2em;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .mt {
  margin-top: 5.12vw;
  margin-bottom: 5.12vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .mecdi-item-look {
  margin: 2.667vw;
  padding: 1.5em;
  height: auto;
  overflow: hidden;
  box-shadow: rgb(102, 102, 102) 0vw 0vw 1.333vw;
  position: relative;
  background-color: #fff;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .mecdi-item-look .img-content img {
  width: 106.667vw;
  margin: 5.333vw 0;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title {
  margin: 7.68vw 0 5.12vw;
  display: flex;
  justify-content: center;
  text-align: center;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div {
  height: 9vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div:nth-child(1) {
  width: 2vw;
  background-color: rgb(56, 89, 254);
  transform: skewX(-20deg);
  overflow: hidden;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div:nth-child(2) {
  width: 0vw;
  border-top: 9vw solid transparent;
  border-right: 3.6vw solid rgb(56, 89, 254);
  overflow: hidden;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div:nth-child(3) {
  font-size: 4.667vw;
  font-weight: bold;
  color: #fff;
  padding: 0.801vw 2.667vw;
  letter-spacing: 1.498px;
  background-image: -webkit-linear-gradient(left, rgb(56, 89, 254), rgb(118, 140, 249));
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div:nth-child(4) {
  width: 0vw;
  border-bottom: 9vw solid transparent;
  border-left: 3.6vw solid rgb(118, 140, 249);
  overflow: hidden;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .look-title div:nth-child(5) {
  width: 2vw;
  background-color: rgb(118, 140, 249);
  transform: skewX(-20deg);
  overflow: hidden;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line {
  border: 0.133vw solid #67b8e8;
  border-radius: 1.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content {
  border-radius: 1.334vw;
  background-color: #f5faff;
  padding: 5.333vw;
  margin: 0.555vw;
  font-size: 1.866vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .one {
  display: flex;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(1) {
  height: 4.667vw;
  width: 4.667vw;
  line-height: 4.667vw;
  margin-right: 1.334vw;
  text-align: center;
  border-radius: 2.667vw;
  font-size: 2.667vw;
  font-weight: 900;
  background-color: #1a9cf0;
  color: #fff;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(2) {
  position: relative;
  height: 4.667vw;
  line-height: 4.667vw;
  text-align: center;
  padding: 0 2.667vw;
  border-radius: 2.667vw;
  background-color: #ff9b0b;
  color: #fff;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .one div:nth-child(2)::after {
  position: absolute;
  bottom: -1.334vw;
  left: 2.667vw;
  content: "";
  width: 0vw;
  height: 0.133vw;
  border-top: 1.866vw solid rgb(255, 155, 11);
  border-right: 1.067vw solid transparent;
  margin-top: -0.533vw;
  margin-left: 2.667vw;
  transform: rotate(30deg);
  visibility: visible;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-left {
  margin: 4.334vw 0;
  display: flex;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-left div {
  position: relative;
  text-align: right;
  background-color: #92d050;
  color: #fff;
  font-size: 3.866vw;
  padding: 3.5vw 5.12vw;
  border-radius: 2.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-left div::after {
  content: "";
  position: absolute;
  left: -2.267vw;
  top: 4vw;
  width: 0px;
  height: 0.133vw;
  border-top: 2.667vw solid transparent;
  border-right: 2.667vw solid rgb(146, 208, 80);
  border-bottom: 2.667vw solid transparent;
  overflow: hidden;
  visibility: visible;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-right {
  margin: 4.334vw 0;
  display: flex;
  justify-content: end;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-right div {
  position: relative;
  text-align: right;
  background-color: #92d050;
  color: #fff;
  font-size: 3.866vw;
  padding: 3.5vw 5.12vw;
  border-radius: 2.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .chat-line .content .tow-right div::after {
  content: "";
  position: absolute;
  right: -2.267vw;
  top: 4vw;
  width: 0px;
  height: 0.133vw;
  border-top: 2.667vw solid transparent;
  border-left: 2.667vw solid rgb(146, 208, 80);
  border-bottom: 2.667vw solid transparent;
  overflow: hidden;
  visibility: visible;
}
.hm-news-content-app .hm-iframe-box .met-editor-look li p {
  color: #a5a5a5;
  text-indent: 1em;
  margin-bottom: 9.6px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .client-desc .head {
  height: 57.6px;
  border-radius: 28.8px;
  border: 15.36px solid #1aa6ff;
  background-color: #0086db;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .client-desc .desc-content {
  margin-top: -10.003px;
  margin-left: 20.006px;
  margin-right: 20.006px;
  background-color: #fafafa;
  list-style: none;
  padding: 19.2px 38.4px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .client-desc .desc-content li {
  border-bottom: 1px dashed #b6b6b6;
  padding-top: 14.995px;
  padding-bottom: 14.995px;
  font-size: 13.997px;
  font-weight: bold;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .client-desc .desc-content li span {
  border: 1px solid #4ab8fe;
  height: 30.01px;
  line-height: 30.01px;
  border-radius: 14.995px;
  color: #4ab8fe;
  font-weight: bold;
  padding: 2.995px;
  margin-right: 20.006px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main img {
  width: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content {
  background-color: #f97c45;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-ms {
  text-align: center;
  width: 40%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk {
  background-color: #fff;
  margin: 2.56vw 3.84vw;
  border-radius: 8.314px;
  padding: 15.36px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk p {
  text-indent: 2em;
  margin-top: 9.6px;
  font-size: 3vw;
  line-height: 6vw;
  letter-spacing: 1.5px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk .p-text {
  letter-spacing: 1.5px;
  font-size: 2.5vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .chunk .img-md {
  text-align: center;
  width: 80%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title {
  margin-top: 7.68vw;
  margin-bottom: 2.56vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip::before {
  content: "";
  display: inline-block;
  width: 12.001vw;
  height: 0.233vw;
  background: linear-gradient(to right, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip::after {
  content: "";
  display: inline-block;
  width: 12.001vw;
  height: 0.233vw;
  background: linear-gradient(to left, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .newyear-tip img {
  width: 4.667vw;
  height: 4.667vw;
  margin: 0 0.666vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .fw-title .second-title {
  font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  padding: 0vw 7vw;
  height: 9vw;
  line-height: 9vw;
  border-radius: 4.5vw;
  background-color: #fee8bb;
  color: #ea423a;
  font-size: 3.8vw;
  font-weight: 600;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other {
  display: flex;
  justify-content: center;
  margin: 1.334vw 0;
  height: 26.666vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div {
  padding: 0.402vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) {
  width: 45%;
  margin-right: 1.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) img {
  border: 1px solid #a00;
  padding: 2.995px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(1) p {
  text-align: center;
  color: #fb8e5a;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(2) {
  border: 1px solid #a00;
  height: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other div:nth-child(2) img {
  height: 100%;
  width: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two {
  display: flex;
  justify-content: center;
  margin: 1.334vw 0;
  height: 26.666vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div {
  padding: 0.402vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) {
  width: 45%;
  margin-left: 1.334vw;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) img {
  border: 1px solid #a00;
  padding: 2.995px;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(2) p {
  text-align: center;
  color: #fb8e5a;
  font-size: 2.5vw;
  margin: 0.333vw 0;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(1) {
  border: 1px solid #a00;
  height: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .forward-content .img-other-two div:nth-child(1) img {
  height: 100%;
  width: 100%;
}
.hm-news-content-app .hm-iframe-box .met-editor-look .forward-main .footer-text {
  padding: 3vw 0;
  background-color: #b7001a;
  font-size: 4.042vw;
  color: #f5d7a6;
}
.hm-news-content-app .hm-new-form {
  display: none;
  padding: 0 6.667vw;
  background-color: #f2f2f2;
  height: 170vw;
}
.hm-news-content-app .hm-new-form .input-group {
  position: relative;
}
.hm-news-content-app .hm-new-form .input-group .form-control {
  height: 15.067vw;
  border-radius: 1.6vw;
  padding-left: 12.667vw;
  font-size: 6.133vw;
  margin: 3.867vw 0;
}
.hm-news-content-app .hm-new-form .input-group .form-control::placeholder {
  color: #ccc;
  letter-spacing: 0.78px;
  font-size: 6.133vw;
}
.hm-news-content-app .hm-new-form .input-group .input-group-prepend {
  position: absolute;
  top: 50%;
  left: 3.333vw;
  width: 7.733vw;
  height: 7.733vw;
  z-index: 9;
  line-height: 7.733vw;
  transform: translate(0, -52%);
}
.hm-news-content-app .hm-new-form .hm-new-tag {
  height: 36.933vw;
  width: 100%;
  border-radius: 1.6vw;
  background-color: #fff;
  box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
  padding: 3.333vw;
  margin-bottom: 4.8vw;
}
.hm-news-content-app .hm-new-form .hm-new-tag .hm-new-tag-title {
  font-size: 5.6vw;
  color: #000;
  font-weight: 400;
}
.hm-news-content-app .hm-new-form .hm-new-tag .hm-new-tag-content {
  display: flex;
  flex-wrap: wrap;
}
.hm-news-content-app .hm-new-form .hm-new-tag .hm-new-tag-content .tag {
  padding: 0 2.667vw;
  font-size: 4.667vw;
  background-color: #c6dbff;
  margin: 2vw;
  min-width: 20vw;
  color: #2b79ff;
  text-align: center;
}
.hm-news-content-app .hm-new-form .hm-new-section {
  height: 90.667vw;
  width: 100%;
  border-radius: 1.6vw;
  background-color: #fff;
  box-shadow: 0.133vw 0.133vw 0.533vw #d6d6d6;
  padding: 3.333vw;
}
.hm-news-content-app .hm-new-form .hm-new-section .hm-new-section-title {
  font-size: 5.6vw;
  color: #000;
  font-weight: 400;
}
.hm-news-content-app .hm-new-form .hm-new-section .hm-new-section-content {
  width: 100%;
  height: 72.267vw;
  margin-bottom: 10vw;
}
.hm-news-content-app .hm-new-form .hm-new-section .hm-new-section-content img {
  height: 100%;
}

.hm-form-content .hm-form-bg {
  position: relative;
  width: 100vw; /* 元素宽度 */
  height: 38.542vw; /* 元素高度 */
}
.hm-form-content .hm-form-bg img {
  height: 100%;
}
.hm-form-content .hm-form-info {
  position: absolute;
  left: 16.719vw;
  top: 7.031vw;
}
.hm-form-content .hm-form-info h1 {
  font-weight: 600;
  font-size: 1.771vw;
  color: #000000;
  line-height: 2.604vw;
  margin-bottom: 5vw;
}
.hm-form-content .hm-form-info img {
  width: 100%;
  height: 100%;
}
.hm-form-content .detail-first {
  font-weight: 500;
  font-size: 0.938vw;
  color: #0094fc;
  margin-bottom: 0px;
}
.hm-form-content .detail-second {
  font-weight: 400;
  font-size: 0.833vw;
  color: #000000;
  margin-bottom: 0px;
}
.hm-form-content .detail-increase {
  font-weight: normal;
  font-size: 2.135vw;
  color: #000000;
}
.hm-form-content .detail-increase i {
  display: inline-block;
  width: 2.083vw; /* 元素宽度 */
  height: 2.135vw; /* 元素高度 */
}
.hm-form-content .hm-form-detail {
  width: 17vw;
}
.hm-form-content .hm-form-container {
  position: absolute;
  top: 5.698vw;
  right: 16.667vw;
  width: 25.938vw;
  height: 31.656vw;
  border-radius: 0.677vw;
  background-color: #ffffff;
  padding: 2.604vw 1.927vw 1.927vw 2.5vw;
  box-sizing: border-box;
}
.hm-form-content .hm-form-container h1 {
  font-weight: 400;
  font-size: 1.458vw;
  color: #000000;
  margin-bottom: 4.5vw;
}
.hm-form-content .hm-form-container h2 {
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
}
.hm-form-content .hm-form-container .user-box {
  position: relative;
  display: flex;
  height: 2.604vw;
  margin-bottom: 1.563vw;
}
.hm-form-content .hm-form-container input[type=checkbox] {
  position: relative;
  top: 0.08vw;
  width: 0.625vw;
  height: 0.625vw;
}
.hm-form-content .hm-form-container .user-box input,
.hm-form-content .hm-form-container select {
  width: 100%;
  height: 100%;
  padding-left: 0.938vw;
  font-size: 0.833vw;
  color: #000000;
  outline: none;
  border-radius: 0.625vw 0.625vw 0.625vw 0.625vw;
  border: 0.052vw solid #d7d7d7;
}
.hm-form-content .hm-form-container .user-box label {
  position: absolute;
  top: 0.208vw;
  left: 0.833vw;
  padding: 0.521vw 0;
  font-weight: 400;
  font-size: 0.833vw;
  color: #a7a7a7;
  pointer-events: none;
  transition: 0.5s;
}
.hm-form-content .hm-form-container .user-box input:focus ~ label,
.hm-form-content .hm-form-container .user-box input:valid ~ label {
  top: -0.521vw;
  left: 0.833vw;
  color: #0095fb;
  font-size: 0.581vw;
}
.hm-form-content .hm-form-container form a {
  position: relative;
  display: inline-block;
  padding: 0.521vw 1.042vw;
  color: #03e9f4;
  font-size: 0.833vw;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 2.083vw;
  letter-spacing: 0.208vw;
}
.hm-form-content .hm-form-telephone span {
  position: absolute;
  top: 0.678vw;
  right: 0.667vw;
  font-weight: 400;
  font-size: 0.833vw;
  color: #0095fb;
  white-space: nowrap;
  cursor: pointer;
}
.hm-form-content .hm-form-agreement {
  text-align: center;
  font-weight: 400;
  font-size: 0.625vw;
  color: #a7a7a7;
}
.hm-form-content .hm-form-select select {
  appearance: none;
}
.hm-form-content .hm-form-select select:active .hm-form-label {
  display: none;
}
.hm-form-content .hm-form-icon {
  position: absolute;
  top: 0.8vw;
  right: 0.365vw;
  width: 1.938vw;
  height: 1.265vw;
  color: #0095FB;
  cursor: pointer;
  pointer-events: none;
}
.hm-form-content .hm-form-button {
  width: 100%;
  height: 3vw;
  line-height: 3vw;
  border: none;
  border-radius: 0.625vw;
  text-align: center;
  margin-top: 1.042vw;
  background-color: #0094FC;
  font-size: 1.354vw;
  color: #fff;
}

.hm-form-content-app .hm-form-bg {
  margin-top: var(--header-top);
  width: 100vw; /* 元素宽度 */
  height: 162.933vw; /* 元素高度 */
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/login-bg@2.png");
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  display: flex;
  flex-direction: column;
}
.hm-form-content-app .hm-form-info {
  width: 100vw;
  padding: 9.333vw;
}
.hm-form-content-app .hm-form-info h1 {
  font-weight: 600;
  font-size: 6.4vw;
  color: #000000;
  line-height: 8.667vw;
  margin-bottom: 4vw;
}
.hm-form-content-app .hm-form-info img {
  width: 100%;
  height: 100%;
}
.hm-form-content-app .detail-first {
  font-weight: 300;
  font-size: 2.4vw;
  color: #000000;
  margin-bottom: 0px;
}
.hm-form-content-app .detail-second {
  font-weight: 400;
  font-size: 3.2vw;
  color: #000000;
  margin-bottom: 0px;
  line-height: 7.733vw;
}
.hm-form-content-app .detail-increase {
  font-family: Barlow, Barlow;
  font-weight: 900;
  font-size: 7.2vw;
  color: #0094FC;
  line-height: 7.2vw;
}
.hm-form-content-app .detail-increase .unit {
  font-size: 4.267vw;
}
.hm-form-content-app .detail-increase i {
  display: inline-block;
  width: 6.667vw; /* 元素宽度 */
  height: 6.8vw; /* 元素高度 */
}
.hm-form-content-app .hm-form-container {
  width: 83.467vw;
  height: 104.4vw;
  margin: 0 8.267vw 10vw;
  padding: 7.2vw 4.267vw;
  border-radius: 0.677vw;
  background-color: #ffffff;
  box-sizing: border-box;
}
.hm-form-content-app .hm-form-container h1 {
  font-weight: 400;
  font-size: 5.333vw;
  color: #000000;
  margin-bottom: 8.5vw;
}
.hm-form-content-app .hm-form-container h2 {
  font-weight: 400;
  font-size: 4.067vw;
  color: #000000;
  margin-bottom: 6.5vw;
}
.hm-form-content-app .hm-form-container .user-box {
  position: relative;
  display: flex;
  height: 9.067vw;
  margin-bottom: 3.563vw;
}
.hm-form-content-app .hm-form-container input[type=checkbox] {
  position: relative;
  top: 1vw;
  width: 3.4vw;
  height: 3.4vw;
}
.hm-form-content-app .hm-form-container .user-box input,
.hm-form-content-app .hm-form-container select {
  width: 100%;
  height: 100%;
  padding-left: 3.467vw;
  font-size: 2.933vw;
  color: #000000;
  outline: none;
  border-radius: 1.6vw;
  border: 0.133vw solid #d7d7d7;
}
.hm-form-content-app .hm-form-container .user-box label {
  position: absolute;
  top: 1.633vw;
  left: 2.667vw;
  padding: 0.521vw 0;
  font-weight: 400;
  font-size: 2.933vw;
  color: #a7a7a7;
  pointer-events: none;
  transition: 0.5s;
}
.hm-form-content-app .hm-form-container .user-box input:focus ~ label,
.hm-form-content-app .hm-form-container .user-box input:valid ~ label {
  top: -1.067vw;
  left: 0.833vw;
  color: #0095fb;
  font-size: 2.633vw;
}
.hm-form-content-app .hm-form-container form a {
  position: relative;
  display: inline-block;
  padding: 1.333vw 2.667vw;
  color: #03e9f4;
  font-size: 2.933vw;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
}
.hm-form-content-app .hm-form-telephone span {
  position: absolute;
  top: 2.333vw;
  right: 1.867vw;
  font-weight: 400;
  font-size: 2.933vw;
  color: #0095fb;
  white-space: nowrap;
  cursor: pointer;
}
.hm-form-content-app .hm-form-agreement {
  text-align: center;
  font-weight: 400;
  font-size: 3.333vw;
  color: #a7a7a7;
}
.hm-form-content-app .hm-form-select select {
  appearance: none;
  background-color: #fff;
}
.hm-form-content-app .hm-form-select select:active .hm-form-label {
  display: none;
}
.hm-form-content-app .hm-form-icon {
  position: absolute;
  top: 3vw;
  right: 3vw;
  width: 3.2vw;
  height: 3.2vw;
  color: #0095FB;
  cursor: pointer;
  pointer-events: none;
}
.hm-form-content-app .hm-form-button {
  width: 72.4vw;
  height: 10.933vw;
  line-height: 10.933vw;
  border: none;
  border-radius: 2.933vw;
  text-align: center;
  margin-top: 2.667vw;
  background-color: #0094FC;
  font-size: 5.867vw;
  color: #fff;
}

#messageiframe {
  display: none;
}

.iframe-dialog {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1000;
  min-width: 70vw;
  background-color: #fff;
  color: #f83538;
  padding: 0.521vw 1.042vw;
  border-radius: 0.26vw;
  box-shadow: 0 0.104vw 0.26vw rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}
.iframe-dialog iframe {
  width: 100%;
  height: 60vh;
}
.iframe-dialog button {
  background: none;
  border: none;
  font-size: 3em;
  padding: 0;
  text-align: right;
  cursor: pointer;
  color: #999;
  outline: none;
  resize: none;
  padding: 0;
  margin: 0;
}

.message-hmc {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1000;
  min-width: 9.375vw;
  background-color: #fff;
  color: #f83538;
  padding: 0.521vw 1.042vw;
  border-radius: 0.26vw;
  box-shadow: 0 0.104vw 0.26vw rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.message-hmc.close {
  opacity: 0;
  transform: translateY(-10px);
}

.message-hmc button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #ccc;
  outline: none;
  resize: none;
  padding: 0;
  margin: 0;
}

#messageBox {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
#messageBox .messageBox {
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 1000;
  min-width: 20.375vw;
  color: #666666;
  padding: 0.521vw 1.042vw;
  border-radius: 0.26vw;
  box-shadow: 0.3vw 0.404vw 0.46vw rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#messageBox .messageBox .messageBox-title {
  font-size: 0.885vw;
  display: flex;
  justify-content: space-between;
}
#messageBox .messageBox .messageBox-title .title-right {
  line-height: 1vw;
  font-size: 1.3vw;
  font-weight: normal;
}
#messageBox .messageBox .messageBox-content {
  display: flex;
  align-items: center;
  margin: 1.042vw 0;
  width: 100%;
  height: 3vw;
  font-size: 1.042vw;
}
#messageBox .messageBox .messageBox-content img {
  height: 100%;
}
#messageBox .messageBox .messageBox-content span {
  padding-left: 0.821vw;
}
#messageBox .messageBox .messageBox-footer button {
  width: 5.2vw;
  height: 1.7vw;
  font-size: 0.842vw;
  background-color: #3f86ff;
  color: #fff;
  border-radius: 1vw;
  border: none;
  outline: none;
}

#messageBox-app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
#messageBox-app .messageBox-app {
  position: fixed;
  top: 45%;
  left: 50%;
  z-index: 1000;
  min-width: 50.375vw;
  color: #666666;
  padding: 2vw 2.042vw;
  border-radius: 0.56vw;
  box-shadow: 0.6vw 0.804vw 0.86vw rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#messageBox-app .messageBox-app .messageBox-title {
  font-size: 3.885vw;
  display: flex;
  justify-content: space-between;
}
#messageBox-app .messageBox-app .messageBox-title .title-right-app {
  line-height: 4vw;
  font-size: 5.6vw;
  font-weight: normal;
}
#messageBox-app .messageBox-app .messageBox-content {
  display: flex;
  align-items: center;
  margin: 3.042vw 0;
  width: 100%;
  height: 7vw;
  font-size: 5.042vw;
}
#messageBox-app .messageBox-app .messageBox-content img {
  height: 100%;
}
#messageBox-app .messageBox-app .messageBox-content span {
  padding-left: 2vw;
}
#messageBox-app .messageBox-app .messageBox-footer {
  text-align: center;
}
#messageBox-app .messageBox-app .messageBox-footer button {
  width: 20vw;
  height: 5.8vw;
  font-size: 3vw;
  background-color: #3f86ff;
  color: #fff;
  border-radius: 2.9vw;
  border: none;
  outline: none;
}

.hm-introduce-content {
  position: relative;
}
.hm-introduce-content section {
  width: 100vw;
  padding: 4.635vw 16.667vw 0vw 16.667vw;
}
.hm-introduce-content .hm-introduce-bg {
  position: relative;
  width: 100vw;
  height: 15.573vw;
  margin-top: var(--header-top);
}
.hm-introduce-content .hm-introduce-bg h1 {
  position: absolute;
  top: 6.51vw;
  left: 16.771vw;
  font-weight: 600;
  font-size: 2.604vw;
  color: #FFFFFF;
}
.hm-introduce-content .hm-introduce-bg img {
  width: 100%;
}
.hm-introduce-content .hm-feature-title {
  font-weight: 400;
  font-size: 1.667vw;
  color: #000000;
  margin-top: 5.677vw;
  margin-bottom: 4.635vw;
}
.hm-introduce-content .hm-category-item-left {
  border-radius: 0.625vw;
  padding: 0.781vw;
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 3.906vw;
}
.hm-introduce-content .hm-category-item-left .category-left {
  width: 50%;
}
.hm-introduce-content .hm-category-item-left .category-left p {
  font-weight: 400;
  font-size: 0.938vw;
  color: #454545;
}
.hm-introduce-content .hm-category-item-left .category-left .hm-category-title {
  margin: 1.302vw 0;
}
.hm-introduce-content .hm-category-item-left .category-left .hm-category-title h4 {
  font-weight: 500;
  font-size: 1.25vw;
  color: #2376FC;
  margin: 0;
}
.hm-introduce-content .hm-category-item-left .category-left .hm-category-title img {
  height: 30.006px;
  width: 30.006px;
  margin: 0.26vw;
}
.hm-introduce-content .hm-category-item-left .category-right {
  flex: 1;
}
.hm-introduce-content .hm-category-item-left .category-right img {
  width: 100%;
  height: 100%;
}
.hm-introduce-content .hm-category-item-right {
  border-radius: 0.625vw;
  padding: 0.781vw;
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 3.906vw;
}
.hm-introduce-content .hm-category-item-right .category-right {
  width: 50%;
}
.hm-introduce-content .hm-category-item-right .category-right p {
  font-weight: 400;
  font-size: 0.938vw;
  color: #454545;
}
.hm-introduce-content .hm-category-item-right .category-right .hm-category-title {
  margin: 1.302vw 0;
}
.hm-introduce-content .hm-category-item-right .category-right .hm-category-title h4 {
  font-weight: 500;
  font-size: 1.25vw;
  color: #2376FC;
  margin: 0;
}
.hm-introduce-content .hm-category-item-right .category-right .hm-category-title img {
  height: 30.006px;
  width: 30.006px;
  margin: 0.26vw;
}
.hm-introduce-content .hm-category-item-right .category-left {
  flex: 1;
}
.hm-introduce-content .hm-category-item-right .category-left img {
  width: 100%;
  height: 100%;
}
.hm-introduce-content .hm-feature-left h2 {
  font-weight: 400;
  font-size: 1.667vw;
  color: #000000;
  margin-bottom: 3.021vw;
}
.hm-introduce-content .hm-feature-left p {
  font-weight: 400;
  font-size: 1.042vw;
  color: #454545;
  line-height: 1.563vw;
  margin-bottom: 2.396vw;
}
.hm-introduce-content .hm-feature-left ul {
  font-weight: 400;
  font-size: 0.885vw;
  color: #454545;
}
.hm-introduce-content .hm-feature-left li:before {
  position: relative;
  top: -0.12vw;
  content: "";
  display: inline-block;
  width: 0.365vw;
  height: 0.365vw;
  border-radius: 50%;
  background: #000000;
  margin-right: 0.521vw;
}
.hm-introduce-content .hm-feature-right {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg10.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 31.094vw; /* 元素宽度 */
  height: 15vw; /* 元素高度 */
  box-sizing: border-box;
  padding: 3.958vw 0vw 0vw 2.344vw;
}
.hm-introduce-content .hm-feature-right h2 {
  font-weight: 400;
  font-size: 1.979vw;
  color: #FFFFFF;
  margin-bottom: 1.823vw;
}
.hm-introduce-content .hm-feature-right p {
  font-weight: 400;
  font-size: 1.042vw;
  color: #FFFFFF;
}
.hm-introduce-content .hm-feature-right p:before {
  position: relative;
  top: -0.12vw;
  content: "";
  display: inline-block;
  width: 0.365vw;
  height: 0.365vw;
  border-radius: 50%;
  background: #FFFFFF;
  margin-right: 0.521vw;
}
.hm-introduce-content .hm-product-detail-img {
  margin-bottom: 3.906vw;
}
.hm-introduce-content .hm-product-detail-img img {
  width: 900px;
  display: block;
  margin: 0 auto;
}
.hm-introduce-content .hm-product-navigation {
  height: 11.979vw;
}
.hm-introduce-content .hm-product-navigation .hm-navigation-link {
  width: 18.229vw;
  height: 2.865vw;
  line-height: 2.865vw;
  border-radius: 0.781vw;
  font-size: 0.833vw;
  color: #000000;
  font-weight: 500;
  border: 0.052vw solid #BED5FF;
  text-align: center;
  cursor: pointer;
}
.hm-introduce-content .hm-sidebar-bar {
  position: absolute;
  top: 18.229vw;
  left: 3.125vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 35.646vw;
  cursor: pointer;
}
.hm-introduce-content .hm-sidebar-bar h5 {
  height: 1.563vw;
  font-size: 0.833vw;
  color: #2376FC;
  text-align: center;
}
.hm-introduce-content .hm-sidebar-bar a {
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
.hm-introduce-content .hm-sidebar-bar a:hover {
  transform: scale(1.1);
}
.hm-introduce-content .hm-sidebar-bar .col {
  padding: 0px;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 8.51vw;
  height: 8.51vw;
}
.hm-introduce-content .hm-sidebar-bar .hm-category-secondDesc {
  font-weight: 300;
  font-size: 0.6vw;
  color: #989898;
  cursor: pointer;
}
.hm-introduce-content .hm-sidebar-bar .hm-category-firstDesc {
  font-weight: 400;
  font-size: 0.746vw;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hm-introduce-content .hm-sidebar-bar p {
  margin: 0vw;
}
.hm-introduce-content .hm-sidebar-bar img {
  margin-top: 2.1vw;
  width: 3.385vw;
  height: 3.385vw;
}
.hm-introduce-content .hm-category-new {
  width: 58.594vw;
  margin-bottom: 5.833vw;
}
.hm-introduce-content .hm-category-new h1 {
  font-size: 1.667vw;
  font-weight: 900;
  margin-top: 4.323vw;
  margin-bottom: 1.042vw;
}
.hm-introduce-content .hm-category-new p {
  font-size: 1.042vw;
  margin-bottom: 1.198vw;
}

.hm-introduce-content-app {
  font-family: MiSans, MiSans;
}
.hm-introduce-content-app section {
  width: 100vw;
  padding: 7.333vw 8.267vw 0vw 8.267vw;
}
.hm-introduce-content-app .hm-introduce-bg {
  position: relative;
  width: 100vw;
  height: 40vw;
  margin-top: var(--header-top);
}
.hm-introduce-content-app .hm-introduce-bg h1 {
  font-family: MiSans, MiSans;
  position: absolute;
  top: 12.267vw;
  left: 8.133vw;
  font-weight: 600;
  font-size: 6.667vw;
  line-height: 8.533vw;
  color: #FFFFFF;
  width: 54.267vw;
  height: 14.933vw;
}
.hm-introduce-content-app .hm-introduce-bg img {
  width: 100%;
}
.hm-introduce-content-app .hm-feature-title {
  font-weight: 400;
  font-size: 4.267vw;
  color: #000000;
  text-align: left;
  margin-top: 8vw;
  margin-bottom: 8.4vw;
}
.hm-introduce-content-app .hm-category-item {
  width: 78.4vw;
  border-radius: 0.625vw;
  padding: 4vw;
  box-shadow: 0px 0px 1.067vw #ccc;
  margin-bottom: 3.906vw;
}
.hm-introduce-content-app .hm-category-item .hm-category-title {
  margin: 1.302vw 0;
}
.hm-introduce-content-app .hm-category-item .hm-category-title img {
  width: 6.979vw;
  height: 100%;
  max-width: unset;
  margin-right: 2vw;
}
.hm-introduce-content-app .hm-category-item .hm-category-title h4 {
  font-size: 4vw;
  color: #2376FC;
  line-height: 4vw;
  margin: 0;
}
.hm-introduce-content-app .hm-category-item .hm-category-item-text {
  font-size: 3.066vw;
  line-height: 5vw;
  margin: 2.133vw 0 4.133vw 0;
  font-weight: 500;
}
.hm-introduce-content-app .hm-feature-left h2 {
  font-weight: 400;
  font-size: 4.533vw;
  color: #000000;
  margin-bottom: 3.021vw;
}
.hm-introduce-content-app .hm-feature-left p {
  font-weight: 400;
  font-size: 3.2vw;
  color: #454545;
  line-height: 4.8vw;
  margin-bottom: 4.8vw;
}
.hm-introduce-content-app .hm-feature-left ul {
  font-size: 2.667vw;
  color: #454545;
  line-height: 4.8vw;
}
.hm-introduce-content-app .hm-feature-left li:before {
  position: relative;
  top: -0.12vw;
  content: "";
  display: inline-block;
  width: 0.365vw;
  height: 0.365vw;
  border-radius: 50%;
  background: #000000;
  margin-right: 0.521vw;
}
.hm-introduce-content-app .hm-feature-right {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg10@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 83.333vw; /* 元素宽度 */
  height: 40.267vw; /* 元素高度 */
  box-sizing: border-box;
  padding: 10.8vw 0vw 0vw 6.267vw;
}
.hm-introduce-content-app .hm-feature-right h2 {
  font-weight: 400;
  font-size: 5.333vw;
  color: #FFFFFF;
  line-height: 8.933vw;
}
.hm-introduce-content-app .hm-feature-right p {
  font-size: 2.8vw;
  color: #FFFFFF;
  line-height: 4.133vw;
}
.hm-introduce-content-app .hm-feature-right a {
  font-size: 2.8vw;
  color: #FFFFFF;
  line-height: 4.133vw;
  border-bottom: 1px solid #fff;
}
.hm-introduce-content-app .hm-feature-right p:before {
  content: "";
  display: inline-block;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background: #FFFFFF;
  margin-right: 1.4vw;
}
.hm-introduce-content-app .hm-product-detail-img {
  margin-bottom: 3.906vw;
}
.hm-introduce-content-app .hm-product-detail-img img {
  width: 900px;
  display: block;
  margin: 0 auto;
}
.hm-introduce-content-app .hm-product-navigation {
  margin-bottom: 7.733vw;
}
.hm-introduce-content-app .hm-product-navigation .hm-navigation-link {
  width: 33.067vw;
  height: 15.333vw;
  border-radius: 1.467vw;
  border: 0.052vw solid #BED5FF;
  text-align: center;
  cursor: pointer;
  font-size: 3.2vw;
  color: #000000;
  line-height: 4.8vw;
  text-align: left;
  padding: 3.333vw 3.467vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hm-introduce-content-app .hm-sidebar-bar {
  height: 110.4vw;
  width: 100vw;
  padding: 6.933vw;
  cursor: pointer;
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/27@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
}
.hm-introduce-content-app .hm-sidebar-bar h5 {
  font-size: 4.8vw;
  color: #2376FC;
  text-align: left;
}
.hm-introduce-content-app .hm-sidebar-bar a {
  text-decoration: none;
  transform: scale(1);
  transition: all 1s;
}
.hm-introduce-content-app .hm-sidebar-bar a:hover {
  transform: scale(1.1);
}
.hm-introduce-content-app .hm-sidebar-bar .hm-sidebar-bar-main {
  display: flex;
  flex-wrap: wrap;
}
.hm-introduce-content-app .hm-sidebar-bar a {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 42.2vw;
  height: 42.5vw;
  padding: 2.5vw;
  margin: 0.2vw;
}
.hm-introduce-content-app .hm-sidebar-bar .hm-category-secondDesc {
  font-weight: 300;
  font-size: 2.533vw;
  color: #989898;
}
.hm-introduce-content-app .hm-sidebar-bar .hm-category-firstDesc {
  font-weight: 400;
  font-size: 3.467vw;
  color: #000000;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hm-introduce-content-app .hm-sidebar-bar p {
  margin: 0.5vw;
}
.hm-introduce-content-app .hm-sidebar-bar img {
  margin-top: 2.5vw;
  width: 20vw;
  height: 20vw;
}
.hm-introduce-content-app .hm-category-new {
  margin-bottom: 5.833vw;
}
.hm-introduce-content-app .hm-category-new h1 {
  font-size: 4.533vw;
  font-weight: 900;
  margin-top: 8.323vw;
  margin-bottom: 3.042vw;
}
.hm-introduce-content-app .hm-category-new p {
  font-weight: 400;
  font-size: 3.2vw;
  color: #454545;
  line-height: 7vw;
  margin-bottom: 1.198vw;
}
.hm-introduce-content-app .hm-category-new img {
  width: 100%;
  height: 100%;
}

#hm-type h1 {
  font-weight: 600;
  font-size: 2.083vw;
  color: #000000;
  margin-bottom: 0.521vw;
  margin-top: 5.104vw;
}
#hm-type h5 {
  font-weight: 400;
  font-size: 1.042vw;
  color: #000000;
  margin-bottom: 2.604vw;
}
#hm-type .tab-pane {
  position: relative;
  width: 48.75vw;
  height: 27.24vw;
  background-color: #FFFFFF;
  border-top-left-radius: 1.458vw;
  border-top-right-radius: 1.458vw;
  border-bottom-left-radius: 1.458vw;
  border-bottom-right-radius: 1.458vw;
  box-sizing: border-box;
  padding: 2.083vw 2.083vw 0vw 1.563vw;
  margin-left: 1.042vw;
  overflow: hidden;
}
#hm-type .tab-content-index {
  height: 1.901vw;
  width: 6.484vw;
  padding-left: 0.313vw;
  line-height: 1.901vw;
  font-weight: bold;
  font-size: 1.25vw;
  color: #FFFFFF;
  border: 0.104vw solid #2F77FF;
  box-sizing: border-box;
  background-color: #2F77FF;
  margin-right: 0.521vw;
}
#hm-type .tab-content-title {
  height: 1.693vw;
  text-align: center;
  flex: 1;
  background-color: #FFFFFF;
  font-size: 1.042vw;
  color: #000000;
  margin-left: 0.417vw;
}
#hm-type .tab-content-header {
  font-weight: 500;
  font-size: 1.146vw;
  color: #000000;
  margin-bottom: 0.26vw;
}
#hm-type .tab-content-desc {
  font-size: 0.833vw;
  color: #000000;
  line-height: 1.146vw;
  margin: 0px;
}
#hm-type .tab-pane-item:not(:last-child) {
  margin-bottom: 1.875rem;
}
#hm-type .tab-pane-item {
  width: 24.854vw;
}
#hm-type .tab-pane-img {
  position: absolute;
  right: 0vw;
  top: 0vw;
  width: 21.875vw; /* 元素宽度 */
  height: 100%; /* 元素高度 */
}
#hm-type .hm-type-container {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/skyscraper.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 33.333vw; /* 元素高度 */
  padding: 2.76vw 0vw 0vw 17.448vw;
}
#hm-type .hm-type-container img {
  height: 100%;
  width: 100%;
}
#hm-type .hm-type-select .nav-link {
  position: relative;
  width: 11.979vw;
  height: 4.123vw;
  line-height: 4.323vw;
  text-align: center;
  box-shadow: 0vw 0vw 0.469vw 0vw #ccd7ef;
  border-radius: 0.26vw 0.26vw 0.26vw 0.26vw;
  margin-bottom: 0.521vw;
  font-weight: 400;
  font-size: 1.458vw;
  background-color: #f6f9fc;
  color: #000000;
  white-space: nowrap;
  padding: 0vw;
}
#hm-type .hm-type-select .nav-pills .nav-link.active {
  color: #ffffff !important;
  background-color: #2f6ff4 !important;
}
#hm-type .hm-type-select .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  top: -0.026vw;
  right: -2.083vw;
  border-top: 2.1vw solid transparent;
  border-bottom: 2.1vw solid transparent;
  border-left: 2.188vw solid #2f6ff4;
  display: block;
  width: 0;
}

#hm-type-app h1 {
  font-weight: 600;
  font-size: 4.533vw;
  line-height: 6vw;
  color: #000000;
  margin: 8.479vw 6.667vw 0;
  text-align: left;
}
#hm-type-app h1:nth-child(2) {
  margin-top: 0;
}
#hm-type-app h5 {
  font-weight: 400;
  font-size: 3.467vw;
  color: #000000;
  margin: 3.479vw 6.667vw;
}
#hm-type-app .tab-pane {
  position: relative;
  width: 86.6vw;
  height: 58vw;
  background-color: #FFFFFF;
  border-top-left-radius: 1.458vw;
  border-top-right-radius: 1.458vw;
  border-bottom-left-radius: 1.458vw;
  border-bottom-right-radius: 1.458vw;
  box-sizing: border-box;
  padding: 2.083vw 2.083vw 0vw 1.563vw;
  overflow: hidden;
}
#hm-type-app .tab-content-index {
  height: 5.1vw;
  width: 16.667vw;
  font-weight: bold;
  font-size: 3vw;
  color: #FFFFFF;
  box-sizing: border-box;
  background-color: #2F77FF;
  margin-right: 1.8vw;
  border: 0.267vw solid #2F77FF;
}
#hm-type-app .tab-content-title {
  height: 4.693vw;
  line-height: 4.693vw;
  text-align: center;
  flex: 1;
  background-color: #FFFFFF;
  font-size: 2.942vw;
  color: #191919;
  margin-left: 1vw;
  font-weight: 400;
}
#hm-type-app .tab-content-header {
  font-weight: 500;
  font-size: 3.046vw;
  color: #000000;
  font-weight: 600;
  margin-bottom: 1.833vw;
}
#hm-type-app .tab-content-title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#hm-type-app .tab-content-desc {
  font-size: 2.533vw;
  color: #000000;
  line-height: 3.546vw;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置为2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#hm-type-app .tab-pane-item:not(:last-child) {
  margin-bottom: 4vw;
}
#hm-type-app .tab-pane-item {
  width: 48.854vw;
}
#hm-type-app .tab-pane-img {
  position: absolute;
  right: 0vw;
  top: 0vw;
  width: 32.875vw; /* 元素宽度 */
  height: 100%; /* 元素高度 */
}
#hm-type-app .hm-type-container-app {
  background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/skyscraper@2.png"); /* 设置图片路径 */
  background-size: cover; /* 背景图覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图不重复 */
  background-position: center; /* 背景图居中 */
  width: 100vw; /* 元素宽度 */
  height: 87.067vw; /* 元素高度 */
  padding: 4.76vw 0vw 0vw 6.667vw;
}
#hm-type-app .hm-type-container-app img {
  height: 100%;
  width: 100%;
}
#hm-type-app .hm-type-select .hm-type-row {
  width: 90vw;
  overflow-x: auto;
}
#hm-type-app .hm-type-select .nav {
  flex-wrap: nowrap;
  align-items: center;
  width: 108vw;
  margin-bottom: 4vw;
}
#hm-type-app .hm-type-select .nav-link {
  position: relative;
  width: 20vw;
  height: 11vw;
  line-height: 11vw;
  text-align: center;
  box-shadow: 0vw 0vw 0.469vw 0vw #ccd7ef;
  border-radius: 1.067vw;
  margin-bottom: 0.521vw;
  font-weight: 400;
  font-size: 5vw;
  font-weight: 500;
  background-color: #f6f9fc;
  color: #000000;
  white-space: nowrap;
  padding: 0vw;
}
#hm-type-app .hm-type-select .nav-pills .nav-link.active {
  color: #ffffff !important;
  border: 0.4vw solid #fff;
  font-weight: 700;
  background-image: linear-gradient(to bottom, #3369e6, #2b72fd) !important;
}

.hm-sidebar-consult {
  position: fixed;
  z-index: 10;
  top: 10.417vw;
  right: 1.042vw;
  height: 22.833vw;
  width: 3.5vw;
}
.hm-sidebar-consult .sidebar-icon-module {
  height: 11.375vw;
  width: 3.5vw;
  border-radius: 2.083vw;
  background-color: #fff;
  box-shadow: 0.052vw 0.052vw 0.313vw #91B2D7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.521vw;
}
.hm-sidebar-consult .sidebar-icon-module .sidebar-icon-line {
  padding: 0.26vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 3.5vw;
}
.hm-sidebar-consult .sidebar-icon-module .sidebar-icon-line img {
  width: 1.398vw;
  height: 1.242vw;
}
.hm-sidebar-consult .sidebar-icon-module .sidebar-icon-line span {
  padding-top: 0.26vw;
  font-size: 0.621vw;
  color: #000;
  border-bottom: 0.052vw solid #e7e5e5;
}
.hm-sidebar-consult .sidebar-icon-module .sidebar-icon-line:last-child span {
  border-bottom: none;
}
.hm-sidebar-consult .back-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 52%;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0.052vw 0.052vw 0.313vw #91B2D7;
}
.hm-sidebar-consult .back-top img {
  width: 0.725vw;
  height: 0.881vw;
  margin-bottom: 0.321vw;
}
.hm-sidebar-consult .back-lingt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 0.521vw;
}
.hm-sidebar-consult .back-lingt .back-lingt-btn {
  height: 3.5vw;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  border: none;
  position: relative;
}
.hm-sidebar-consult .back-lingt .back-lingt-btn .back-movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(67, 104, 214, 0.5);
  z-index: -11;
  animation: movie 2s infinite;
}
.hm-sidebar-consult .back-lingt span {
  display: block;
  height: 1.042vw;
  width: 100%;
  line-height: 1.042vw;
  background-color: #005dff;
  color: #fff;
  text-align: center;
  border-radius: 1.042vw;
}
.hm-sidebar-consult span {
  font-size: 0.621vw;
}

.hm-sidebar-consult-app {
  position: fixed;
  z-index: 1100;
  top: 40.001vw;
  right: 2.667vw;
  height: 88.453vw;
  width: 15vw;
}
.hm-sidebar-consult-app .sidebar-icon-module {
  height: 49.12vw;
  width: 15vw;
  border-radius: 7.5vw;
  background-color: #fff;
  box-shadow: 0.133vw 0.133vw 0.801vw #91B2D7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.334vw;
}
.hm-sidebar-consult-app .sidebar-icon-module .sidebar-icon-line {
  padding: 0.666vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15vw;
}
.hm-sidebar-consult-app .sidebar-icon-module .sidebar-icon-line img {
  width: 6vw;
  height: 6vw;
}
.hm-sidebar-consult-app .sidebar-icon-module .sidebar-icon-line span {
  padding-top: 0.666vw;
  font-size: 2.667vw;
  color: #000;
  border-bottom: 0.133vw solid #e7e5e5;
}
.hm-sidebar-consult-app .sidebar-icon-module .sidebar-icon-line:last-child span {
  border-bottom: none;
}
.hm-sidebar-consult-app .back-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15vw;
  height: 15vw;
  border-radius: 52%;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0.133vw 0.133vw 0.801vw #91B2D7;
}
.hm-sidebar-consult-app .back-top img {
  width: 4vw;
  height: 6vw;
  margin-bottom: 0.822vw;
}
.hm-sidebar-consult-app .back-lingt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 3.334vw;
}
.hm-sidebar-consult-app .back-lingt .back-lingt-btn {
  height: 15vw;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  border: none;
  position: relative;
}
.hm-sidebar-consult-app .back-lingt .back-lingt-btn .back-movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(67, 104, 214, 0.5);
  z-index: -11;
  animation: movie 2s infinite;
}
.hm-sidebar-consult-app .back-lingt span {
  display: block;
  height: 5vw;
  width: 100%;
  line-height: 5vw;
  background-color: #005dff;
  color: #fff;
  text-align: center;
  border-radius: 2.5vw;
}
.hm-sidebar-consult-app span {
  font-size: 2.667vw;
}

@keyframes movie {
  0%, 100% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
}
body {
  overflow-x: hidden;
  font-size: 15px;
  font-family: MiSans, MiSans;
}

img {
  max-width: 100%;
}

.tofront {
  position: relative;
  z-index: 1;
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

a {
  color: #7832e2;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.c-pointer:hover {
  cursor: pointer;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

@media (max-width: 991.98px) {
  .display-3 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .display-3 {
    font-size: 2rem;
  }
}

.row.gap-y > .col, .row.gap-y > [class*=col-] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.mt-neg5 {
  margin-top: -5rem;
}

.ml-neg5 {
  margin-left: -5rem;
}

@media (min-width: 768px) {
  .h-md-100-v {
    height: 100vh;
  }
  .h-md-100 {
    height: 100vh;
  }
}
@media (min-width: 1200px) and (max-width: 1919.98px) {
  .h-xl-300 {
    height: 300px;
  }
  .h-max-380 {
    max-height: 380px;
  }
}
@media (min-width: 1920px) {
  .h-xl-560 {
    max-height: 560px;
  }
}
.btn-round {
  border-radius: 30px !important;
}

.btn {
  padding: 0.45rem 1.2rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  position: relative;
}
.btn:hover, .btn:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  outline: 0 !important;
}
.btn .badge {
  position: absolute;
  top: -0.625rem;
  right: -0.3125rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.65rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-link:hover, .btn-link:focus {
  box-shadow: none;
}

.btn-link.btn-primary {
  color: #005dff;
  border-color: transparent;
}

.btn-link.btn-secondary {
  color: #7832e2;
  border-color: transparent;
}

.btn-link.btn-success {
  color: #44c553;
  border-color: transparent;
}

.btn-link.btn-info {
  color: #09ebaf;
  border-color: transparent;
}

.btn-link.btn-warning {
  color: #ffde03;
  border-color: transparent;
}

.btn-link.btn-danger {
  color: #ff5916;
  border-color: transparent;
}

.btn-link.btn-light {
  color: #f8f9fa;
  border-color: transparent;
}

.btn-link.btn-dark {
  color: #343a40;
  border-color: transparent;
}

.btn-link.btn-white {
  color: #fff;
  border-color: transparent;
}

.btn-link.btn-purple {
  color: #ad6edd;
  border-color: transparent;
}

.btn-link.btn-salmon {
  color: #ff977a;
  border-color: transparent;
}

.btn-link.btn-cyan {
  color: #35bdff;
  border-color: transparent;
}

.btn-link.btn-gray {
  color: #ced4da;
  border-color: transparent;
}

.btn-link.btn-indigo {
  color: #005dff;
  border-color: transparent;
}

.btn-link.btn-orange {
  color: #fbb500;
  border-color: transparent;
}

.btn-white {
  background-color: #fff;
}

.input-round {
  border-radius: 30px !important;
}

.input-group.input-round input:first-child {
  border-radius: 30px 0 0 30px;
}
.input-group.input-round input:last-child {
  border-radius: 0px 30px 30px 0px;
}

.navbar {
  font-weight: 400;
}

.navbar-brand {
  margin-right: 2rem;
  font-size: 1.25rem;
}

.dropdown-item {
  font-weight: 300;
}

.dropdown-menu {
  border: 0;
  text-transform: none;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-brand {
  color: black;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #005dff;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #005dff;
}
.navbar-light .navbar-toggler {
  color: black;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.jumbotron {
  background-size: cover;
  padding: 7rem 1rem;
}

@media (min-width: 992px) {
  .jumbotron-lg-withnav {
    padding-bottom: calc(10rem - 70px);
  }
  .jumbotron-lg {
    padding: 10rem 0;
  }
  .jumbotron-xl {
    padding: 15rem 0;
  }
  .jumbotron-xl {
    min-height: 100vh;
  }
  .bottom-align-text-absolute {
    position: absolute;
    bottom: 30px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
.bg-black {
  background-color: #000;
}

.overlay {
  position: relative;
}
.overlay .container {
  position: relative;
}
.overlay:before {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.overlay-black:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-blue:before {
  background-color: rgba(23, 29, 90, 0.5);
}

.overlay-red:before {
  background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6));
}

.overlay-blue:before {
  background-color: rgba(23, 29, 90, 0.5);
}

.bg-primary {
  fill: #005dff !important;
}

.bg-secondary {
  fill: #7832e2 !important;
}

.bg-success {
  fill: #44c553 !important;
}

.bg-info {
  fill: #09ebaf !important;
}

.bg-warning {
  fill: #ffde03 !important;
}

.bg-danger {
  fill: #ff5916 !important;
}

.bg-light {
  fill: #f8f9fa !important;
}

.bg-dark {
  fill: #343a40 !important;
}

.bg-white {
  fill: #fff !important;
}

.bg-purple {
  fill: #ad6edd !important;
}

.bg-salmon {
  fill: #ff977a !important;
}

.bg-cyan {
  fill: #35bdff !important;
}

.bg-gray {
  fill: #ced4da !important;
}

.bg-indigo {
  fill: #005dff !important;
}

.bg-orange {
  fill: #fbb500 !important;
}

.card .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #ff5916;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.card .date .day {
  font-size: 16px;
  line-height: 1;
}
.card .date .month {
  font-size: 11px;
  text-transform: uppercase;
}
.card a:hover {
  text-decoration: none;
  color: #005dff;
}

.card-pricing .card ul li {
  margin-bottom: 1.5rem;
}

.iconbox {
  border: 1px solid;
  text-align: center;
  display: inline-block;
}

.iconbox.iconsmall {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1rem;
}

.iconbox.iconmedium {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 1.8rem;
}

.iconbox.iconlarge {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 2.2rem;
}

.alert-primary {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.alert-primary hr {
  border-top-color: #0054e6;
}
.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert-secondary {
  color: #fff;
  background-color: #7832e2;
  border-color: #7832e2;
}
.alert-secondary hr {
  border-top-color: #6a1fdb;
}
.alert-secondary .alert-link {
  color: #e6e6e6;
}

.alert-success {
  color: #fff;
  background-color: #44c553;
  border-color: #44c553;
}
.alert-success hr {
  border-top-color: #39b747;
}
.alert-success .alert-link {
  color: #e6e6e6;
}

.alert-info {
  color: #fff;
  background-color: #09ebaf;
  border-color: #09ebaf;
}
.alert-info hr {
  border-top-color: #08d29d;
}
.alert-info .alert-link {
  color: #e6e6e6;
}

.alert-warning {
  color: #fff;
  background-color: #ffde03;
  border-color: #ffde03;
}
.alert-warning hr {
  border-top-color: #e9ca00;
}
.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert-danger {
  color: #fff;
  background-color: #ff5916;
  border-color: #ff5916;
}
.alert-danger hr {
  border-top-color: #fc4800;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-light {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #e6e6e6;
}

.alert-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #e6e6e6;
}

.alert-white {
  color: #fff;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #e6e6e6;
}

.alert-purple {
  color: #fff;
  background-color: #ad6edd;
  border-color: #ad6edd;
}
.alert-purple hr {
  border-top-color: #a159d8;
}
.alert-purple .alert-link {
  color: #e6e6e6;
}

.alert-salmon {
  color: #fff;
  background-color: #ff977a;
  border-color: #ff977a;
}
.alert-salmon hr {
  border-top-color: #ff8361;
}
.alert-salmon .alert-link {
  color: #e6e6e6;
}

.alert-cyan {
  color: #fff;
  background-color: #35bdff;
  border-color: #35bdff;
}
.alert-cyan hr {
  border-top-color: #1cb5ff;
}
.alert-cyan .alert-link {
  color: #e6e6e6;
}

.alert-gray {
  color: #fff;
  background-color: #ced4da;
  border-color: #ced4da;
}
.alert-gray hr {
  border-top-color: #bfc7cf;
}
.alert-gray .alert-link {
  color: #e6e6e6;
}

.alert-indigo {
  color: #fff;
  background-color: #005dff;
  border-color: #005dff;
}
.alert-indigo hr {
  border-top-color: #0054e6;
}
.alert-indigo .alert-link {
  color: #e6e6e6;
}

.alert-orange {
  color: #fff;
  background-color: #fbb500;
  border-color: #fbb500;
}
.alert-orange hr {
  border-top-color: #e2a300;
}
.alert-orange .alert-link {
  color: #e6e6e6;
}

ul.list-unstyled li {
  margin-bottom: 0.3rem;
}

@media (min-width: 768px) {
  .loginarea {
    z-index: 1111;
  }
}
article {
  line-height: 1.7;
}
article p, article pre, article figure, article img {
  margin-bottom: 2rem;
}
article blockquote {
  border-left: 7px solid #09ebaf;
  padding-left: 30px;
  margin-left: 30px;
  font-style: italic;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

footer.bg-black a {
  color: #fff;
}

@media (min-width: 992px) {
  .fixedsidebar {
    top: 0px;
    position: fixed;
    z-index: 1050;
    left: 0;
    overflow-y: overlay;
    width: 260px;
    height: 100%;
    padding: 0 0.5rem 0.5rem 1.5rem;
    border-right: 1px solid #e9ecef;
    background-color: #fff;
    padding-bottom: 50px;
  }
  .content-withfixedsidebar {
    margin-left: 260px;
  }
  .content-withfixedsidebar .navbar.fixed-top {
    margin-left: 260px;
  }
}
#docsarea h1 {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
}
#docsarea h2 {
  text-transform: uppercase;
  font-size: 1.4rem;
}
#docsarea h1, #docsarea h2 {
  padding-top: 80px;
  padding-bottom: 30px;
}
#docsarea h1 + h2 {
  padding-top: 0;
}

/* JUST SOME STYLES FOR PRE CODES */
code {
  font-size: 14px;
  display: inline-block;
  line-height: 1.5;
}

.code-toolbar {
  position: relative;
}

.code-toolbar .toolbar-item a {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #ffffff;
  background: #333;
  padding: 0 8px;
  border-radius: 30px;
  font-size: 13px;
  cursor: pointer;
}

.code-toolbar .toolbar-item a:hover, .code-toolbar .toolbar-item a:focus {
  background: #ff5684;
  color: #fff;
}

#docsarea .alert-orange {
  color: rgba(255, 255, 255, 0.83);
}

#docsarea .alert-orange code {
  color: #b73b3b;
}

#docsarea .alert-orange a {
  color: inherit;
  border-bottom: 1px dashed;
}

ul.ascii, ul.ascii ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

ul.ascii li {
  margin: 0;
  padding: 0;
}

/* level 1 */
ul.ascii > li::before {
  content: "";
}

/* level 2 */
ul.ascii > li > ul > li::before {
  content: "├── ";
}

ul.ascii > li > ul > li:last-child::before {
  content: "└── ";
}

/* level 3 */
ul.ascii > li > ul > li > ul > li::before {
  content: "│   ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child::before {
  content: "│   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li::before {
  content: "    ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child::before {
  content: "    └── ";
}

/* level 4 */
ul.ascii > li > ul > li > ul > li > ul > li::before {
  content: "│   │   ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child::before {
  content: "│   │   └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li::before {
  content: "│       ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "│       └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li::before {
  content: "    │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "    │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "        ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "        └── ";
}

/* level 5 */
ul.ascii > li > ul > li > ul > li > ul > li > ul > li::before {
  content: "│   │   │   ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li > ul > li:last-child::before {
  content: "│   │   │   └── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li::before {
  content: "│   │       ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "│   │       └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li::before {
  content: "│       │   ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "│       │   └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "│           ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "│           └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li::before {
  content: "    │   │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li:last-child::before {
  content: "    │   │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li::before {
  content: "    │       ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "    │       └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li::before {
  content: "        │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "        │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "            ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "            └── ";
}

/* level 6 */
ul.ascii > li > ul > li > ul > li > ul > li > ul > li > ul > li::before {
  content: "│   │   │   │   ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li > ul > li > ul > li:last-child::before {
  content: "│   │   │   │   └── ";
}

ul.ascii > li > ul > li > ul > li > ul > li > ul > li:last-child > ul > li::before {
  content: "│   │   │       ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "│   │   │       └── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li > ul > li::before {
  content: "│   │       │   ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "│   │       │   └── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "│   │           ├── ";
}

ul.ascii > li > ul > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "│   │           └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li > ul > li::before {
  content: "│       │   │   ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li > ul > li:last-child::before {
  content: "│       │   │   └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li::before {
  content: "│       │       ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "│       │       └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li::before {
  content: "│           │   ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "│           │   └── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "│               ├── ";
}

ul.ascii > li > ul > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "│               └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li > ul > li::before {
  content: "    │   │   │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li > ul > li:last-child::before {
  content: "    │   │   │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li:last-child > ul > li::before {
  content: "    │   │       ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "    │   │       └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li > ul > li::before {
  content: "    │       │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "    │       │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "    │           ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "    │           └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li > ul > li::before {
  content: "        │   │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li > ul > li:last-child::before {
  content: "        │   │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li:last-child > ul > li::before {
  content: "        │       ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li > ul > li:last-child > ul > li:last-child::before {
  content: "        │       └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li > ul > li::before {
  content: "            │   ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li > ul > li:last-child::before {
  content: "            │   └── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li::before {
  content: "                ├── ";
}

ul.ascii > li > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child::before {
  content: "                └── ";
}