:root {
    --header-top: 3.125vw;
    font-size: 16px; /* 默认字体大小 */
}
@media (max-width: 960px) {
  :root {
     --header-top:4.525rem;
    font-size: 14px; /* 在较小屏幕上减小字体大小 */
  }
}
@import 'header';
@import 'footer';
@import 'containers';
@import 'swiper';
@import 'extends';
@import 'hmCategory';
@import 'hmStep';
@import 'solution_section';
@import '_hmNews';
@import '_hmForm';
@import '_service';
@import '_hmType';
@import '_sidebar';