.hm-sidebar-consult {
  position: fixed;
  z-index: 10;
  top: 10.417vw;
  right: 1.042vw;
  height: 22.833vw;
  width: 3.5vw;
  .sidebar-icon-module{
    height: 11.375vw;
    width: 3.5vw;
    border-radius: 2.083vw;
    background-color: #fff;
    box-shadow: 0.052vw 0.052vw 0.313vw #91B2D7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.521vw;
    .sidebar-icon-line{
      padding: 0.26vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 3.5vw;
      img {
        width: 1.398vw;
        height: 1.242vw;
      }
      span{
        padding-top: 0.26vw;
        font-size: 0.621vw;
        color: #000;
        border-bottom: 0.052vw solid #e7e5e5;
      }
      
    }
    .sidebar-icon-line:last-child{
      span {
        border-bottom: none;
      }
    }
  }
  .back-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3.5vw;
    height: 3.5vw;
    border-radius: 52%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0.052vw 0.052vw 0.313vw #91B2D7;
    img {
      width: 0.725vw;
      height: 0.881vw;
      margin-bottom: 0.321vw;
    }
  }
  .back-lingt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0.521vw;
    .back-lingt-btn {
      height: 3.5vw;
      border-radius: 50%;
      padding: 0;
      overflow: hidden;
      background-color: transparent;
      border: none;
      position: relative;
      .back-movie {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(67, 104, 214, 0.5);
        z-index: -11;
        animation: movie 2s infinite;
      }
    }
    span{
      display: block;
      height: 1.042vw;
      width: 100%;
      line-height: 1.042vw;
      background-color: #005dff;
      color: #fff;
      text-align: center;
      border-radius: 1.042vw;
    }
    
  }

  span{
    font-size: 0.621vw;
  }
}
.hm-sidebar-consult-app {
  position: fixed;
  z-index: 1100;
  top: 40.001vw;
  right: 2.667vw;
  height: 88.453vw;
  width: 15vw;
  .sidebar-icon-module{
    height: 49.12vw;
    width: 15vw;
    border-radius: 7.5vw;
    background-color: #fff;
    box-shadow: 0.133vw 0.133vw 0.801vw #91B2D7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1.334vw;
    .sidebar-icon-line{
      padding: 0.666vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 15vw;
      img {
        width: 6vw;
        height: 6vw;
      }
      span{
        padding-top: 0.666vw;
        font-size: 2.667vw;
        color: #000;
        border-bottom: 0.133vw solid #e7e5e5;
      }
      
    }
    .sidebar-icon-line:last-child{
      span {
        border-bottom: none;
      }
    }
  }
  .back-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 15vw;
    border-radius: 52%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0.133vw 0.133vw 0.801vw #91B2D7;
    img {
      width: 4vw;
      height: 6vw;
      margin-bottom: 0.822vw;
    }
  }
  .back-lingt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 3.334vw;
    .back-lingt-btn {
      height:15vw;
      border-radius: 50%;
      padding: 0;
      overflow: hidden;
      background-color: transparent;
      border: none;
      position: relative;
      .back-movie {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(67, 104, 214, 0.5);
        z-index: -11;
        animation: movie 2s infinite;
      }
    }
    span{
      display: block;
      height: 5vw;
      width: 100%;
      line-height: 5vw;
      background-color: #005dff;
      color: #fff;
      text-align: center;
      border-radius: 2.5vw;
    }
    
  }

  span{
    font-size: 2.667vw;
  }
}

@keyframes movie {
  0%, 100% {
    transform: scale(0.8);
  }
  50%{
   transform: scale(1);
  }
}