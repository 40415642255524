 
.hm-desc {
      margin: 4.479vw auto 1.667vw auto;
    h1 {
        font-weight: 600;
        font-size: 2.083vw;
        color: #000000;
    }
    h5 {
        font-weight: 400;
        font-size: 1.042vw;
        color: #000000;
    }
    .hm-desc-container {
        position: relative;
        width: 65.625vw;
        margin: 0 auto;
        img:nth-child(1){
           width: 31.25vw;
           height: 25vw;
           transition: all 1s;
           &:hover{
	        transform: scale(1.1);
           }
        }
        img:nth-child(2){
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 12.917vw;
            height: 12.031vw;
            z-index: 99;

         }
        img:nth-child(3){
            width: 31.25vw;
            height: 25vw;
           transition: all 1s;
           &:hover{
	        transform: scale(1.1);
           }
         }
         margin-bottom: 4.167vw;
    }
}