

.hm-form-content {
  .hm-form-bg {
    position: relative;
    width: 100vw; /* 元素宽度 */
    height: 38.542vw; /* 元素高度 */
    img {
      height: 100%;
    }
  }
  .hm-form-info {
    position: absolute;
    left: 16.719vw;
    top: 7.031vw;
    h1 {
      font-weight: 600;
      font-size: 1.771vw;
      color: #000000;
      line-height: 2.604vw;
      margin-bottom: 5vw;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .detail-first {
    font-weight: 500;
    font-size: 0.938vw;
    color: #0094fc;
    margin-bottom: 0px;
  }
  .detail-second {
    font-weight: 400;
    font-size: 0.833vw;
    color: #000000;
    margin-bottom: 0px;
  }
  .detail-increase {
    font-weight: normal;
    font-size: 2.135vw;
    color: #000000;
    i {
      display: inline-block;
      width: 2.083vw; /* 元素宽度 */
      height: 2.135vw; /* 元素高度 */
    }
  }
  .hm-form-detail {
    width: 17vw;
  }
  .hm-form-container {
    position: absolute;
    top: 5.698vw;
    right: 16.667vw;
    width: 25.938vw;
    height: 31.656vw;
    border-radius: 0.677vw;
    background-color: #ffffff;
    padding: 2.604vw 1.927vw 1.927vw 2.5vw;
    box-sizing: border-box;
    h1 {
      font-weight: 400;
      font-size: 1.458vw;
      color: #000000;
      margin-bottom: 4.5vw;
    }
    h2 {
      font-weight: 400;
      font-size: 1vw;
      color: #000000;
     
    }
    .user-box {
      position: relative;
      display: flex;
      height: 2.604vw;
      margin-bottom: 1.563vw;
    }
    input[type='checkbox']{
      position: relative;
      top: 0.08vw;
      width: 0.625vw;
      height: 0.625vw;
    }
    .user-box input,
    select {
      width: 100%;
      height: 100%;
      padding-left: 0.938vw;
      font-size: 0.833vw;
      color: #000000;
      
      outline: none;
      border-radius: 0.625vw 0.625vw 0.625vw 0.625vw;
      border: 0.052vw solid #d7d7d7;
    }
    .user-box label {
      position: absolute;
      top: 0.208vw;
      left: 0.833vw;
      padding: 0.521vw 0;
      font-weight: 400;
      font-size: 0.833vw;
      color: #a7a7a7;
      pointer-events: none;
      transition: 0.5s;
    }
  
    .user-box input:focus ~ label,
    .user-box input:valid ~ label {
      top: -0.521vw;
      left: 0.833vw;
      color: #0095fb;
      font-size: 0.581vw;
    }
  
    form a {
      position: relative;
      display: inline-block;
      padding: 0.521vw 1.042vw;
      color: #03e9f4;
      font-size: 0.833vw;
      text-decoration: none;
      text-transform: uppercase;
      overflow: hidden;
      transition: 0.5s;
      margin-top: 2.083vw;
      letter-spacing: 0.208vw;
    }
  }
  .hm-form-telephone span {
    position: absolute;
    top: 0.678vw;
    right: 0.667vw;
    font-weight: 400;
    font-size: 0.833vw;
    color: #0095fb;
    white-space: nowrap;
    cursor: pointer;
  }
  .hm-form-agreement {
    text-align: center;
    font-weight: 400;
    font-size: 0.625vw;
    color: #a7a7a7;
  }
  .hm-form-select {
    select {
      appearance: none;
    }
    select:active {
      .hm-form-label {
        display: none;
      }
    }
  
    
  }
  .hm-form-icon {
      position: absolute;
      top: 0.8vw;
      right: 0.365vw;
      width: 1.938vw;
      height: 1.265vw;
      color: #0095FB;
      cursor: pointer;
      pointer-events: none;
  
  }
  .hm-form-button{
    width: 100%;
    height: 3vw;
    line-height: 3vw;
    border: none;
    border-radius: 0.625vw;
    text-align: center;
    margin-top: 1.042vw;
    background-color: #0094FC;
    font-size: 1.354vw;
    color: #fff;
  }
  
}
.hm-form-content-app {
  .hm-form-bg {
    margin-top: var(--header-top);
    width: 100vw; /* 元素宽度 */
    height: 162.933vw; /* 元素高度 */
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/login-bg@2.png');
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    display: flex;
    flex-direction: column;
  }
  .hm-form-info {
    width: 100vw;
    padding: 9.333vw;
    h1 {
      font-weight: 600;
      font-size: 6.4vw;
      color: #000000;
      line-height: 8.667vw;
      margin-bottom: 4vw;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .detail-first {
    font-weight: 300;
    font-size: 2.4vw;
    color: #000000;
    margin-bottom: 0px;
  }
  .detail-second {
    font-weight: 400;
    font-size: 3.2vw;
    color: #000000;
    margin-bottom: 0px;
    line-height: 7.733vw;
  }
  .detail-increase {
    font-family: Barlow, Barlow;
    font-weight: 900;
    font-size: 7.2vw;
    color: #0094FC;
    line-height: 7.2vw;
    .unit{
      font-size: 4.267vw;
    }
    i {
      display: inline-block;
      width: 6.667vw; /* 元素宽度 */
      height: 6.8vw; /* 元素高度 */
    }
  }
  .hm-form-container {
    // width: 100vw;
    width: 83.467vw;
    height: 104.4vw;
    margin: 0 8.267vw 10vw;
    padding: 7.2vw 4.267vw;
    border-radius: 0.677vw;
    background-color: #ffffff;
    box-sizing: border-box;
    h1 {
      font-weight: 400;
      font-size: 5.333vw;
      color: #000000;
      margin-bottom: 8.5vw;
    }
    h2 {
      font-weight: 400;
      font-size: 4.067vw;
      color: #000000;
      margin-bottom: 6.5vw;
    }
    .user-box {
      position: relative;
      display: flex;
      height: 9.067vw;
      margin-bottom: 3.563vw;
    }
    input[type='checkbox']{
      position: relative;
      top: 1vw;
      width: 3.4vw;
      height: 3.4vw;
    }
    .user-box input,
    select {
      width: 100%;
      height: 100%;
      padding-left: 3.467vw;
      font-size: 2.933vw;
      color: #000000;
      outline: none;
      border-radius: 1.6vw;
      border: 0.133vw solid #d7d7d7;
    }
    .user-box label {
      position: absolute;
      top: 1.633vw;
      left: 2.667vw;
      padding: 0.521vw 0;
      font-weight: 400;
      font-size: 2.933vw;
      color: #a7a7a7;
      pointer-events: none;
      transition: 0.5s;
    }
  
    .user-box input:focus ~ label,
    .user-box input:valid ~ label {
      top: -1.067vw;
      left: 0.833vw;
      color: #0095fb;
      font-size: 2.633vw;
    }
  
    form a {
      position: relative;
      display: inline-block;
      padding: 1.333vw 2.667vw;
      color: #03e9f4;
      font-size: 2.933vw;
      text-decoration: none;
      text-transform: uppercase;
      overflow: hidden;
      transition: 0.5s;
      // margin-top: 2.083vw;
      // letter-spacing: 0.208vw;
    }
  }
  .hm-form-telephone span {
    position: absolute;
    top: 2.333vw;
    right: 1.867vw;
    font-weight: 400;
    font-size: 2.933vw;
    color: #0095fb;
    white-space: nowrap;
    cursor: pointer;
  }
  .hm-form-agreement {
    text-align: center;
    font-weight: 400;
    font-size: 3.333vw;
    color: #a7a7a7;
  }
  .hm-form-select {
    select {
      appearance: none;
      background-color: #fff;
    }
    select:active {
      .hm-form-label {
        display: none;
      }
    }
  
    
  }
  .hm-form-icon {
      position: absolute;
      top: 3vw;
      right: 3vw;
      width: 3.2vw;
      height: 3.2vw;
      color: #0095FB;
      cursor: pointer;
      pointer-events: none;
  
  }
  .hm-form-button{
    width: 72.4vw;
    height: 10.933vw;
    line-height: 10.933vw;
    border: none;
    border-radius: 2.933vw;
    text-align: center;
    margin-top: 2.667vw;
    background-color: #0094FC;
    font-size: 5.867vw;
    color: #fff;
  }
}
#messageiframe {
  display: none;
}
.iframe-dialog {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1000;
  min-width: 70vw;
  background-color: #fff;
  color: #f83538;
  padding: 0.521vw 1.042vw;
  border-radius: 0.26vw;
  box-shadow: 0 0.104vw 0.26vw rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  iframe{
    width: 100%;
    height: 60vh;
  }
  button {
    background: none;
    border: none;
    font-size: 3em;
    padding: 0;
    text-align: right;
    cursor: pointer;
    color: #999;
    outline: none;
    resize: none;
    padding: 0;
    margin: 0;
  }
}
.message-hmc {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1000;
  min-width: 9.375vw;
  background-color: #fff;
  color: #f83538;
  padding: 0.521vw 1.042vw;
  border-radius: 0.26vw;
  box-shadow: 0 0.104vw 0.26vw rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.message-hmc.close {
  opacity: 0;
  transform: translateY(-10px);
}

.message-hmc button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #ccc;
  outline: none;
  resize: none;
  padding: 0;
  margin: 0;
}

#messageBox {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  .messageBox {
    position: fixed;
    top: 40%;
    left: 50%;
    z-index: 1000;
    min-width: 20.375vw;
    color: #666666;
    padding: 0.521vw 1.042vw;
    border-radius: 0.26vw;
    box-shadow: 0.3vw 0.404vw 0.46vw rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-weight: bold;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    .messageBox-title {
      font-size: 0.885vw;
      display: flex;
      justify-content: space-between;
      .title-right{
        line-height: 1vw;
        font-size: 1.3vw;
        font-weight: normal;
      }
    }
    .messageBox-content{
      display: flex;
      align-items: center;
      margin: 1.042vw 0;
      width: 100%;
      height: 3vw;
      font-size: 1.042vw;
      img{
        height: 100%;
      }
      span{
        padding-left: 0.821vw;
      }
    }
    .messageBox-footer {
      button{
        width: 5.2vw;
        height: 1.7vw;
        font-size: .842vw;
        background-color: #3f86ff;
        color: #fff;
        border-radius: 1vw;
        border: none;
        outline: none;
      }
    }
  }
}
#messageBox-app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  .messageBox-app {
    position: fixed;
    top: 45%;
    left: 50%;
    z-index: 1000;
    min-width: 50.375vw;
    color: #666666;
    padding: 2vw 2.042vw;
    border-radius: 0.56vw;
    box-shadow: 0.6vw 0.804vw 0.86vw rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-weight: bold;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    .messageBox-title {
      font-size: 3.885vw;
      display: flex;
      justify-content: space-between;
      .title-right-app{
        line-height: 4vw;
        font-size: 5.6vw;
        font-weight: normal;
      }
    }
    .messageBox-content{
      display: flex;
      align-items: center;
      margin: 3.042vw 0;
      width: 100%;
      height: 7vw;
      font-size: 5.042vw;
      img{
        height: 100%;
      }
      span{
        padding-left: 2vw;
      }
    }
    .messageBox-footer {
      text-align: center;
      button{
        width: 20vw;
        height: 5.8vw;
        font-size: 3vw;
        background-color: #3f86ff;
        color: #fff;
        border-radius: 2.9vw;
        border: none;
        outline: none;
      }
    }
  }
}
