#hm-step {
  padding: 3.631vw 0px 10.417vw 0px;
   h1 {
        font-weight: 600;
        font-size: 1.875vw;
        color: #000000;
       
   }
   h4 {
    font-weight: 400;
    font-size: 1.042vw;
    color: #000000;
    margin-bottom: 2.604vw;
   }
   .row {
      width: 72.083vw;
      margin: 0 auto;
      div {
         position: relative;
         transform: scale(1);
            transition: all 1s;
      }
      div:hover {
        transform: scale(1.1);
      }
      div:nth-child(1){
        &::after {
         position: absolute;
         top: 6.5vw;
         z-index: -1;
         content: "01";
         font-weight: 400;
         font-size: 4.115vw;
         color: #FAEBF2;
        }
      }
      div:nth-child(2){
         &::after {
          position: absolute;
          top: 6.5vw;
          z-index: -1;
          content: "02";
          font-weight: 400;
          font-size: 4.115vw;
          color: #F2EBFA;
         }
       }
       div:nth-child(3){
         &::after {
          position: absolute;
          top: 6.5vw;
          z-index: -1;
          content: "03";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBEFFA;
         }
       }
       div:nth-child(4){
         &::after {
          position: absolute;
          top: 6.5vw;
          z-index: -1;
          content: "04";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBF4FA;
         }
       }
       div:nth-child(5){
         &::after {
          position: absolute;
          top: 6.5vw;
          z-index: -1;
          content: "05";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBF7FA;
         }
       }
       div:nth-child(6){
         &::after {
          position: absolute;
          top: 6.5vw;
          z-index: -1;
          content: "06";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBF7FA;
         }
       }
   }
   .col {
    padding: 0px;
   }
   img {
    position: relative;
    width: 7.2vw;
    height: 8.75vw;
   }
   
   .hm-category-firstDesc {
    font-weight: 400;
    font-size: 1.302vw;
    color: #000000;
    margin-bottom: 0.26vw;
    white-space: nowrap;
   }
   .hm-category-secondDesc {
    font-weight: 400;
    font-size: 0.729vw;
    color: #A2A2A2;
   }
   .hm-step-footer {
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/ft.png'); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 73.542vw; /* 元素宽度 */
    height: 2.708vw; /* 元素高度 */
    border-radius: 0.5vw;
    box-sizing: border-box;
    margin: 0 auto;
   }
}
#hm-step-app {
  padding: 3.631vw 0px 17.417vw 0px;
  h1 {
    font-weight: 700;
    font-size: 4.533vw;
    line-height: 6vw;
    color: #000000;
    margin: 8.479vw 6.667vw 0;
    strong {
      display: inline;
      font-size: 5.5vw;
    }
  }
  
  h4 {
      font-weight: 400;
      font-size: 3.067vw;
      color: #000000;
      margin: 1.479vw 6.667vw 0vw;
  }
  
  
   .row {
      width: 100vw;
      margin: 6vw auto 0;
      div {
         position: relative;
         transform: scale(1);
            transition: all 1s;
      }
      div:hover {
        transform: scale(1.1);
      }
      .col:nth-child(1){
        &::after {
         position: absolute;
         bottom: -5.9vw;
         z-index: -1;
         content: "01";
         font-weight: 400;
         font-size: 4.115vw;
         color: #FAEBF2;
        }
      }
      .col:nth-child(2){
         &::after {
          position: absolute;
          bottom: -5.9vw;
          z-index: -1;
          content: "02";
          font-weight: 400;
          font-size: 4.115vw;
          color: #F2EBFA;
         }
       }
       div:nth-child(3){
         &::after {
          position: absolute;
          bottom: -5.9vw;
          z-index: -1;
          content: "03";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBEFFA;
         }
       }
   }
   .row-2 {
      width: 100vw;
      margin: 6vw auto 0;
      div {
         position: relative;
         transform: scale(1);
            transition: all 1s;
      }
      div:hover {
        transform: scale(1.1);
      }
      .col:nth-child(1){
        &::after {
         position: absolute;
         bottom: -5.9vw;
         z-index: -1;
         content: "04";
         font-weight: 400;
         font-size: 4.115vw;
         color: #FAEBF2;
        }
      }
      .col:nth-child(2){
         &::after {
          position: absolute;
          bottom: -5.9vw;
          z-index: -1;
          content: "05";
          font-weight: 400;
          font-size: 4.115vw;
          color: #F2EBFA;
         }
       }
       div:nth-child(3){
         &::after {
          position: absolute;
          bottom: -5.9vw;
          z-index: -1;
          content: "06";
          font-weight: 400;
          font-size: 4.115vw;
          color: #EBEFFA;
         }
       }
   }
   .col {
    padding: 0px;
   }
   img {
    position: relative;
    width: 18vw;
    height: 22vw;
   }
   
   .hm-category-firstDesc {
    font-weight: 400;
    font-size: 4vw;
    color: #000000;
    margin-bottom: 0.26vw;
    white-space: nowrap;
   }
   .hm-category-secondDesc {
    font-weight: 400;
    font-size: 0.729vw;
    color: #A2A2A2;
   }
   .hm-step-footer {
    background-image: linear-gradient(to right, #eaa3f9, #336cfc); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 96vw; /* 元素宽度 */
    height: 5vw; /* 元素高度 */
    border-radius: 2.5vw;
    box-sizing: border-box;
    margin: 0 auto;
   }
   .hm-step-footer-2 {
    background-image: linear-gradient(to right, #3280f9, #2ad5eb); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 96vw; /* 元素宽度 */
    height: 5vw; /* 元素高度 */
    border-radius: 2.5vw;
    box-sizing: border-box;
    margin: 0 auto;
   }
}