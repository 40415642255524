


.hm-introduce-content {
    position: relative;
    section {
        width: 100vw;
        padding: 4.635vw 16.667vw 0vw 16.667vw;
    }
    .hm-introduce-bg {
        position: relative;
        width: 100vw;
        height: 15.573vw;
        margin-top: var(--header-top);
        h1 {
            position: absolute;
            top: 6.51vw;
            left: 16.771vw;
            font-weight: 600;
            font-size: 2.604vw;
            color: #FFFFFF;
    
        }
        img {
            width: 100%;
        }
    }
    .hm-feature-title {
        font-weight: 400;
        font-size: 1.667vw;
        color: #000000;
        margin-top: 5.677vw;
        margin-bottom: 4.635vw;
    }
    .hm-category-item-left {
       border-radius: 0.625vw;
       padding: 0.781vw;
       box-shadow: 0px 0px 10px #ccc;
       margin-bottom: 3.906vw;
       .category-left {
        width: 50%;
        p{
          font-weight: 400;
          font-size: 0.938vw;
          color: #454545;
        }
        .hm-category-title {
            margin: 1.302vw 0;
            h4 {
              font-weight: 500;
              font-size: 1.25vw;
              color: #2376FC;
              margin: 0;
            }
            img{
                height: 30.006px;
                width: 30.006px;
                margin: 0.26vw;
            }
        }
       }
       .category-right {
        flex: 1;
        img {
            width: 100%;
            height: 100%;
        }
       }
        
    }
    .hm-category-item-right {
       border-radius: 0.625vw;
       padding: 0.781vw;
       box-shadow: 0px 0px 10px #ccc;
       margin-bottom: 3.906vw;
       .category-right {
        width: 50%;
        p{
          font-weight: 400;
          font-size: 0.938vw;
          color: #454545;
        }
        .hm-category-title {
            margin: 1.302vw 0;
            h4 {
              font-weight: 500;
              font-size: 1.25vw;
              color: #2376FC;
              margin: 0;
            }
            img{
                height: 30.006px;
                width: 30.006px;
                margin: 0.26vw;
            }
        }
       }
       .category-left {
        flex: 1;
        img {
            width: 100%;
            height: 100%;
        }
       }
        
    }
    .hm-feature-left {
        h2 {
            font-weight: 400;
            font-size: 1.667vw;
            color: #000000;
            margin-bottom: 3.021vw;
        }
        p {
            font-weight: 400;
            font-size: 1.042vw;
            color: #454545;
            line-height: 1.563vw;
            margin-bottom: 2.396vw;
        }
        ul {
            font-weight: 400;
            font-size: 0.885vw;
            color: #454545;
        }
        li:before {
          position: relative; 
          top: -0.12vw;
          content: "";
          display: inline-block;
          width: 0.365vw;
          height: 0.365vw;
          border-radius: 50%;
          background: #000000;
          margin-right: 0.521vw;
        }
    }
    .hm-feature-right {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg10.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 31.094vw; /* 元素宽度 */
        height: 15vw; /* 元素高度 */
        box-sizing: border-box;
        padding: 3.958vw 0vw 0vw 2.344vw;
        h2 {
            font-weight: 400;
            font-size: 1.979vw;
            color: #FFFFFF;
            margin-bottom: 1.823vw;
        }
        p {
            font-weight: 400;
            font-size: 1.042vw;
            color: #FFFFFF;
        }
        p:before {
            position: relative; 
            top: -0.12vw;
            content: "";
            display: inline-block;
            width: 0.365vw;
            height: 0.365vw;
            border-radius: 50%;
            background: #FFFFFF;
            margin-right: 0.521vw;
        }
    }
    .hm-product-detail-img{
        margin-bottom:3.906vw ;
        img{
            width: 900px;
            display: block;
            margin: 0 auto;
        }
    }
    .hm-product-navigation {
        height: 11.979vw;
        .hm-navigation-link {
            width: 18.229vw;
            height: 2.865vw;
            line-height: 2.865vw;
            border-radius: 0.781vw;
            font-size: 0.833vw;
            color: #000000;
            font-weight: 500;
            border: 0.052vw solid #BED5FF;
            text-align: center;
            cursor: pointer;
        }
    }
    
    .hm-sidebar-bar {
        position: absolute;
        top: 18.229vw;
        left: 3.125vw;
        // width: 16.667vw;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 35.646vw;
        cursor: pointer;
        h5{
            height: 1.563vw;
            font-size: 0.833vw;
            color: #2376FC;
            text-align: center;
        }
        a{
            text-decoration: none;
            transform: scale(1);
            transition: all 1s;
            &:hover{
             transform: scale(1.1);
            }
         }
        
        .col {
            padding:0px;
            background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png'); /* 设置图片路径 */
            background-size: cover; /* 背景图覆盖整个元素 */
            background-repeat: no-repeat; /* 背景图不重复 */
            background-position: center; /* 背景图居中 */
            width: 8.51vw;
            height: 8.51vw;
        }
        .hm-category-secondDesc {
            font-weight: 300;
            font-size: 0.6vw;
            color: #989898;
            cursor: pointer;
        }
        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 0.746vw;
            color: #000000;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
       
        p {
            margin: 0vw;
        }
        img {
            margin-top: 2.1vw;
            width: 3.385vw;
            height: 3.385vw;
        }
    }

    .hm-category-new {
        width: 58.594vw;
        margin-bottom: 5.833vw;
       
        h1{
            font-size: 1.667vw;
            font-weight: 900;
            margin-top: 4.323vw;
            margin-bottom: 1.042vw;
        }
        p {
            font-size: 1.042vw;
            margin-bottom: 1.198vw;
        }
    }
}
.hm-introduce-content-app {
    font-family: MiSans, MiSans;
    section {
        width: 100vw;
        padding: 7.333vw 8.267vw 0vw 8.267vw;
    }
    .hm-introduce-bg {
        position: relative;
        width: 100vw;
        height: 40vw;
        margin-top: var(--header-top);
        h1 {
            font-family: MiSans, MiSans;
            position: absolute;
            top: 12.267vw;
            left: 8.133vw;
            font-weight: 600;
            font-size: 6.667vw;
            line-height: 8.533vw;
            color: #FFFFFF;
            width: 54.267vw;
            height: 14.933vw;
    
        }
        img {
            width: 100%;
        }
    }
    .hm-feature-title {
        font-weight: 400;
        font-size: 4.267vw;
        color: #000000;
        text-align: left;
        margin-top: 8vw;
        margin-bottom: 8.4vw;
    }
    .hm-category-item {
        width: 78.4vw;
        border-radius: 0.625vw;
        padding: 4vw;
        box-shadow: 0px 0px 1.067vw #ccc;
        margin-bottom: 3.906vw;
        .hm-category-title {
            margin: 1.302vw 0;
            img {
                width: 6.979vw;
                height: 100%;
                max-width: unset;
                margin-right: 2vw;
            }
            h4 {
                font-size: 4vw;
                color: #2376FC;
                line-height: 4vw;
                margin: 0;
            }
        }
        .hm-category-item-text {
            font-size: 3.066vw;
            line-height: 5vw;
            margin: 2.133vw 0 4.133vw 0;
            font-weight: 500;
        }
    }
    .hm-feature-left {
        h2 {
            font-weight: 400;
            font-size: 4.533vw;
            color: #000000;
            margin-bottom: 3.021vw;
        }
        p {
            font-weight: 400;
            font-size: 3.2vw;
            color: #454545;
            line-height: 4.8vw;
            margin-bottom: 4.8vw;
        }
        ul {
            font-size: 2.667vw;
            color: #454545;
            line-height: 4.8vw;
        }
        li:before {
          position: relative; 
          top: -0.12vw;
          content: "";
          display: inline-block;
          width: 0.365vw;
          height: 0.365vw;
          border-radius: 50%;
          background: #000000;
          margin-right: 0.521vw;
        }
    }
    .hm-feature-right {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg10@2.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 83.333vw; /* 元素宽度 */
        height: 40.267vw; /* 元素高度 */
        box-sizing: border-box;
        padding: 10.8vw 0vw 0vw 6.267vw;
        h2 {
            font-weight: 400;
            font-size: 5.333vw;
            color: #FFFFFF;
            line-height: 8.933vw;
        }
        p {
            font-size: 2.8vw;
            color: #FFFFFF;
            line-height: 4.133vw;
        }
        a{
            font-size: 2.8vw;
            color: #FFFFFF;
            line-height: 4.133vw;
            border-bottom: 1px solid #fff;
        }
        p:before {
            content: "";
            display: inline-block;
            width: 1vw;
            height: 1vw;
            border-radius: 50%;
            background: #FFFFFF;
            margin-right: 1.4vw;
        }
    }
    .hm-product-detail-img{
        margin-bottom:3.906vw ;
        img{
            width: 900px;
            display: block;
            margin: 0 auto;
        }
    }
    .hm-product-navigation {
        margin-bottom: 7.733vw;
        .hm-navigation-link {
            width: 33.067vw;
            height: 15.333vw;
            border-radius: 1.467vw;
            border: 0.052vw solid #BED5FF;
            text-align: center;
            cursor: pointer;
            font-size: 3.2vw;
            color: #000000;
            line-height: 4.8vw;
            text-align: left;
            padding: 3.333vw 3.467vw;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
    }
    
    .hm-sidebar-bar {
        height: 110.4vw;
        width: 100vw;
        padding: 6.933vw;
        cursor: pointer;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/27@2.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        h5{
            font-size: 4.8vw;
            color: #2376FC;
            text-align: left;
        }
        a{
            text-decoration: none;
            transform: scale(1);
            transition: all 1s;
            &:hover{
             transform: scale(1.1);
            }
         }
         .hm-sidebar-bar-main {
            display: flex;
            flex-wrap: wrap;
         }
        
        a {
            background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg12.png'); /* 设置图片路径 */
            background-size: cover; /* 背景图覆盖整个元素 */
            background-repeat: no-repeat; /* 背景图不重复 */
            background-position: center; /* 背景图居中 */
            width: 42.2vw;
            height: 42.5vw;
            padding: 2.5vw;
            margin: 0.2vw;
        }
        .hm-category-secondDesc {
            font-weight: 300;
            font-size: 2.533vw;
            color: #989898;
        }
        .hm-category-firstDesc {
            font-weight: 400;
            font-size: 3.467vw;
            color: #000000;
            text-align: center;
            width: 100%;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
       
        p {
            margin: 0.5vw;
        }
        img {
            margin-top: 2.5vw;
            width: 20vw;
            height: 20vw;
        }
    }
    .hm-category-new {
        margin-bottom: 5.833vw;
       
        h1{
            font-size: 4.533vw;
            font-weight: 900;
            margin-top: 8.323vw;
            margin-bottom: 3.042vw;
        }
        p {
            font-weight: 400;
            font-size: 3.2vw;
            color: #454545;
            line-height: 7vw;
            margin-bottom: 1.198vw;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}


