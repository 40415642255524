.hm-news-content {
    font-family: MiSans, MiSans;
    background-color: #f2f2f2;
    position: relative;
    // height: 87.208vw;
    margin-bottom: -3vw;
    margin-top: var(--header-top);

    .hm-new-bg {
        width: 100vw;
        /* 元素宽度 */
        height: 15.573vw;
        /* 元素高度 */
        background-color: #fcfcfc;
        position: relative;
        img{
            width: 100%;
        }
        h1{
            position: absolute;
            top: 50%;
            left: 15.625vw;
            font-size: 2.083vw;
            font-weight: 900;
            color: #fff;
        }
    }

    .hm-download {
        height: 10vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3vw;
        background-color: #fff;

        a {
            margin: 2vw;
            font-size: 0.833vw;
            color: #000000;

            &:nth-child(2) {
                display: block;
                height: 2.083vw;
                line-height: 2.083vw;
                text-align: center;
                width: 6.25vw;
                border: 1px solid #1868c9;
                color: #1868c9;
            }
        }
    }

    .hm-iframe-box {
        // position: absolute;
        position: relative;
        top: -8.28vw;
        // width: 62.5vw;
        // margin-left: 13.021vw;
        background-color: #fff;
        border-radius: 1.042vw;
        overflow: hidden;

        .details-title {
            margin: 0 1.042vw;
            padding: 1.042vw 0;
            display: block;
            border-bottom: 1px solid #d4d6d8;

            .news_list_detail {
                font-size: 1.25vw;
                line-height: 1.5;
                color: #000000;
            }

            .news_list_info {
                font-size: 0.833vw;
                color: #b2b2b2;
                display: flex;
                align-items: center;

                span {
                    padding-right: 0.521vw;
                }

                .icon-eye {
                    display: inline-block;
                    vertical-align: sub;
                    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/eye.png');
                    /* 设置图片路径 */
                    background-size: cover;
                    /* 背景图覆盖整个元素 */
                    background-repeat: no-repeat;
                    /* 背景图不重复 */
                    background-position: center;
                    /* 背景图居中 */
                    width: 1vw;
                    height: .55vw;
                    margin-right: 0.521vw;
                }
            }
        }

        .met-editor-notice {
            padding: 1vw;
            font-family: 微软雅黑, MicrosoftYaHei;

            p {
                font-size: 0.885vw;
                text-indent: 2em;
            }

            .flon {
                font-size: 0.99vw;
            }

            .text-red {
                color: #ff0000;
            }

            .text-other {
                color: #17365d;
                font-weight: bold;
            }

            a {
                color: #62a8ea;
                font-weight: 500;
            }

            .iframe-img {
                display: flex;
                justify-content: center;
            }
        }

        .iframe-title {
            margin: 1.563vw;
        }

        .met-editor {
            padding: 1vw;
            font-size: 0.781vw;
            font-family: 微软雅黑, MicrosoftYaHei;

            .text-bold {
                font-weight: bold;
                color: #0d54fe;
            }

            .img-content {
                img {
                    width: 41.667vw;
                }
            }

            .mecdi-item {
                position: relative;
                background-color: #f6f6f6;
                padding: 0.521vw 0.781vw 0.781vw 5.208vw;

                strong {
                    font-weight: bold;
                }

                .tip {
                    position: absolute;
                    top: 0;
                    left: 1.042vw;
                    width: 2em;
                    height: 3vw;
                    background-color: rgb(0, 82, 217);
                }

                .tip::after {
                    position: absolute;
                    bottom: -0.9em;
                    content: '';
                    outline: 0px;
                    height: 0.5em;
                    width: 2em;
                    border-left: 1em solid rgb(0, 82, 217);
                    border-right: 1em solid rgb(0, 82, 217);
                    visibility: visible;
                    border-bottom: 1em solid transparent !important;
                }

                .bc-line {
                    background-color: rgb(0, 82, 217);
                    color: #fff;
                    font-weight: bold;
                }
            }

            .four-title {
                font-size: 2.6vw;
                padding: 0.5vw;
                margin-bottom: 3.084vw;
                font-weight: bold;
                position: relative;

                span {
                    font-size: 2.3vw;
                    color: #deebfb;
                }

                .line {
                    position: absolute;
                    top: 1.25vw;
                    left: 0;
                    color: #256ed9;

                    span {
                        &:nth-child(1) {
                            color: #256ed9;
                            font-size: 4.125vw;
                            letter-spacing: -0.261vw;
                            padding-right: 0.521vw;
                        }
                    }
                }

            }

            .line-two {
                margin-left: 4.3vw;
                margin-top: -1.6vw;
                margin-bottom: 1vw;
                color: #256ed9;
                font-size: 1.8vw;
                font-weight: bold;
            }

            .four-title-second {
                color: #256ed9;
                font-size: 2vw;
                font-weight: bold;
            }

            ul {
                li {
                    font-weight: bold;
                    margin: 0.5vw;
                }
            }

            h3 {
                text-align: center;
                font-weight: 900;
                padding: 2vw 0 2vw;
            }

            .item-line {
                font-weight: 600;
            }

            .text-d {
                text-indent: 2em;
            }

            .mb-1 {
                margin-bottom: 1vw !important;
            }

            .mb-4 {
                margin-bottom: 5vw !important;
            }

            .text-underline {
                text-decoration: underline;
                font-weight: 600;
            }

            a {
                color: #62a8ea;
                font-weight: 500;
            }

            table {
                thead {
                    tr {
                        th {
                            height: 5vw;
                            line-height: 2.5vw;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 1vw;
                        }
                    }
                }
            }

            p {
                margin-bottom: 0.8em;

                img {
                    width: 100%;
                }
            }
        }

        .met-editor-four {
            padding: 1vw 5vw;
            font-size: 0.833vw;
            font-family: 微软雅黑, MicrosoftYaHei;

            .text-bold {
                font-weight: bold;
                color: #0d54fe;
            }
            .img-content {
                img {
                    width: 41.667vw;
                }
            }

            .mecdi-item {
                position: relative;
                background-color: #f6f6f6;
                padding: 0.521vw 0.781vw 0.781vw 100px;

                strong {
                    font-weight: bold;
                }

                .tip {
                    position: absolute;
                    top: 0;
                    left: 1.042vw;
                    width: 2em;
                    height: 3vw;
                    background-color: rgb(0, 82, 217);
                }

                .tip::after {
                    position: absolute;
                    bottom: -0.9em;
                    content: '';
                    outline: 0px;
                    height: 0.5em;
                    width: 2em;
                    border-left: 1em solid rgb(0, 82, 217);
                    border-right: 1em solid rgb(0, 82, 217);
                    visibility: visible;
                    border-bottom: 1em solid transparent !important;
                }

                .bc-line {
                    background-color: rgb(0, 82, 217);
                    color: #fff;
                    font-weight: bold;
                }
            }

            .four-title {
                font-size: 2.6vw;
                padding: 0.5vw;
                margin-bottom: 3.084vw;
                font-weight: bold;
                position: relative;

                span {
                    font-size: 2.3vw;
                    color: #deebfb;
                }

                .line {
                    position: absolute;
                    top: 1.25vw;
                    left: 0;
                    color: #256ed9;

                    span {
                        &:nth-child(1) {
                            color: #256ed9;
                            font-size: 4.125vw;
                            letter-spacing: -0.261vw;
                            padding-right: 0.521vw;
                        }
                    }
                }

            }

            .line-two {
                margin-left: 4.3vw;
                margin-top: -1.6vw;
                margin-bottom: 1vw;
                color: #256ed9;
                font-size: 1.8vw;
                font-weight: bold;
            }

            .four-title-second {
                color: #256ed9;
                font-size: 2vw;
                font-weight: bold;
            }

            ul {
                margin: 2vw 0;

                li {
                    font-weight: bold;
                    margin: 0.5vw;
                }
            }

            .title-six {
                margin: 0.521vw auto;
                font-weight: bold;
                .top-tip{
                    font-size: 1.042vw; 
                    letter-spacing: 0.078vw; 
                    color: rgb(175, 199, 117); 
                    text-align: center;
                }
                .serial {
                    font-size: 0.833vw;
                    color: rgb(255, 255, 255);
                    width: 1.302vw;
                    height: 1.302vw;
                    background-color: rgb(11, 121, 255);
                    border-radius: 100%;
                    text-align: center;
                    line-height: 1.302vw;
                }
                .title-content {
                    font-size: 1.25vw; 
                    color: rgb(11, 121, 255); 
                    text-align: left; 
                    padding-right: 0.26vw;
                }
                .title-line {
                    width: 100%;
                    height: 1px; border-top: 1px solid rgb(203, 227, 162);
                }
            }
        }
        .met-editor-introd {
            padding: 1vw 5vw;
            font-size: 0.833vw;
            font-family: PingFang SC,system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
            .text-blur {
                color: #0d54fe;
            }
            .text-red {
                color: #d7281d;
            }
            .img-content {
                img{
                    width: 60%;
                }
            }
            .introd-desc {
                text-indent: 3em;
                font-weight: 500;
            }
            .introd-two {
                margin-left: 3em;
                font-weight: 500;
            }
            .introd-h {
                margin: 4.5vw 0 1vw 0;
                font-size: 2.6vw;
                font-weight: 900;
            }
            .introd-htwo {
                margin: 2.083vw 2vw;
                h2{
                    font-size: 2vw;
                    font-weight: 900;
                }
            }
        }
        .met-editor-year {
            padding: 1vw 5vw;
            font-size: 0.833vw;
            .year-main {
                display: flex;
                flex-direction: column;
                .text-blur {
                    color: #0d54fe;
                    font-weight: bold;
                }
                .top-item {
                    background-image: linear-gradient(to right bottom, #c42714, #da731f);
                    .top-item-desc {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        color: #ffee9e;
                        margin-top: -2.604vw;
                        margin-bottom: 2.604vw;
                        overflow: hidden;
                        h2{
                            font-weight: 900;
                        }
                        p{
                            font-size: 0.729vw;
                        }
                    }
                }
                .year-content {
                    margin-top: -0.521vw;
                    border: 1px solid #0086db;
                    padding: 0 0.781vw;
                    .img-content {
                        margin: 0 1.563vw;
                    }
                    .img-md {
                        width: 80%;
                    }
                    .mt-5{
                        margin-top: 2vw;
                    }
                    .p-tip {
                        text-align: center;
                        font-size: 0.625vw;
                    }
                    .year-p-desc {
                        line-height: 2vw;
                        p{
                            text-indent: 2em;
                        }
                    }
                    .year-title {
                        margin: 1.563vw;
                        text-align: center;
                        span {
                            font-size: 1.198vw;
                            font-weight: bold;
                            padding: 0.2vw 0.521vw;
                            color: #fff;
                            background-color: #fcb42b; 
                        }
                    }
                }
            }
        }

        .met-editor-look {
            padding: 1vw 5vw;
            font-size: 0.833vw;
            color: #000;
            font-family: 微软雅黑, MicrosoftYaHei;

            .text-bold {
                font-weight: bold;
                color: #0d54fe;
            }

            .img-content {
                img {
                    width: 41.667vw;
                }
            }

            .mt {
                margin-top: 2vw;
                margin-bottom: 2vw;

                p {
                    text-indent: 2em;
                }
            }

            .mecdi-item-look {
                margin: 2vw;
                padding: 1.5em;
                outline: 0px;
                height: auto;
                overflow: hidden;
                box-shadow: rgb(102, 102, 102) 0px 0px 10px;
                visibility: visible;
                position: relative;
                background-color: #fff;

                .img-content {
                    img {
                        width: 41.667vw;
                        margin: 2.083vw 0;
                    }
                }
            }

            .look-title {
                margin: 3vw 0 2vw;
                display: flex;
                justify-content: center;
                text-align: center;

                div {
                    height: 2.083vw;

                    &:nth-child(1) {
                        width: 0.521vw;
                        background-color: rgb(56, 89, 254);
                        transform: skewX(-20deg);
                        overflow: hidden;
                    }

                    &:nth-child(2) {
                        width: 0vw;
                        border-top: 2.083vw solid transparent;
                        border-right: 0.729vw solid rgb(56, 89, 254);
                        overflow: hidden;
                    }

                    &:nth-child(3) {
                        font-size: 1.042vw;
                        font-weight: bold;
                        color: #fff;
                        padding: 0.313vw 1.042vw;
                        letter-spacing: 0.078vw;
                        background-image: -webkit-linear-gradient(left, rgb(56, 89, 254), rgb(118, 140, 249))
                    }

                    &:nth-child(4) {
                        width: 0vw;
                        border-bottom: 2.083vw solid transparent;
                        border-left: 0.729vw solid rgb(118, 140, 249);
                        overflow: hidden;
                    }

                    &:nth-child(5) {
                        width: 0.521vw;
                        background-color: rgb(56, 89, 254);
                        transform: skewX(-20deg);
                        overflow: hidden;
                    }
                }

            }

            .chat-line {
                border: 0.052vw solid #67b8e8;
                border-radius: 0.521vw;

                .content {
                    border-radius: 0.521vw;
                    background-color: #f5faff;
                    padding: 2.083vw;
                    margin: 0.217vw;
                    font-size: 0.729vw;

                    .one {
                        display: flex;

                        div:nth-child(1) {
                            height: 1.823vw;
                            width: 1.823vw;
                            line-height: 1.823vw;
                            margin-right: 0.521vw;
                            text-align: center;
                            border-radius: 1.042vw;
                            font-size: 1.042vw;
                            font-weight: 900;
                            background-color: #1a9cf0;
                            color: #fff;
                        }

                        div:nth-child(2) {
                            position: relative;
                            height: 1.823vw;
                            line-height: 1.823vw;
                            text-align: center;
                            padding: 0 1.042vw;
                            border-radius: 1.042vw;
                            background-color: #ff9b0b;
                            color: #fff;

                            &::after {
                                position: absolute;
                                bottom: -0.521vw;
                                left: 1.042vw;
                                content: "";
                                width: 0px;
                                height: 0.052vw;
                                border-top: 0.729vw solid rgb(255, 155, 11);
                                border-right: 0.417vw solid transparent;
                                margin-top: -0.208vw;
                                margin-left: 1.042vw;
                                transform: rotate(30deg);
                                visibility: visible;
                            }
                        }
                    }

                    .tow-left {
                        margin: 0.521vw 0;
                        display: flex;

                        div {
                            position: relative;
                            text-align: right;
                            background-color: #92d050;
                            color: #fff;
                            font-size: 0.729vw;
                            padding: 1vw 2vw;
                            border-radius: 0.521vw;

                            &::after {
                                content: "";
                                position: absolute;
                                left: -0.521vw;
                                top: 1.1vw;
                                width: 0px;
                                height: 1px;
                                border-top: 0.521vw solid transparent;
                                border-right: 0.521vw solid rgb(146, 208, 80);
                                border-bottom: 0.521vw solid transparent;
                                overflow: hidden;
                                visibility: visible;
                            }
                        }
                    }

                    .tow-right {
                        margin: 0.521vw 0;
                        display: flex;
                        justify-content: end;

                        div {
                            position: relative;
                            text-align: right;
                            background-color: #92d050;
                            color: #fff;
                            font-size: 0.729vw;
                            padding: 1vw 2vw;
                            border-radius: 0.521vw;

                            &::after {
                                content: "";
                                position: absolute;
                                right: -0.521vw;
                                top: 1.1vw;
                                width: 0vw;
                                height: 0.052vw;
                                border-top: 0.521vw solid transparent;
                                border-left: 0.521vw solid rgb(146, 208, 80);
                                border-bottom: 0.521vw solid transparent;
                                overflow: hidden;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            li {
                p {
                    color: #a5a5a5;
                    text-indent: 1em;
                    margin-bottom: 0.5vw;
                }
            }

            .client-desc {
                .head {
                    height: 3vw;
                    border-radius: 1.5vw;
                    border: 0.8vw solid #1aa6ff;
                    background-color: #0086db;
                }

                .desc-content {
                    margin-top: -0.521vw;
                    margin-left: 1.042vw;
                    margin-right: 1.042vw;
                    background-color: #fafafa;
                    list-style: none;
                    padding: 1vw 2vw;

                    li {
                        border-bottom: 1px dashed #b6b6b6;
                        padding-top: 0.781vw;
                        padding-bottom: 0.781vw;
                        font-size: 0.729vw;
                        font-weight: bold;

                        span {
                            border: 1px solid #4ab8fe;
                            height: 1.563vw;
                            line-height: 1.563vw;
                            border-radius: 0.781vw;
                            color: #4ab8fe;
                            font-weight: bold;
                            padding: 0.156vw;
                            margin-right: 1.042vw;
                        }
                    }
                }
            }
            .forward-main {
                img {
                    width: 100%;
                }
                .forward-content {
                    background-color: #f97c45;
                    .img-ms {
                        text-align: center;
                        width: 40%;
                    }
                    .chunk {
                        background-color: #fff;
                        margin: 1vw 1.5vw;
                        border-radius: 0.433vw;
                        padding: 0.8vw;
                        p{
                            text-indent: 2em;
                            margin-top: 0.5vw;
                        }
                        .img-md {
                            text-align: center;
                            width: 80%;
                        }
                    }
                    
                    .fw-title {
                        margin-top: 3vw;
                        margin-bottom: 1vw;
                        .newyear-tip{
                            &::before {
                                content: "";
                                display: inline-block;
                                width: 4.688vw;
                                height: 0.052vw;
                                background: linear-gradient(to right, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
                            }
                            &::after {
                                content: "";
                                display: inline-block;
                                width: 4.688vw;
                                height: 0.052vw;
                                background: linear-gradient(to left, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
                            }
                            img{
                                width: 1.042vw;
                                height: 1.042vw;
                                margin: 0 0.26vw;
                            }
                        }
                        .second-title {
                            font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
                            padding: 0vw 2.042vw;
                            height: 2vw;
                            line-height: 2vw;
                            border-radius: 1vw;
                            background-color: #fee8bb;
                            color: #ea423a;
                            font-size: 0.833vw;
                            font-weight: 600;
                        }
                    }
                    .img-other {
                        display: flex;
                        justify-content: center;
                        margin: 0.521vw 0;
                        height: 18.229vw;
                        div{
                            padding: 0.157vw;
                            &:nth-child(1) {
                                width: 35%;
                                margin-right: 0.521vw;
                                img {
                                    border: 1px solid #a00;
                                    padding: 0.156vw;
                                }
                                p{
                                    text-align: center;
                                    color: #fb8e5a;
                                    
                                }
                            }
                            &:nth-child(2) {
                                border: 1px solid #a00;
                                height: 100%;
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .img-other-two {
                        display: flex;
                        justify-content: center;
                        margin: 0.521vw 0;
                        height: 18.229vw;
                        div{
                            padding: 0.157vw;
                            &:nth-child(2) {
                                width: 35%;
                                margin-right: 0.521vw;
                                img {
                                    border: 1px solid #a00;
                                    padding: 0.156vw;
                                }
                                p{
                                    text-align: center;
                                    color: #fb8e5a;
                                    
                                }
                            }
                            &:nth-child(1) {
                                border: 1px solid #a00;
                                height: 100%;
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .footer-text {
                    padding: 3vw 0;
                    background-color: #b7001a;
                    font-size: 1.042vw;
                    color: #f5d7a6;
                }
            }
        }
    }

    .hm-new-form {
        position: absolute;
        right: 6.567vw;
        top: 7.292vw;
        width: 15.021vw;
        display: none;

        .input-group {
            margin-bottom: 0.521vw;
            position: relative;

            .form-control {
                height: 1.823vw;
                border-radius: 0.417vw;
                padding-left: 1.302vw;
            }

            .form-control::placeholder {
                color: #ccc;
                letter-spacing: 0.104vw;
                font-size: 0.885vw;
            }

            .input-group-prepend {
                position: absolute;
                top: 50%;
                left: 0.26vw;
                width: 0.881vw;
                height: 0.881vw;
                z-index: 9;
                line-height: 1.823vw;
                transform: translate(0, -52%);
            }
        }

        .hm-new-tag {
            height: 6.771vw;
            width: 100%;
            border-radius: 0.417vw;
            background-color: #fff;
            box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
            padding: 0.417vw;
            margin-bottom: 1.042vw;

            .hm-new-tag-title {
                font-size: 0.838vw;
                color: #000;
                font-weight: 400;
            }

            .hm-new-tag-content {
                display: flex;

                .tag {
                    height: 1.042vw;
                    line-height: 1.042vw;
                    padding: 0 0.521vw;
                    font-size: 0.625vw;
                    min-width: 3.083vw;
                    background-color: #c6dbff;
                    margin: 0.26vw;
                    color: #2b79ff;
                    text-align: center;
                }
            }
        }

        .hm-new-section {
            height: 15.771vw;
            width: 100%;
            border-radius: 0.417vw;
            background-color: #fff;
            box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
            padding: 0.417vw;

            .hm-new-section-title {
                font-size: 0.838vw;
                color: #000;
                font-weight: 400;
            }

            .hm-new-section-content {
                display: flex;

                .tag {
                    height: 1.042vw;
                    line-height: 1.042vw;
                    padding: 0 0.521vw;
                    font-size: 0.625vw;
                    min-width: 3.083vw;
                    background-color: #c6dbff;
                    margin: 0.26vw;
                    color: #2b79ff;
                    text-align: center;
                }
            }
        }
    }
}

.hm-news-content-app {
    font-family: MiSans, MiSans;
    margin-top: var(--header-top);
    background-color: #c6dbff;

    .hm-new-bg {
        width: 100vw;
        /* 元素宽度 */
        height: 38.733vw;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/图层 7.png');
        /* 设置图片路径 */
        background-size: cover;
        /* 背景图覆盖整个元素 */
        background-repeat: no-repeat;
        /* 背景图不重复 */
        background-position: center;
        /* 背景图居中 */
    }

    .hm-download {
        height: 20vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3vw;
        background-color: #fff;

        a {
            margin: 2vw;
            font-size: 3.125vw;
            color: #000000;

            &:nth-child(2) {
                display: block;
                height: 6.083vw;
                line-height: 6.083vw;
                text-align: center;
                width: 16.25vw;
                border: 1px solid #1868c9;
                color: #1868c9;
            }
        }
    }

    .hm-iframe-box {
        position: relative;
        top: -15vw;
        // width: 100vw;
        // margin: 0 6.667vw;
        background-color: #fff;
        border-radius: 1.042vw;
        overflow: hidden;
        margin: 0 6.667vw;

        // box-shadow: 1px 1px 4px #ccc;
        .details-title {
            display: block;
            padding: 1.042vw;

            .news_list_detail {
                font-size: 4.133vw;
                line-height: 5.333vw;
                color: #000000;
                padding: 4.267vw 4.533vw 0;
            }

            .news_list_info {
                padding: 0 4.533vw;
                font-size: 2.267vw;
                color: #b1b1b1;
                display: flex;
                align-items: center;

                span {
                    padding-right: 1.333vw;
                }

                .icon-eye {
                    display: inline-block;
                    vertical-align: sub;
                    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/eye.png');
                    /* 设置图片路径 */
                    background-size: cover;
                    /* 背景图覆盖整个元素 */
                    background-repeat: no-repeat;
                    /* 背景图不重复 */
                    background-position: center;
                    /* 背景图居中 */
                    width: 2.5vw;
                    height: 1.5vw;
                    margin-right: 1.333vw;
                }
            }
        }

        .met-editor-notice {
            padding: 1vw;
            font-family: 微软雅黑, MicrosoftYaHei;

            p {
                font-size: 4vw;
                text-indent: 2em;
            }

            .text-red {
                color: #ff0000;
            }

            a {
                color: #62a8ea;
                font-weight: 500;
            }

            .iframe-img {
                display: flex;
                justify-content: center;
            }
        }

        .iframe-title {
            margin: 1.563vw;
        }

        .met-editor {
            padding: 1vw;
            font-size: 2.667vw;
            font-family: 微软雅黑, MicrosoftYaHei;

            h3 {
                text-align: center;
                font-weight: 900;
                padding: 5vw 0 5vw;
            }

            .item-line {
                font-weight: 600;
            }

            .text-d {
                text-indent: 2em;
            }

            .mb-1 {
                margin-bottom: 4vw !important;
            }

            .mb-4 {
                margin-bottom: 10vw !important;
            }

            .text-underline {
                text-decoration: underline;
                font-weight: 600;
            }

            a {
                color: #62a8ea;
                font-weight: 500;
            }

            table {
                thead {
                    tr {
                        th {
                            height: 5vw;
                            line-height: 2.5vw;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 1vw;
                        }
                    }
                }
            }

            p {
                margin-bottom: 0.8em;

                img {
                    width: 100%;
                }
            }
        }

        .met-editor-four {
            padding: 20px;
            font-size: 15.994px;
            font-family: 微软雅黑, MicrosoftYaHei;

            .text-bold {
                font-weight: bold;
                color: #0d54fe;
            }

            .img-content {
                img {
                    width: 800.006px;
                }
            }

            .mecdi-item {
                position: relative;
                background-color: #f6f6f6;
                padding: 15.333vw 5.334vw 14.995px 14.995px;
                line-height: 7vw;
                font-size: 3.334vw;

                strong {
                    font-weight: bold;
                }

                .tip {
                    position: absolute;
                    top: 0;
                    left: 2.667vw;
                    width: 1.8em;
                    height: 10.8vw;
                    background-color: rgb(0, 82, 217);
                }

                .tip::after {
                    position: absolute;
                    bottom: -0.98em;
                    content: '';
                    outline: 0px;
                    height: 0.5em;
                    width: 1.8em;
                    border-left: 0.9em solid rgb(0, 82, 217);
                    border-right: 0.9em solid rgb(0, 82, 217);
                    visibility: visible;
                    border-bottom: 1em solid transparent !important;
                }

                .bc-line {
                    background-color: rgb(0, 82, 217);
                    color: #fff;
                    font-weight: bold;
                }
            }

            .four-title {
                font-size: 6.656vw;
                padding: 0.5vw;
                margin-bottom: 7.895vw;
                font-weight: bold;
                position: relative;

                span {
                    font-size: 5.888vw;
                    color: #deebfb;
                }

                .line {
                    position: absolute;
                    top: 3.2vw;
                    left: 0;
                    color: #256ed9;

                    span {
                        &:nth-child(1) {
                            color: #256ed9;
                            font-size: 10.56vw;
                            letter-spacing: -0.668vw;
                            padding-right: 1.334vw;
                        }
                    }
                }

            }

            .line-two {
                margin-left: 11.008vw;
                margin-top: -4.096vw;
                margin-bottom: 2.56vw;
                color: #256ed9;
                font-size: 4.608vw;
                font-weight: bold;
            }

            .four-title-second {
                color: #256ed9;
                font-size: 5.12vw;
                font-weight: bold;
            }

            ul {
                margin: 5.12vw 0;

                li {
                    font-weight: bold;
                    margin: 1.28vw;
                }
            }
            .title-six {
                margin: 1.334vw auto;
                font-weight: bold;
                .top-tip{
                    font-size: 3.667vw; 
                    letter-spacing: 1.498px; 
                    color: rgb(175, 199, 117); 
                    text-align: center;
                }
                .serial {
                    font-size: 3.133vw;
                    color: rgb(255, 255, 255);
                    width: 5vw;
                    height: 5vw;
                    background-color: rgb(11, 121, 255);
                    border-radius: 100%;
                    text-align: center;
                    line-height: 5vw;
                }
                .title-content {
                    font-size: 5vw; 
                    color: rgb(11, 121, 255); 
                    text-align: left; 
                    padding-right: 0.26vw;
                }
                .title-line {
                    width: 100%;
                    height: 1px; border-top: 1px solid rgb(203, 227, 162);
                }
            }
            .introd-desc {
                text-indent: 3em;
                font-size: 3vw;
            }
        }
        .met-editor-introd {
            padding: 2.56vw 10vw;
            font-size: 3.56vw;
            font-family: PingFang SC,system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
            .text-blur {
                color: #0d54fe;
            }
            .text-red {
                color: #d7281d;
            }

            .introd-desc {
                text-indent: 3em;
                font-weight: 500;
            }
            .introd-two {
                font-weight: 500;
            }
            .introd-h {
                margin: 5.333vw 0;
                font-size: 7.68vw;
                font-weight: 900;
            }
            .introd-htwo {
                margin: 5.333vw 0;
                h2{
                    font-size: 5.12vw;
                    font-weight: 900;
                }
            }
        }
        .met-editor-year {
            padding: 2.56vw 1.333vw;
            font-size: 3.5vw;
            .year-main {
                display: flex;
                flex-direction: column;
                .text-blur {
                    color: #0d54fe;
                    font-weight: bold;
                }
                .top-item {
                    background-image: linear-gradient(to right bottom, #c42714, #da731f);
                    .top-item-desc {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        color: #ffee9e;
                        margin-top: -6.666vw;
                        margin-bottom: 6.666vw;
                        overflow: hidden;
                        h2{
                            font-weight: 900;
                        }
                        p{
                            font-size: 2.3vw;
                        }
                    }
                }
                .year-content {
                    margin-top: -10.003px;
                    border: 1px solid #0086db;
                    padding: 0 1.999vw;
                    .img-content {
                        margin: 0 4.001vw;
                    }
                    .img-md {
                        width: 90%;
                    }
                    .mt-5{
                        margin-top: 3.12vw;
                    }
                    .p-tip {
                        text-align: center;
                        font-size: 2.5vw;
                    }
                    .year-p-desc {
                        line-height: 8.12vw;
                        p{
                            text-indent: 2em;
                        }
                    }
                    .year-title {
                        margin: 4.001vw;
                        text-align: center;
                        span {
                            font-size: 5.067vw;
                            font-weight: bold;
                            padding: 0.512vw 1.334vw;
                            color: #fff;
                            background-color: #fcb42b; 
                        }
                    }
                }
            }
        }


        .met-editor-look {
            padding: 2.56vw 1.333vw;
            font-size: 3.5vw;
            color: #000;
            font-family: 微软雅黑, MicrosoftYaHei;

            .text-bold {
                font-weight: bold;
                color: #0d54fe;
            }

            p {
                text-indent: 2em;
            }

            .mt {
                margin-top: 5.12vw;
                margin-bottom: 5.12vw;
            }

            .mecdi-item-look {
                margin: 2.667vw;
                padding: 1.5em;
                height: auto;
                overflow: hidden;
                box-shadow: rgb(102, 102, 102) 0vw 0vw 1.333vw;
                position: relative;
                background-color: #fff;

                .img-content {
                    img {
                        width: 106.667vw;
                        margin: 5.333vw 0;
                    }
                }
            }

            .look-title {
                margin: 7.68vw 0 5.12vw;
                display: flex;
                justify-content: center;
                text-align: center;

                div {
                    height: 9vw;

                    &:nth-child(1) {
                        width: 2vw;
                        background-color: rgb(56, 89, 254);
                        transform: skewX(-20deg);
                        overflow: hidden;
                    }

                    &:nth-child(2) {
                        width: 0vw;
                        border-top: 9vw solid transparent;
                        border-right: 3.6vw solid rgb(56, 89, 254);
                        overflow: hidden;
                    }

                    &:nth-child(3) {
                        font-size: 4.667vw;
                        font-weight: bold;
                        color: #fff;
                        padding: 0.801vw 2.667vw;
                        letter-spacing: 1.498px;
                        background-image: -webkit-linear-gradient(left, rgb(56, 89, 254), rgb(118, 140, 249))
                    }

                    &:nth-child(4) {
                        width: 0vw;
                        border-bottom: 9vw solid transparent;
                        border-left: 3.6vw solid rgb(118, 140, 249);
                        overflow: hidden;
                    }

                    &:nth-child(5) {
                        width: 2vw;
                        background-color: rgb(118, 140, 249);
                        transform: skewX(-20deg);
                        overflow: hidden;
                    }
                }

            }

            .chat-line {
                border: 0.133vw solid #67b8e8;
                border-radius: 1.334vw;

                .content {
                    border-radius: 1.334vw;
                    background-color: #f5faff;
                    padding: 5.333vw;
                    margin: 0.555vw;
                    font-size: 1.866vw;

                    .one {
                        display: flex;

                        div:nth-child(1) {
                            height: 4.667vw;
                            width: 4.667vw;
                            line-height: 4.667vw;
                            margin-right: 1.334vw;
                            text-align: center;
                            border-radius: 2.667vw;
                            font-size: 2.667vw;
                            font-weight: 900;
                            background-color: #1a9cf0;
                            color: #fff;
                        }

                        div:nth-child(2) {
                            position: relative;
                            height: 4.667vw;
                            line-height: 4.667vw;
                            text-align: center;
                            padding: 0 2.667vw;
                            border-radius: 2.667vw;
                            background-color: #ff9b0b;
                            color: #fff;

                            &::after {
                                position: absolute;
                                bottom: -1.334vw;
                                left: 2.667vw;
                                content: "";
                                width: 0vw;
                                height: 0.133vw;
                                border-top: 1.866vw solid rgb(255, 155, 11);
                                border-right: 1.067vw solid transparent;
                                margin-top: -0.533vw;
                                margin-left: 2.667vw;
                                transform: rotate(30deg);
                                visibility: visible;
                            }
                        }
                    }

                    .tow-left {
                        margin: 4.334vw 0;
                        display: flex;

                        div {
                            position: relative;
                            text-align: right;
                            background-color: #92d050;
                            color: #fff;
                            font-size: 3.866vw;
                            padding: 3.5vw 5.12vw;
                            border-radius: 2.334vw;

                            &::after {
                                content: "";
                                position: absolute;
                                left: -2.267vw;
                                top: 4vw;
                                width: 0px;
                                height: 0.133vw;
                                border-top: 2.667vw solid transparent;
                                border-right: 2.667vw solid rgb(146, 208, 80);
                                border-bottom: 2.667vw solid transparent;
                                overflow: hidden;
                                visibility: visible;
                            }
                        }

                    }

                    .tow-right {
                        margin: 4.334vw 0;
                        display: flex;
                        justify-content: end;

                        div {
                            position: relative;
                            text-align: right;
                            background-color: #92d050;
                            color: #fff;
                            font-size: 3.866vw;
                            padding: 3.5vw 5.12vw;
                            border-radius: 2.334vw;

                            &::after {
                                content: "";
                                position: absolute;
                                right: -2.267vw;
                                top: 4vw;
                                width: 0px;
                                height: 0.133vw;
                                border-top: 2.667vw solid transparent;
                                border-left: 2.667vw solid rgb(146, 208, 80);
                                border-bottom: 2.667vw solid transparent;
                                overflow: hidden;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            li {
                p {
                    color: #a5a5a5;
                    text-indent: 1em;
                    margin-bottom: 9.6px;
                }
            }

            .client-desc {
                .head {
                    height: 57.6px;
                    border-radius: 28.8px;
                    border: 15.36px solid #1aa6ff;
                    background-color: #0086db;
                }

                .desc-content {
                    margin-top: -10.003px;
                    margin-left: 20.006px;
                    margin-right: 20.006px;
                    background-color: #fafafa;
                    list-style: none;
                    padding: 19.2px 38.4px;

                    li {
                        border-bottom: 1px dashed #b6b6b6;
                        padding-top: 14.995px;
                        padding-bottom: 14.995px;
                        font-size: 13.997px;
                        font-weight: bold;

                        span {
                            border: 1px solid #4ab8fe;
                            height: 30.01px;
                            line-height: 30.01px;
                            border-radius: 14.995px;
                            color: #4ab8fe;
                            font-weight: bold;
                            padding: 2.995px;
                            margin-right: 20.006px;
                        }
                    }
                }
            }
            .forward-main {
                img {
                    width: 100%;
                }
                .forward-content {
                    background-color: #f97c45;
                    .img-ms {
                        text-align: center;
                        width: 40%;
                    }
                    .chunk {
                        background-color: #fff;
                        margin: 2.56vw 3.84vw;
                        border-radius: 8.314px;
                        padding: 15.36px;
                        p{
                            text-indent: 2em;
                            margin-top: 9.6px;
                            font-size: 3vw;
                            line-height: 6vw;
                            letter-spacing: 1.5px;
                        }
                        .p-text {
                            letter-spacing: 1.5px;
                            font-size: 2.5vw;
                        }
                        .img-md {
                            text-align: center;
                            width: 80%;
                        }
                    }
                    
                    .fw-title {
                        margin-top: 7.68vw;
                        margin-bottom: 2.56vw;
                        .newyear-tip{
                            &::before {
                                content: "";
                                display: inline-block;
                                width: 12.001vw;
                                height: 0.233vw;
                                background: linear-gradient(to right, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
                            }
                            &::after {
                                content: "";
                                display: inline-block;
                                width: 12.001vw;
                                height: 0.233vw;
                                background: linear-gradient(to left, rgb(249, 124, 69) 10%, rgb(254, 232, 187));
                            }
                            img{
                                width: 4.667vw;
                                height: 4.667vw;
                                margin: 0 0.666vw;
                            }
                        }
                        .second-title {
                            font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
                            padding: 0vw 7vw;
                            height: 9vw;
                            line-height: 9vw;
                            border-radius: 4.5vw;
                            background-color: #fee8bb;
                            color: #ea423a;
                            font-size: 3.8vw;
                            font-weight: 600;
                        }
                    }
                    .img-other {
                        display: flex;
                        justify-content: center;
                        margin: 1.334vw 0;
                        height: 26.666vw;
                        div{
                            padding: 0.402vw;
                            &:nth-child(1) {
                                width: 45%;
                                margin-right: 1.334vw;
                                img {
                                    border: 1px solid #a00;
                                    padding: 2.995px;
                                }
                                p{
                                    text-align: center;
                                    color: #fb8e5a;
                                    
                                }
                            }
                            &:nth-child(2) {
                                border: 1px solid #a00;
                                height: 100%;
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .img-other-two {
                        display: flex;
                        justify-content: center;
                        margin: 1.334vw 0;
                        height: 26.666vw;
                        div{
                            padding: 0.402vw;
                            &:nth-child(2) {
                                width: 45%;
                                margin-left: 1.334vw;
                                img {
                                    border: 1px solid #a00;
                                    padding: 2.995px;
                                }
                                p{  
                                    text-align: center;
                                    color: #fb8e5a;
                                    font-size: 2.5vw;
                                    margin: 0.333vw 0;
                                }
                            }
                            &:nth-child(1) {
                                border: 1px solid #a00;
                                height: 100%;
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .footer-text {
                    padding: 3vw 0;
                    background-color: #b7001a;
                    font-size: 4.042vw;
                    color: #f5d7a6;
                }
            }
        }
    }

    .hm-new-form {
        display: none;
        padding: 0 6.667vw;
        background-color: #f2f2f2;
        height: 170vw;

        .input-group {
            position: relative;

            .form-control {
                height: 15.067vw;
                border-radius: 1.6vw;
                padding-left: 12.667vw;
                font-size: 6.133vw;
                margin: 3.867vw 0;
            }

            .form-control::placeholder {
                color: #ccc;
                letter-spacing: 0.78px;
                font-size: 6.133vw;
            }

            .input-group-prepend {
                position: absolute;
                top: 50%;
                left: 3.333vw;
                width: 7.733vw;
                height: 7.733vw;
                z-index: 9;
                line-height: 7.733vw;
                transform: translate(0, -52%);
            }
        }

        .hm-new-tag {
            height: 36.933vw;
            width: 100%;
            border-radius: 1.6vw;
            background-color: #fff;
            box-shadow: 0.052vw 0.052vw 0.26vw #d6d6d6;
            padding: 3.333vw;
            margin-bottom: 4.8vw;

            .hm-new-tag-title {
                font-size: 5.6vw;
                color: #000;
                font-weight: 400;
            }

            .hm-new-tag-content {
                display: flex;
                flex-wrap: wrap;

                .tag {
                    padding: 0 2.667vw;
                    font-size: 4.667vw;
                    background-color: #c6dbff;
                    margin: 2vw;
                    min-width: 20vw;
                    color: #2b79ff;
                    text-align: center;
                }
            }
        }

        .hm-new-section {
            height: 90.667vw;
            width: 100%;
            border-radius: 1.6vw;
            background-color: #fff;
            box-shadow: 0.133vw 0.133vw 0.533vw #d6d6d6;
            padding: 3.333vw;

            .hm-new-section-title {
                font-size: 5.6vw;
                color: #000;
                font-weight: 400;
            }

            .hm-new-section-content {
                width: 100%;
                height: 72.267vw;
                margin-bottom: 10vw;

                img {
                    height: 100%;
                }
            }
        }
    }
}