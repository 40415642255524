#hm-call {
    position: relative;
    display: flex;
    align-items: center;
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/bg3.png'); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 13.75vw; /* 元素高度 */
    .hm-call-employee {
        position: absolute;
        right: 16.198vw;
        bottom: 0px;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/employee.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 13.177vw; /* 元素宽度 */
        height: 17.5vw; /* 元素高度 */
    }
    .hm-call-text {
        font-weight: 600;
        font-size: 1.692vw;
        color: #FFFFFF;
        margin: 0vw 14.546vw 0vw 16.719vw;
    }
    .hm-call-btn {
        width: 9.635vw;
        height: 2.813vw;
        line-height: 2.813vw;
        border-radius: 1.354vw 1.354vw 1.354vw 1.354vw;
        border: 0.052vw solid #FFFFFF;
        font-weight: 400;
        font-size: 1.146vw;
        color: #FFFFFF;
        text-align: center;
        margin-right: 1.667vw;
        cursor: pointer;
    }
    .chat-icon {
        display: inline-block;
        vertical-align: sub;
        margin-right: 0.26vw;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/tv.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 1.354vw;
        height: 1.354vw;
    }
    .tv-icon {
        display: inline-block;
        vertical-align: sub;
        margin-right: 0.26vw;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/chat.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 1.354vw;
        height: 1.354vw;
    }
}
#hm-call-app {
    position: relative;
    display: flex;
    align-items: center;
    background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/矢量智能对象@2.png'); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 35.2vw; /* 元素高度 */
    .hm-call-employee {
        position: absolute;
        right: 1vw;
        bottom: 0;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/employee@2.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 33.733vw; /* 元素宽度 */
        height: 44.8vw; /* 元素高度 */
    }
    .hm-call-btn {
        width: 30vw;
        height: 8vw;
        line-height: 8vw;
        border-radius: 4vw;
        border: 0.052vw solid #FFFFFF;
        font-weight: 400;
        font-size: 4.146vw;
        color: #FFFFFF;
        text-align: center;
        margin-right: 3.667vw;
        cursor: pointer;
    }
    .hm-call-btn:nth-child(1) {
        margin-left: 4vw;
    }
    .chat-icon {
        display: inline-block;
        vertical-align: sub;
        margin: 0 1.333vw;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/tv.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 5vw;
        height: 5.354vw;
    }
    .tv-icon {
        display: inline-block;
        margin: 0 1.333vw;
        vertical-align: sub;
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/chat.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 5vw;
        height: 5.354vw;
    }
}
.hm-footer-container {
    background-color: #F7F7F7;
    padding-top: 2.656vw;
    h5 {
        font-weight: 400;
        font-size: 1.25vw;
        color: #000000 !important;  
    }
    li {
        font-weight: 400;
        font-size: 0.833vw;
        color: #7E7E7E;
        font-weight: 400;
    }
    .row {
       width: 68.302vw;
       margin: 0 auto;
    }
    .col-6:nth-child(1) {
        margin-right: -4vw;
    }
    .col-6:nth-child(3) {
        margin-right: -3vw;
    }
    .hm-tip::before {
        content: "";
        display: inline-block;
        width: 0vw;
        height: 0vw;
        margin: auto;
        vertical-align: middle;
        border: 0.417vw solid transparent;
        border-left: 0.417vw solid #000000;
    }
    .qrcode {
        width: 5.73vw;
        margin-right: 0.521vw;
        img {
            width: 5.677vw;
            height: 5.677vw;
            
        }
    }
    .qrcode-6 {
        img {
            width: 5.677vw;
            height: 5.677vw;
            
        }
    }
    .hm-map {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 1.563vw;
        color: #FFFFFF;
        width: 66.302vw;
        height: 3.385vw;
        line-height: 3.385vw;
        background: radial-gradient( 0% 0% at 0% 0%, #518AFF 0%, #0057FF 100%);
        // border-radius: 0.521vw 0.521vw 0.521vw 0.521vw;
        box-sizing: border-box;
        padding: 0vw 1.302vw 0vw 3.333vw;
        position: fixed;
        bottom: 0;
        left: 0;
        opacity: 0.8;
    }
    .hm-footer-button {
        width: 7.604vw;
        height: 2.188vw;
        line-height: 2.188vw;
        background: #FFFFFF;
        border-radius: 1.094vw 1.094vw 1.094vw 1.094vw;
        font-weight: 400;
        font-size: 1.042vw;
        color: #1A64FF;
        text-align: center;
    }
    .hm-footer-split {
        height: 0.052vw;
        background: #D2D2D2;
        border: 0.052vw solid #D2D2D2;
        margin-top: 30px;
    }
    .hm-footer-endDesc {
        align-items: center;
        font-weight: 400;
        font-size: 0.833vw;
        color: #A1A1A1;
        a{
            color: #1A64FF;
        }
    }
    .hm-footer-endDesc .hm-footer-endDesc-item {
        flex: 1;
        font-weight: 600;
        font-size: 1.042vw;
        color: #404040;
    }
    .w-75 {
        width: 73% !important;
    }
    .orgicon {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/hm_icon.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 8.21vw;
        height: 2.01vw;
    }
  
}
.hm-footer-container-app {
    background-color: #F7F7F7;
    padding-top: 2.656vw;
    margin: 0;
    h5 {
        font-weight: 700;
        font-size: 4.042vw;
        color: #000000 !important;  
    }
    li {
        font-weight: 400;
        font-size: 2.833vw;
        color: #7E7E7E;
        font-weight: 400;
        .text-muted{
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .row {
       width: 100vw;
    }
    .hm-tip::before {
        content: "";
        display: inline-block;
        width: 0vw;
        height: 0vw;
        margin: auto;
        vertical-align: middle;
        border: 0.417vw solid transparent;
        border-left: 0.417vw solid #000000;
    }
    .qrcode, .qrcode-6 {
        font-size: 2.863vw;
        img {
            width: 14.677vw;
            height: 14.677vw;
        }
    }
    
    .hm-mapapp {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 2.863vw;
        color: #FFFFFF;
        height: 10.385vw;
        line-height: 10.385vw;
        background: radial-gradient( 0% 0% at 0% 0%, #518AFF 0%, #0057FF 100%);
        // border-radius: 0.521vw 0.521vw 0.521vw 0.521vw;
        box-sizing: border-box;
        padding: 0vw 1.302vw 0vw 3.333vw;
        position: fixed;
        bottom: 0;
        left: 0;
        opacity: 0.8;
    }
    .hm-footer-button {
        width: 15.604vw;
        height: 6vw;
        line-height: 6vw;
        background: #FFFFFF;
        border-radius: 3vw;
        font-weight: 400;
        font-size: 2.542vw;
        color: #1A64FF;
        text-align: center;
    }
    .hm-footer-split {
        border-bottom: 0.133vw solid #D2D2D2;
        margin-top: 30px;
    }
    .hm-footer-endDesc {
        align-items: center;
        font-weight: 400;
        font-size: 2.233vw;
        color: #A1A1A1;
        .hm-footer-endDesc-content {
            flex: 1;
            padding: 3.667vw;
        }
        a{
            color: #1A64FF;
        }
    }
    .hm-footer-endDesc .hm-footer-endDesc-item {
        width: 30vw;
        font-weight: 600;
        font-size: 3.042vw;
        color: #404040;
    }
    .orgicon {
        background-image: url('https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/hm_icon.png'); /* 设置图片路径 */
        background-size: cover; /* 背景图覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图不重复 */
        background-position: center; /* 背景图居中 */
        width: 26.813vw;
        height: 6.5vw;
    }
  
}
footer {
    background-color: #F7F7F7;
    padding-bottom: 7vw;
}
