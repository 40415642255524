#hm-type {
  h1 {
    font-weight: 600;
    font-size: 2.083vw;
    color: #000000;
    margin-bottom: 0.521vw;
    margin-top: 5.104vw;
  }
  h5 {
    font-weight: 400;
    font-size: 1.042vw;
    color: #000000;
    margin-bottom: 2.604vw;
  }
  .tab-pane {
    position: relative;
    width:48.75vw;
    height: 27.24vw;
    background-color: #FFFFFF;
    border-top-left-radius:1.458vw;
    border-top-right-radius:1.458vw;
    border-bottom-left-radius:1.458vw;
    border-bottom-right-radius:1.458vw;
    box-sizing: border-box;
    padding: 2.083vw 2.083vw 0vw 1.563vw;
    margin-left: 1.042vw;
    overflow: hidden;
  }
  .tab-content-index {
    height: 1.901vw;
    width: 6.484vw;
    padding-left: 0.313vw;
    line-height: 1.901vw;
    font-weight: bold;
    font-size: 1.25vw;
    color: #FFFFFF;
    border:0.104vw solid #2F77FF;
    box-sizing: border-box;
    background-color: #2F77FF;
    margin-right: 0.521vw;
  }
  .tab-content-title {
    height: 1.693vw;
    text-align: center;
    flex: 1;
    background-color: #FFFFFF;
    font-size: 1.042vw;
    color: #000000;
   
    margin-left: 0.417vw;
  }
  .tab-content-header {
    font-weight: 500;
    font-size: 1.146vw;
    color: #000000;
    margin-bottom: 0.26vw;
  }
  .tab-content-desc {
    font-size: 0.833vw;
    color: #000000;
    line-height: 1.146vw;
    margin: 0px;
  }
  .tab-pane-item:not(:last-child){
    margin-bottom: 1.875rem;
  }
  .tab-pane-item {
    width: 24.854vw;
  }
  .tab-pane-img {
    position: absolute;
    right: 0vw;
    top: 0vw;
    width: 21.875vw; /* 元素宽度 */
    height: 100%; /* 元素高度 */
  }
  .hm-type-container {
    background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/skyscraper.png"); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 33.333vw; /* 元素高度 */
    padding: 2.76vw 0vw 0vw 17.448vw;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .hm-type-select {
    .nav-link {
      position: relative;
      width: 11.979vw;
      height: 4.123vw;
      line-height: 4.323vw;
      text-align: center;
      box-shadow: 0vw 0vw 0.469vw 0vw #ccd7ef;
      border-radius: 0.26vw 0.26vw 0.26vw 0.26vw;
      margin-bottom: 0.521vw;
      font-weight: 400;
      font-size: 1.458vw;
      background-color: #f6f9fc;
      color: #000000;
      white-space: nowrap;
      padding: 0vw;
    }
  
    .nav-pills .nav-link.active {
      color: #ffffff !important;
      background-color: #2f6ff4 !important;
      &::after {
        content: "";
        position: absolute;
        top: -0.026vw;
        right: -2.083vw;
        border-top: 2.1vw solid transparent;
        border-bottom: 2.1vw solid transparent;
        border-left: 2.188vw solid #2f6ff4;
        display: block;
        width: 0;
      }
    }
  }
}
#hm-type-app {
  h1 {
    font-weight: 600;
    font-size: 4.533vw;
    line-height: 6vw;
    color: #000000;
    margin: 8.479vw 6.667vw 0;
    text-align: left;
  }

  h1:nth-child(2) {
      margin-top: 0;
  }

  h5 {
      font-weight: 400;
      font-size: 3.467vw;
      color: #000000;
      margin: 3.479vw 6.667vw;
  }
  .tab-pane {
    position: relative;
    width: 86.6vw;
    height: 58vw;
    background-color: #FFFFFF;
    border-top-left-radius:1.458vw;
    border-top-right-radius:1.458vw;
    border-bottom-left-radius:1.458vw;
    border-bottom-right-radius:1.458vw;
    box-sizing: border-box;
    padding: 2.083vw 2.083vw 0vw 1.563vw;
    overflow: hidden;
  }
  .tab-content-index {
    height: 5.1vw;
    width: 16.667vw;
    font-weight: bold;
    font-size: 3vw;
    color: #FFFFFF;
    box-sizing: border-box;
    background-color: #2F77FF;
    margin-right: 1.8vw;
    border: 0.267vw solid #2F77FF;
  }
  .tab-content-title {
    height: 4.693vw;
    line-height: 4.693vw;
    text-align: center;
    flex: 1;
    background-color: #FFFFFF;
    font-size: 2.942vw;
    color: #191919;
    margin-left: 1vw;
    font-weight: 400;
  }
  .tab-content-header {
    font-weight: 500;
    font-size: 3.046vw;
    color: #000000;
    font-weight: 600;
    margin-bottom: 1.833vw;
    
  }
  .tab-content-title-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tab-content-desc {
    font-size: 2.533vw;
    color: #000000;
    line-height: 3.546vw;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置为2行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tab-pane-item:not(:last-child){
    margin-bottom: 4vw;
  }
  .tab-pane-item {
    width: 48.854vw;
  }
  .tab-pane-img {
    position: absolute;
    right: 0vw;
    top: 0vw;
    width: 32.875vw; /* 元素宽度 */
    height: 100%; /* 元素高度 */
  }
  .hm-type-container-app {
    background-image: url("https://static-hop.hmccloud.com/hmcsoft_cn/assets/img/demo/skyscraper@2.png"); /* 设置图片路径 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图不重复 */
    background-position: center; /* 背景图居中 */
    width: 100vw; /* 元素宽度 */
    height: 87.067vw; /* 元素高度 */
    padding: 4.76vw 0vw 0vw 6.667vw;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .hm-type-select {
    .hm-type-row {
      width: 90vw;
      overflow-x: auto;
    }
    .nav {
      flex-wrap: nowrap;
      align-items: center;
      width: 108vw;
      margin-bottom: 4vw;
    }
    .nav-link {
      position: relative;
      width: 20vw;
      height: 11vw;
      line-height: 11vw;
      text-align: center;
      box-shadow: 0vw 0vw 0.469vw 0vw #ccd7ef;
      border-radius: 1.067vw;
      margin-bottom: 0.521vw;
      font-weight: 400;
      font-size: 5vw;
      font-weight: 500;
      background-color: #f6f9fc;
      color: #000000;
      white-space: nowrap;
      padding: 0vw;
    }
  
    .nav-pills .nav-link.active {
      color: #ffffff !important;
      border: 0.4vw solid #fff;
      font-weight: 700;
      background-image: linear-gradient(to bottom, #3369e6 , #2b72fd) !important;
    }
  }

}

